import React from "react";

const WashCold = props => (
  <svg viewBox="0 0 31.99 22.018"   {...props}>
    <path
      d="M25.995 1.018c-3 0-3 2-5 2s-2-2-5-2-3 2-5 2-2-2-5-2-5 3-5 3l5 17h20l5-17s-2-3-5-3z"
      fill="#87cfff"
    />
    <path
      d="M31.268.034a.972.972 0 0 0-1.193.696l-4.853 19.287H6.768L1.915.73A.974.974 0 0 0 .722.034.986.986 0 0 0 .033 1.24l5.042 20.035a.964.964 0 0 0 .92.715v.027h20v-.027a.963.963 0 0 0 .92-.715L31.957 1.24a.986.986 0 0 0-.689-1.206z"
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#48a0dc"
    />
    <path
      d="M13.995 7.018h-4a1 1 0 1 0 0 1.999h2.155l-.862 1.293a1.001 1.001 0 0 0 .707 1.707 1 1 0 1 1-1 1.001 1 1 0 1 0-2 0 3 3 0 0 0 6 0 2.988 2.988 0 0 0-1.45-2.557l1.157-1.736a1.001 1.001 0 0 0-.707-1.707zm5 0a3 3 0 0 0-3 3v3a3 3 0 0 0 6 0v-3a3 3 0 0 0-3-3zm1 6a1 1 0 0 1-2 0v-3a1 1 0 1 1 2 0v3zm4-6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#fff"
    />
  </svg>
);

export default WashCold;
