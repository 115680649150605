import React from "react";

const Planet1 = props => (
  <svg viewBox="0 0 64 64"   {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M53.2 25.928l1.155.261c.189.052.189.321 0 .373l-1.155.261A.271.271 0 0 0 53 27l-.317 1.233c-.052.19-.321.19-.373 0L51.993 27a.27.27 0 0 0-.2-.177l-1.154-.26c-.19-.053-.19-.322 0-.374l1.154-.26a.273.273 0 0 0 .2-.178l.317-1.234c.052-.189.32-.189.373 0L53 25.751a.273.273 0 0 0 .2.177zM6.703 57.553l1.155.26c.19.053.19.322 0 .374l-1.155.26a.271.271 0 0 0-.2.178l-.317 1.233c-.052.19-.32.19-.373 0l-.317-1.233a.27.27 0 0 0-.2-.177l-1.154-.261c-.19-.052-.19-.321 0-.373l1.154-.261a.273.273 0 0 0 .2-.177l.317-1.234c.052-.19.321-.19.373 0l.317 1.234a.273.273 0 0 0 .2.177M7 6a1 1 0 1 1-2 0 1 1 0 0 1 2 0m23 39a1 1 0 1 1-2 0 1 1 0 0 1 2 0M50 6H14l1-1h34l1 1zm5 4H9l1-1h44l1 1zm-6 49H16l-1-1h35l-1 1zM37 38H1v-1h37l-1 1zm26 0H47l-1-1h17v1zM.5 32h63v-1H.5v1zM4 18h56v-1H4v1z"
        fill="#f2b430"
      />
      <path
        d="M32 0C14.327 0 0 14.327 0 32c0 17.673 14.327 32 32 32 17.673 0 32-14.327 32-32C64 14.327 49.673 0 32 0m0 1c17.093 0 31 13.907 31 31S49.093 63 32 63 1 49.093 1 32 14.907 1 32 1"
        fill="#1d7ed0"
      />
      <path
        d="M42 35a6 6 0 1 0 0 12 6 6 0 0 0 0-12m0 1c2.757 0 5 2.243 5 5s-2.243 5-5 5-5-2.243-5-5 2.243-5 5-5"
        fill="#317fc1"
      />
    </g>
  </svg>
);

export default Planet1;
