import React from "react";

const Scream = props => (
  <svg viewBox="0 0 32 32"   {...props}>
    <path
      d="M30 23.999c-.521-.52-3.528-2.302-6.11-3.791.036-.069.081-.15.11-.209 1-2 1-6.999 1-9.999 0-4-2.094-10-9-10S7 6 7 10c0 3 0 7.999 1 9.999.029.059.074.14.11.209-2.583 1.488-5.59 3.271-6.11 3.791C.12 25.88 0 32 0 32h32s-.12-6.12-2-8.001z"
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#333"
    />
    <path
      d="M22 10c0-4-2.594-7-6-7s-6 3-6 7c0 1-1 1-1 3 0 1 2 3 3 3 0 8 .809 13 4 13s4-5 4-13c1 0 3-2 3-3 0-2-1-1.996-1-3z"
      fill="#fff"
    />
    <path
      d="M16 17c-1.82 0-2 .896-2 2s.896 7 2 7 2-5.896 2-7-.18-2-2-2z"
      fill="#333"
    />
    <path d="M16 13c-.875 0-1 2-1 2h2s-.125-2-1-2z" fill="#666" />
    <path
      d="M19 10c-.604-.604-.428-2-1-2-.5 0-1 .374-1 1 0 1.45.818 3 2 3 .668 0 1-.552 1-1 0-.356-.601-.601-1-1zm-5-2c-.572 0-.397 1.396-1 2-.619.618-1 .644-1 1 0 .448.332 1 1 1 1.182 0 2-1.55 2-3 0-.626-.5-1-1-1z"
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#666"
    />
  </svg>
);

export default Scream;
