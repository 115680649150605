import React from "react";

const Capsule1 = props => (
  <svg viewBox="0 0 44 64"   {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M42.703 8.553l1.155.26c.19.053.19.322 0 .374l-1.155.26a.271.271 0 0 0-.2.178l-.317 1.233c-.052.19-.32.19-.373 0l-.317-1.233a.27.27 0 0 0-.2-.177l-1.154-.261c-.19-.052-.19-.321 0-.373l1.154-.261a.273.273 0 0 0 .2-.177l.317-1.234c.052-.19.321-.19.373 0l.317 1.234a.273.273 0 0 0 .2.177m-40 23l1.155.26c.19.053.19.322 0 .374l-1.155.26a.271.271 0 0 0-.2.178l-.317 1.233c-.052.19-.32.19-.373 0l-.317-1.233a.27.27 0 0 0-.2-.177l-1.154-.261c-.19-.052-.19-.321 0-.373l1.154-.261a.273.273 0 0 0 .2-.177l.317-1.234c.052-.19.321-.19.373 0l.317 1.234a.273.273 0 0 0 .2.177"
        fill="#f2b430"
      />
      <path
        d="M28 54h-8l-4.183 6.971c-.799 1.333.161 3.029 1.715 3.029h12.936c1.554 0 2.514-1.696 1.715-3.029L28 54zm-.566 1l3.891 6.486a.982.982 0 0 1 .013 1.007.983.983 0 0 1-.87.507H17.532a.983.983 0 0 1-.87-.507.982.982 0 0 1 .013-1.007L20.566 55h6.868z"
        fill="#1d7ed0"
      />
      <path
        d="M24 0c-.553 0-1.107.228-1.505.683L10.493 14.435A1.998 1.998 0 0 0 10 15.75V55h28V15.75c0-.484-.175-.951-.493-1.315L25.505.683A1.992 1.992 0 0 0 24 0m0 1c.293 0 .56.121.752.341l12.001 13.751c.159.183.247.416.247.658V54H11V15.75c0-.242.088-.475.247-.658l12-13.751A.988.988 0 0 1 24 1"
        fill="#1d7ed0"
      />
      <path d="M11 16h26v-1H11v1zm0 10h26v-1H11v1z" fill="#1d7ed0" />
      <path
        d="M11 52h26v-1H11v1zm19-16h8v-1h-8v1zm0 6h8v-1h-8v1z"
        fill="#f2b430"
      />
      <path
        d="M10 45h4V30h-4v15zm1-1h2V31h-2v13zm27-15h-6a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h6V29zm-1 1v17h-5c-.551 0-1-.449-1-1V31c0-.551.449-1 1-1h5z"
        fill="#1d7ed0"
      />
      <path
        d="M18 25h1v-9h-1v9zm13-6a1 1 0 1 1-2 0 1 1 0 0 1 2 0m4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0"
        fill="#f2b430"
      />
    </g>
  </svg>
);

export default Capsule1;
