import React from "react";

const Stopwatch = props => (
  <svg viewBox="0 0 128 128"   {...props}>
    <path
      fill="#F6E89A"
      d="M42.052 57.262l-7.07 7.072-15.428-15.427 7.07-7.071z"
    />
    <path
      fill="#8D4520"
      d="M34.979 67.334a2.988 2.988 0 0 1-2.121-.879L17.431 51.028a3 3 0 0 1 0-4.243l7.071-7.071a3.001 3.001 0 0 1 4.242 0l15.427 15.427a3 3 0 0 1 0 4.243L37.1 66.455a2.988 2.988 0 0 1-2.121.879zM23.795 48.907l11.184 11.185 2.829-2.829-11.185-11.184-2.828 2.828z"
    />
    <path
      fill="#F6E89A"
      d="M30.352 44.92l3.213-3.213-7.071-7.072-14.142 14.143 7.071 7.071 3.926-3.927"
    />
    <path
      fill="#8D4520"
      d="M19.423 58.849a3.001 3.001 0 0 1-2.121-.878L10.231 50.9a3 3 0 0 1-.001-4.243l14.142-14.143c1.126-1.126 3.118-1.126 4.243 0l7.071 7.071a2.998 2.998 0 0 1 0 4.242l-3.213 3.213-4.242-4.242 1.092-1.092-2.829-2.829-9.899 9.9 2.829 2.828 1.805-1.805 4.243 4.242-3.926 3.927a3.001 3.001 0 0 1-2.123.88z"
    />
    <path
      fill="#8D4520"
      d="M22.409 53.434a2 2 0 0 1-1.414-3.414l7.295-7.295a2 2 0 1 1 2.828 2.828l-7.295 7.295c-.39.39-.902.586-1.414.586z"
    />
    <path
      fill="#B8CBCD"
      d="M113.051 114.147c0 5.626-21.675 10.187-48 10.187-26.326 0-47.335-4.561-47.335-10.187 0-5.626 21.341-10.188 47.667-10.188 26.325.001 47.668 4.562 47.668 10.188z"
    />
    <path fill="#F6E89A" d="M59.051 28.475h12v14.859h-12z" />
    <path
      fill="#8D4520"
      d="M71.051 46.334h-12a3 3 0 0 1-3-3V28.475a3 3 0 0 1 3-3h12a3 3 0 0 1 3 3v14.859a3 3 0 0 1-3 3zm-9-6h6v-8.859h-6v8.859z"
    />
    <path
      fill="#F6E89A"
      d="M104.78 41.834l7.07 7.071-15.427 15.427-7.07-7.071z"
    />
    <path
      fill="#8D4520"
      d="M96.424 67.334a2.988 2.988 0 0 1-2.121-.879l-7.071-7.071a3 3 0 0 1 0-4.243l15.427-15.427a3 3 0 0 1 4.242 0l7.071 7.071a3 3 0 0 1 0 4.243L98.545 66.455a2.991 2.991 0 0 1-2.121.879zm-2.829-10.071l2.829 2.829 11.184-11.185-2.829-2.828-11.184 11.184z"
    />
    <circle fill="#F6E89A" cx={65.716} cy={79.834} r={39.5} />
    <path
      fill="#8D4520"
      d="M65.716 122.334c-23.435 0-42.5-19.065-42.5-42.5s19.065-42.5 42.5-42.5 42.5 19.065 42.5 42.5-19.065 42.5-42.5 42.5zm0-79c-20.126 0-36.5 16.374-36.5 36.5s16.374 36.5 36.5 36.5 36.5-16.374 36.5-36.5-16.374-36.5-36.5-36.5z"
    />
    <path
      fill="#F6E89A"
      d="M101.051 44.92l-3.213-3.213 7.071-7.072 14.142 14.143-7.071 7.071-3.927-3.927"
    />
    <path
      opacity={0.2}
      fill="#8D4520"
      d="M65.716 117.334c-23.262 0-42.187-19.065-42.187-42.5a3 3 0 1 1 6 0c0 20.126 16.233 36.5 36.187 36.5 19.953 0 36.187-16.374 36.187-36.5a3 3 0 1 1 6 0c0 23.435-18.925 42.5-42.187 42.5z"
    />
    <path
      fill="#8D4520"
      d="M111.98 58.849a3 3 0 0 1-2.122-.879l-3.926-3.927 4.243-4.242 1.805 1.805 2.829-2.828-9.899-9.9-2.829 2.829 1.092 1.092-4.242 4.242-3.213-3.213a2.998 2.998 0 0 1 0-4.242l7.071-7.071a3 3 0 0 1 4.243 0l14.142 14.143a2.999 2.999 0 0 1-.001 4.243l-7.071 7.071a3.01 3.01 0 0 1-2.122.877z"
    />
    <path fill="#FFF" d="M59.051 31.334h-6v-10h24v10h-6" />
    <path
      fill="#8D4520"
      d="M77.051 34.334h-6v-6h3v-4h-18v4h3v6h-6a3 3 0 0 1-3-3v-10a3 3 0 0 1 3-3h24a3 3 0 0 1 3 3v10a3 3 0 0 1-3 3z"
    />
    <path
      fill="#8D4520"
      d="M47.064 47.214c-.83 0-1.656-.343-2.249-1.014-4.615-5.224-6.764-10.582-6.764-16.866 0-14.888 12.112-27 27-27s27 12.112 27 27c0 5.773-1.8 11.282-5.207 15.934a3 3 0 1 1-4.841-3.545 20.83 20.83 0 0 0 4.047-12.389c0-11.58-9.42-21-21-21s-21 9.42-21 21c0 4.799 1.623 8.775 5.26 12.894a2.999 2.999 0 0 1-2.246 4.986z"
    />
    <circle fill="#8D4520" cx={56.051} cy={52.334} r={3} />
    <circle fill="#8D4520" cx={56.051} cy={52.334} r={3} />
    <circle fill="#8D4520" cx={40.314} cy={65.098} r={3} />
    <circle fill="#8D4520" cx={36.465} cy={84.992} r={3} />
    <circle fill="#8D4520" cx={46.302} cy={102.706} r={3} />
    <circle fill="#8D4520" cx={65.225} cy={109.951} r={3} />
    <circle fill="#8D4520" cx={84.378} cy={103.339} r={3} />
    <circle fill="#8D4520" cx={94.799} cy={85.962} r={3} />
    <circle fill="#8D4520" cx={91.613} cy={65.952} r={3} />
    <circle fill="#8D4520" cx={76.31} cy={52.671} r={3} />
    <path
      fill="#FFF"
      d="M72.147 78.76c2.631 3.199 2.445 5.11-.754 7.741-3.199 2.631-5.11 2.445-7.742-.754-2.631-3.199-14.6-26.413-14.6-26.413S69.516 75.56 72.147 78.76z"
    />
    <path
      fill="#8D4520"
      d="M67.603 90.324c-2.461 0-4.23-1.768-5.496-3.307-2.689-3.269-13.598-24.371-14.833-26.767a2 2 0 0 1 3.02-2.483c2.112 1.674 20.71 16.453 23.398 19.723 1.164 1.415 2.472 3.324 2.255 5.548-.217 2.224-1.869 3.844-3.284 5.008-1.916 1.576-3.477 2.278-5.06 2.278zm-12.36-23.46c4.168 7.872 8.624 15.996 9.953 17.612 1.022 1.244 1.81 1.848 2.407 1.848.568 0 1.416-.46 2.52-1.368 1.168-.96 1.788-1.736 1.844-2.307.056-.57-.403-1.452-1.363-2.619-1.33-1.616-8.441-7.556-15.361-13.166zm53.751-13.43a1.99 1.99 0 0 1-1.414-.586l-7.295-7.295a2 2 0 1 1 2.828-2.828l7.295 7.295a2 2 0 0 1-1.414 3.414zM57.488 28.334h15.313v4H57.488z"
    />
  </svg>
);

export default Stopwatch;
