import React from "react";

const NuclearSymbol = props => (
  <svg viewBox="0 0 1010 1010"   {...props}>
    <path
      fill="#C9D3D7"
      d="M505.02 20.404c-267.644 0-484.616 216.992-484.616 484.576 0 267.643 216.972 484.616 484.616 484.616 267.603 0 484.576-216.973 484.576-484.616 0-267.584-216.973-484.576-484.576-484.576z"
    />
    <path
      fill="#78909C"
      d="M770.85 505c0 146.814-119.057 265.85-265.85 265.85-146.814 0-265.85-119.037-265.85-265.85S358.187 239.16 505 239.16c146.793 0 265.85 119.027 265.85 265.84z"
    />
    <path
      fill="#FFAB00"
      d="M504.96 456.64c-26.66 0-48.34 21.69-48.34 48.34 0 26.68 21.68 48.4 48.34 48.4 26.661 0 48.4-21.72 48.4-48.4 0-26.65-21.739-48.34-48.4-48.34zM145.718 521c5.26 116.467 67.389 224.106 165.783 287.092L421.51 617.56c-31.084-23.113-51.389-58.203-55.872-96.56h-219.92zm442.771 96.521L698.5 808.091C796.894 745.106 859.082 637.468 864.322 521h-220.04c-4.425 38.357-24.749 73.447-55.793 96.521zM504.96 364.752c19.169 0 38.198 3.995 55.792 11.627l109.991-190.541c-102.1-53.122-229.446-53.092-331.486 0l109.99 190.54c17.575-7.63 36.544-11.626 55.713-11.626z"
    />
    <g fill="#43525B">
      <path d="M505.02 0C226.557 0 0 226.537 0 504.98 0 783.443 226.557 1010 505.02 1010 783.463 1010 1010 783.443 1010 504.98 1010 226.537 783.463 0 505.02 0zm0 969.191c-255.967 0-464.212-208.244-464.212-464.211 0-255.947 208.245-464.172 464.212-464.172 255.947 0 464.171 208.225 464.171 464.172 0 255.967-208.224 464.211-464.171 464.211z" />
      <path d="M589.805 504.98c0-46.746-38.059-84.774-84.845-84.774-46.746 0-84.764 38.028-84.764 84.774 0 46.766 38.018 84.825 84.764 84.825 46.786 0 84.845-38.059 84.845-84.825zm-84.845 44.016c-24.23 0-43.956-19.746-43.956-44.016 0-24.24 19.726-43.966 43.956-43.966 24.29 0 44.036 19.726 44.036 43.966 0 24.27-19.746 44.016-44.036 44.016z" />
      <path d="M880.682 484.576H629.218c-11.278 0-20.404 9.136-20.404 20.404 0 36.863-19.906 71.275-51.966 89.826-9.744 5.64-13.073 18.112-7.453 27.876L675.146 840.51a20.406 20.406 0 0 0 12.394 9.485c1.732.478 3.506.717 5.28.717 3.567 0 7.093-.937 10.222-2.75 122.165-70.697 198.043-202.107 198.043-342.983 0-11.268-9.127-20.404-20.404-20.404zM699.994 801.894L594.208 618.657c29.251-22.994 48.66-56.549 53.959-93.271H859.68c-6.477 111.763-66.014 214.82-159.686 276.51zM425.257 407.683a20.398 20.398 0 0 0 12.394 9.505c5.18 1.395 10.8.677 15.502-2.042 31.563-18.272 72.032-18.283 103.714.01a20.29 20.29 0 0 0 10.182 2.73c7.055 0 13.908-3.657 17.694-10.203l125.752-217.8c5.62-9.753 2.292-22.226-7.452-27.865-60.076-34.75-128.562-53.113-198.023-53.113-69.501 0-137.986 18.372-198.043 53.113-9.744 5.639-13.071 18.112-7.452 27.866l125.732 217.8zm239.409-220.04L558.879 370.859c-17.115-6.904-35.447-10.49-53.919-10.49-18.451 0-36.743 3.586-53.84 10.47L345.355 187.642c98.693-49.735 220.54-49.755 319.312 0zM453.133 594.865c-32.08-18.59-52.006-53.043-52.006-89.885 0-11.268-9.126-20.404-20.404-20.404H129.338c-11.277 0-20.403 9.136-20.403 20.404 0 140.875 75.877 272.306 198.022 342.983a20.374 20.374 0 0 0 10.222 2.75c1.774 0 3.547-.24 5.28-.717a20.405 20.405 0 0 0 12.394-9.485l125.732-217.79c5.62-9.763 2.292-22.237-7.452-27.856zm-143.127 207.03c-93.671-61.69-153.19-164.747-159.666-276.51h211.453c5.3 36.723 24.708 70.297 53.98 93.312L310.006 801.894z" />
    </g>
  </svg>
);

export default NuclearSymbol;
