import React from "react";

const Billiards = props => (
  <svg viewBox="0 0 128 128"   {...props}>
    <ellipse fill="#B8CBCD" cx={65.384} cy={114.604} rx={47.667} ry={10.186} />
    <path
      fill="#F7BB83"
      d="M80.771 39.59c-2.657-4.816-7.073-4.854-9.813-.086l-40.035 69.66c-2.74 4.769-.482 8.67 5.018 8.67h78c5.5 0 7.826-3.94 5.169-8.756L80.771 39.59z"
    />
    <path
      fill="#6E4123"
      d="M113.94 120.834h-78c-3.721 0-6.708-1.474-8.195-4.044-1.487-2.57-1.277-5.894.576-9.12l40.036-69.66c1.846-3.214 4.581-5.057 7.504-5.057 2.971 0 5.718 1.891 7.537 5.188l38.338 69.488c1.792 3.249 1.949 6.586.431 9.158-1.518 2.572-4.517 4.047-8.227 4.047zM75.861 38.953c-.695 0-1.577.784-2.302 2.046l-40.036 69.66c-.747 1.3-.966 2.469-.585 3.126.38.657 1.502 1.049 3.002 1.049h78c1.511 0 2.654-.41 3.06-1.097.406-.687.213-1.887-.518-3.209L78.144 41.039c-.71-1.287-1.585-2.086-2.283-2.086z"
    />
    <circle fill="#FFF" cx={75.44} cy={57.334} r={6.5} />
    <path
      fill="#6E4123"
      d="M75.44 65.834c-4.687 0-8.5-3.813-8.5-8.5s3.813-8.5 8.5-8.5c4.687 0 8.5 3.813 8.5 8.5s-3.814 8.5-8.5 8.5zm0-13c-2.481 0-4.5 2.019-4.5 4.5s2.019 4.5 4.5 4.5 4.5-2.019 4.5-4.5-2.019-4.5-4.5-4.5z"
    />
    <circle fill="#B2EDA6" cx={66.44} cy={73.334} r={6.5} />
    <path
      fill="#6E4123"
      d="M66.44 81.834c-4.687 0-8.5-3.813-8.5-8.5 0-4.687 3.813-8.5 8.5-8.5 4.687 0 8.5 3.813 8.5 8.5 0 4.687-3.814 8.5-8.5 8.5zm0-13c-2.481 0-4.5 2.019-4.5 4.5s2.019 4.5 4.5 4.5 4.5-2.019 4.5-4.5-2.019-4.5-4.5-4.5z"
    />
    <circle fill="#B2EDA6" cx={84.44} cy={73.334} r={6.5} />
    <path
      fill="#6E4123"
      d="M84.44 81.834c-4.687 0-8.5-3.813-8.5-8.5 0-4.687 3.813-8.5 8.5-8.5 4.687 0 8.5 3.813 8.5 8.5 0 4.687-3.814 8.5-8.5 8.5zm0-13c-2.481 0-4.5 2.019-4.5 4.5s2.019 4.5 4.5 4.5 4.5-2.019 4.5-4.5-2.019-4.5-4.5-4.5z"
    />
    <circle fill="#FFBAB0" cx={57.44} cy={89.334} r={6.5} />
    <path
      fill="#6E4123"
      d="M57.44 97.834c-4.687 0-8.5-3.813-8.5-8.5 0-4.687 3.813-8.5 8.5-8.5 4.687 0 8.5 3.813 8.5 8.5 0 4.687-3.814 8.5-8.5 8.5zm0-13c-2.481 0-4.5 2.019-4.5 4.5s2.019 4.5 4.5 4.5 4.5-2.019 4.5-4.5-2.019-4.5-4.5-4.5z"
    />
    <circle fill="#FFBAB0" cx={75.44} cy={89.334} r={6.5} />
    <path
      fill="#6E4123"
      d="M75.44 97.834c-4.687 0-8.5-3.813-8.5-8.5 0-4.687 3.813-8.5 8.5-8.5 4.687 0 8.5 3.813 8.5 8.5 0 4.687-3.814 8.5-8.5 8.5zm0-13c-2.481 0-4.5 2.019-4.5 4.5s2.019 4.5 4.5 4.5 4.5-2.019 4.5-4.5-2.019-4.5-4.5-4.5z"
    />
    <circle fill="#FFBAB0" cx={93.44} cy={89.334} r={6.5} />
    <path
      fill="#6E4123"
      d="M93.44 97.834c-4.687 0-8.5-3.813-8.5-8.5 0-4.687 3.813-8.5 8.5-8.5 4.687 0 8.5 3.813 8.5 8.5 0 4.687-3.814 8.5-8.5 8.5zm0-13c-2.481 0-4.5 2.019-4.5 4.5s2.019 4.5 4.5 4.5 4.5-2.019 4.5-4.5-2.019-4.5-4.5-4.5z"
    />
    <circle fill="#F6E89A" cx={48.44} cy={105.334} r={6.5} />
    <path
      fill="#6E4123"
      d="M48.44 113.834c-4.687 0-8.5-3.813-8.5-8.5 0-4.687 3.813-8.5 8.5-8.5 4.686 0 8.5 3.813 8.5 8.5 0 4.687-3.814 8.5-8.5 8.5zm0-13c-2.482 0-4.5 2.019-4.5 4.5s2.018 4.5 4.5 4.5c2.481 0 4.5-2.019 4.5-4.5s-2.019-4.5-4.5-4.5z"
    />
    <circle fill="#F6E89A" cx={66.44} cy={105.334} r={6.5} />
    <path
      fill="#6E4123"
      d="M66.44 113.834c-4.687 0-8.5-3.813-8.5-8.5 0-4.687 3.813-8.5 8.5-8.5 4.687 0 8.5 3.813 8.5 8.5 0 4.687-3.814 8.5-8.5 8.5zm0-13c-2.481 0-4.5 2.019-4.5 4.5s2.019 4.5 4.5 4.5 4.5-2.019 4.5-4.5-2.019-4.5-4.5-4.5z"
    />
    <circle fill="#F6E89A" cx={84.44} cy={105.334} r={6.5} />
    <path
      fill="#6E4123"
      d="M84.44 113.834c-4.687 0-8.5-3.813-8.5-8.5 0-4.687 3.813-8.5 8.5-8.5 4.687 0 8.5 3.813 8.5 8.5 0 4.687-3.814 8.5-8.5 8.5zm0-13c-2.481 0-4.5 2.019-4.5 4.5s2.019 4.5 4.5 4.5 4.5-2.019 4.5-4.5-2.019-4.5-4.5-4.5z"
    />
    <circle fill="#F6E89A" cx={101.94} cy={105.334} r={6.5} />
    <path
      fill="#6E4123"
      d="M101.94 113.834c-4.687 0-8.5-3.813-8.5-8.5 0-4.687 3.813-8.5 8.5-8.5 4.687 0 8.5 3.813 8.5 8.5 0 4.687-3.814 8.5-8.5 8.5zm0-13c-2.481 0-4.5 2.019-4.5 4.5s2.019 4.5 4.5 4.5 4.5-2.019 4.5-4.5-2.019-4.5-4.5-4.5z"
    />
    <path
      fill="#F7BB83"
      d="M20.58 79.628l10.552 6.283-12.791 21.48-10.553-6.283z"
    />
    <path
      fill="#6E4123"
      d="M18.339 110.395a2.984 2.984 0 0 1-1.532-.423l-10.553-6.283a3 3 0 0 1-1.043-4.112l12.79-21.481a3 3 0 0 1 4.113-1.043l10.553 6.284a3 3 0 0 1 1.043 4.112l-12.79 21.48a3 3 0 0 1-2.581 1.466zm-6.437-10.327l5.397 3.214 9.721-16.325-5.398-3.214-9.72 16.325z"
    />
    <path
      fill="#F7BB83"
      d="M20.58 79.628l10.552 6.283-12.791 21.48-10.553-6.283z"
    />
    <path
      fill="#6E4123"
      d="M18.339 110.395a2.984 2.984 0 0 1-1.532-.423l-10.553-6.283a3 3 0 0 1-1.043-4.112l12.79-21.481a3 3 0 0 1 4.113-1.043l10.553 6.284a3 3 0 0 1 1.043 4.112l-12.79 21.48a3 3 0 0 1-2.581 1.466zm-6.437-10.327l5.397 3.214 9.721-16.325-5.398-3.214-9.72 16.325z"
    />
    <path
      fill="#F7BB83"
      d="M31.132 85.914l-12.79 21.48-10.553-6.283 12.79-21.481"
    />
    <path
      fill="#6E4123"
      d="M18.339 110.395a2.984 2.984 0 0 1-1.532-.423l-10.553-6.283a3 3 0 0 1-1.043-4.112l12.79-21.481a3 3 0 1 1 5.156 3.069l-11.256 18.903 5.397 3.214 11.255-18.903a3 3 0 0 1 5.156 3.069l-12.79 21.48a2.995 2.995 0 0 1-2.58 1.467z"
    />
    <path
      fill="#F7BB83"
      d="M22.268 80.636l43.818-73.93 7.145 4.255L29.413 84.89"
    />
    <path
      fill="#6E4123"
      d="M29.41 87.891a3 3 0 0 1-2.578-4.53l42.291-71.354-1.989-1.185-42.285 71.343a3.001 3.001 0 0 1-5.162-3.059l43.818-73.93a3.001 3.001 0 0 1 4.116-1.048l7.145 4.255a3 3 0 0 1 1.046 4.107L31.994 86.42a3 3 0 0 1-2.584 1.471z"
    />
    <path
      fill="#F6E89A"
      d="M66.084 6.706l7.145 4.255-5.628 9.451-7.145-4.254z"
    />
    <path
      fill="#6E4123"
      d="M67.604 22.412c-.357 0-.711-.096-1.023-.282l-7.146-4.254a1.998 1.998 0 0 1-.695-2.742l5.628-9.452a1.996 1.996 0 0 1 1.228-.915 1.995 1.995 0 0 1 1.515.22l7.145 4.255a2 2 0 0 1 .695 2.741l-5.627 9.451a2.001 2.001 0 0 1-1.72.978zm-4.404-6.95l3.708 2.208 3.581-6.014-3.708-2.208-3.581 6.014zM29.624 88.376a1.99 1.99 0 0 1-1.067-.311l-8.492-5.375a2 2 0 1 1 2.139-3.38l8.492 5.375a2 2 0 0 1-1.072 3.691z"
    />
  </svg>
);

export default Billiards;
