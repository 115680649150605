import React from "react";

const Sputnik = props => (
  <svg viewBox="0 0 64 42"   {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M12 31a.501.501 0 0 1-.433-.75c.019-.034 1.933-3.438 1.933-10.25 0-6.698-1.917-10.222-1.937-10.257a.5.5 0 1 1 .873-.488c.085.15 2.064 3.76 2.064 10.745 0 7.11-1.983 10.605-2.068 10.75A.499.499 0 0 1 12 31"
        fill="#f2b430"
      />
      <path
        d="M11 9C4.925 9 0 13.925 0 20s4.925 11 11 11 11-4.925 11-11S17.075 9 11 9m0 1c5.514 0 10 4.486 10 10s-4.486 10-10 10S1 25.514 1 20s4.486-10 10-10"
        fill="#1d7ed0"
      />
      <path
        d="M11 10a.5.5 0 0 1-.084-.993l41-7a.5.5 0 1 1 .167.986l-41 7A.476.476 0 0 1 11 10m47.002 31a.46.46 0 0 1-.104-.011l-47-10a.5.5 0 1 1 .207-.978l47 10a.5.5 0 0 1-.103.989m5.5-14.5l-.04-.001-52.5-4a.5.5 0 0 1-.46-.537.494.494 0 0 1 .538-.461l52.5 4a.5.5 0 0 1-.038.999M21 16.5a.5.5 0 0 1-.044-.998l22-2a.5.5 0 0 1 .091.996l-22 2a.37.37 0 0 1-.046.002m-18 3a.5.5 0 0 1-.5-.5c0-4.206 3.294-7.5 7.5-7.5a.5.5 0 0 1 0 1c-3.645 0-6.5 2.855-6.5 6.5a.5.5 0 0 1-.5.5"
        fill="#1d7ed0"
      />
      <path
        d="M14.703 1.553l1.155.26c.19.053.19.322 0 .374l-1.155.26a.271.271 0 0 0-.2.178l-.317 1.233c-.052.19-.32.19-.373 0l-.317-1.233a.27.27 0 0 0-.2-.177l-1.154-.261c-.19-.052-.19-.321 0-.373l1.154-.261a.273.273 0 0 0 .2-.177l.317-1.234c.052-.19.321-.19.373 0l.317 1.234a.273.273 0 0 0 .2.177m15 38l1.155.26c.19.053.19.322 0 .374l-1.155.26a.271.271 0 0 0-.2.178l-.317 1.233c-.052.19-.32.19-.373 0l-.317-1.233a.27.27 0 0 0-.2-.177l-1.154-.261c-.19-.052-.19-.321 0-.373l1.154-.261a.273.273 0 0 0 .2-.177l.317-1.234c.052-.19.321-.19.373 0l.317 1.234a.273.273 0 0 0 .2.177"
        fill="#f2b430"
      />
    </g>
  </svg>
);

export default Sputnik;
