import React from "react";

const Molecule = props => (
  <svg viewBox="0 0 997.885 1010"   {...props}>
    <path
      fill="#FFAB00"
      d="M707.814 505c0 115.371-93.53 208.883-208.872 208.883-115.37 0-208.882-93.512-208.882-208.883 0-115.35 93.512-208.882 208.882-208.882 115.341 0 208.872 93.531 208.872 208.882zm189.107-299.166c44.444 0 80.56 36.145 80.56 80.6 0 44.415-36.135 80.52-80.56 80.52-44.395 0-80.53-36.105-80.53-80.52 0-44.455 36.135-80.6 80.53-80.6zm-795.967 161.12c-44.415 0-80.55-36.126-80.55-80.52 0-44.455 36.135-80.6 80.55-80.6 44.404 0 80.54 36.145 80.54 80.6 0 44.394-36.136 80.52-80.54 80.52z"
    />
    <path
      fill="#AEBCC4"
      d="M100.954 804.166c-44.415 0-80.55-36.145-80.55-80.54s36.135-80.56 80.55-80.56c44.404 0 80.54 36.125 80.54 80.56 0 44.394-36.136 80.54-80.54 80.54zm317.428-703.182c0-44.434 36.156-80.58 80.58-80.58 44.395 0 80.53 36.146 80.53 80.58 0 44.435-36.135 80.54-80.53 80.54-44.424 0-80.58-36.105-80.58-80.54z"
    />
    <path
      fill="#FFF"
      d="M418.382 505.04c0-44.434 36.136-80.58 80.58-80.58 44.395 0 80.53 36.146 80.53 80.58 0 44.435-36.135 80.54-80.53 80.54-44.444 0-80.58-36.145-80.58-80.54z"
    />
    <path
      fill="#FFAB00"
      d="M579.492 909.056c0 44.434-36.135 80.54-80.55 80.54-44.404 0-80.56-36.106-80.56-80.54 0-44.435 36.156-80.58 80.56-80.58 44.414 0 80.55 36.185 80.55 80.58z"
    />
    <path
      fill="#AEBCC4"
      d="M885.015 804.166c-44.434 0-80.57-36.145-80.57-80.54 0-44.474 36.156-80.58 80.57-80.58 44.405 0 80.56 36.105 80.56 80.58 0 44.394-36.155 80.54-80.56 80.54z"
    />
    <path
      fill="#43525B"
      d="M798.786 699.456c-3.397 0-6.845-.856-10.022-2.65l-617.363-349c-9.813-5.54-13.26-17.993-7.72-27.797 5.539-9.804 17.963-13.251 27.806-7.712l617.362 349c9.813 5.54 13.26 17.994 7.72 27.798-3.755 6.635-10.67 10.361-17.783 10.361z"
    />
    <path
      fill="#43525B"
      d="M181.474 717.07c-7.054 0-13.908-3.646-17.694-10.202-5.629-9.764-2.292-22.237 7.472-27.876l634.977-366.615c9.733-5.66 22.227-2.291 27.876 7.473 5.628 9.763 2.292 22.236-7.472 27.876L191.656 714.34a20.321 20.321 0 0 1-10.182 2.73z"
    />
    <path
      fill="#43525B"
      d="M498.942 847.485c-11.268 0-20.404-9.126-20.404-20.404l-.02-627.484c0-11.278 9.136-20.404 20.404-20.404s20.404 9.126 20.404 20.404l.02 627.484c0 11.278-9.136 20.404-20.404 20.404zm397.979-460.127c-55.652 0-100.935-45.272-100.935-100.924 0-55.693 45.283-101.004 100.935-101.004 55.673 0 100.964 45.311 100.964 101.004 0 55.652-45.291 100.924-100.964 100.924zm0-161.12c-33.156 0-60.126 27-60.126 60.196 0 33.156 26.97 60.116 60.126 60.116 33.166 0 60.156-26.96 60.156-60.116 0-33.197-26.99-60.196-60.156-60.196zm-795.967 161.12C45.291 387.358 0 342.086 0 286.434 0 230.74 45.291 185.43 100.954 185.43S201.9 230.74 201.9 286.434c0 55.652-45.282 100.924-100.945 100.924zm0-161.12c-33.167 0-60.146 27-60.146 60.196 0 33.156 26.98 60.116 60.146 60.116 33.156 0 60.136-26.96 60.136-60.116 0-33.197-26.98-60.196-60.136-60.196zm0 598.332C45.291 824.57 0 779.28 0 723.626c0-55.673 45.291-100.964 100.954-100.964S201.9 667.953 201.9 723.626c0 55.653-45.282 100.944-100.945 100.944zm0-161.1c-33.167 0-60.146 26.98-60.146 60.156 0 33.157 26.98 60.136 60.146 60.136 33.156 0 60.136-26.979 60.136-60.136 0-33.177-26.98-60.156-60.136-60.156z"
    />
    <path
      fill="#43525B"
      d="M498.962 201.928c-55.683 0-100.983-45.291-100.983-100.944C397.978 45.291 443.279 0 498.962 0c55.653 0 100.935 45.291 100.935 100.984 0 55.653-45.282 100.944-100.935 100.944zm0-161.12c-33.176 0-60.176 27-60.176 60.176 0 33.157 27 60.136 60.176 60.136 33.157 0 60.127-26.98 60.127-60.136 0-33.176-26.97-60.176-60.127-60.176z"
    />
    <path
      fill="#EBEEF0"
      d="M418.382 505.04c0-44.434 36.136-80.58 80.58-80.58 44.395 0 80.53 36.146 80.53 80.58 0 44.435-36.135 80.54-80.53 80.54-44.444 0-80.58-36.145-80.58-80.54z"
    />
    <path
      fill="#43525B"
      d="M498.962 605.984c-55.683 0-100.983-45.292-100.983-100.944 0-55.692 45.3-100.984 100.983-100.984 55.653 0 100.935 45.292 100.935 100.984 0 55.652-45.282 100.944-100.935 100.944zm0-161.12c-33.176 0-60.176 27-60.176 60.176 0 33.156 27 60.136 60.176 60.136 33.157 0 60.127-26.98 60.127-60.136 0-33.177-26.97-60.177-60.127-60.177zm-.02 565.136c-55.672 0-100.963-45.291-100.963-100.944 0-55.693 45.29-100.984 100.963-100.984 55.664 0 100.955 45.291 100.955 100.984 0 55.653-45.292 100.944-100.955 100.944zm0-161.12c-33.166 0-60.156 26.999-60.156 60.176 0 33.157 26.99 60.135 60.156 60.135s60.147-26.978 60.147-60.135c0-33.177-26.98-60.176-60.147-60.176zM885.015 824.57c-55.672 0-100.974-45.29-100.974-100.944 0-55.692 45.302-100.983 100.974-100.983 55.673 0 100.964 45.29 100.964 100.983 0 55.653-45.29 100.944-100.964 100.944zm0-161.12c-33.176 0-60.166 27-60.166 60.176 0 33.157 26.99 60.136 60.166 60.136 33.167 0 60.156-26.979 60.156-60.136 0-33.177-26.99-60.176-60.156-60.176z"
    />
  </svg>
);

export default Molecule;
