import React from "react";

const Weight = props => (
  <svg viewBox="0 0 128 128"   {...props}>
    <path
      fill="#B9E3ED"
      d="M65.158 59.334a7.936 7.936 0 0 1-2.921-.558c-4.152-1.638-6.208-6.38-4.594-10.592.052-.135 5.216-13.613 8.13-21.743.554-1.547.665-2.529.657-2.981-.558-.32-2.47-1.154-7.229-1.154H36.856c-4.759 0-6.672.834-7.229 1.154-.008.452.103 1.434.657 2.98 2.915 8.13 8.079 21.608 8.13 21.743 1.614 4.212-.443 8.954-4.594 10.592-4.149 1.637-8.825-.448-10.44-4.66-.214-.559-5.28-13.781-8.255-22.083-3.163-8.825-1.101-14.627 1.186-17.941 3.786-5.483 10.805-7.758 20.844-7.758H58.84c10.039 0 17.119 2.274 20.906 7.758 2.287 3.314 4.35 9.116 1.186 17.941-2.975 8.301-8.041 21.524-8.256 22.083-1.24 3.238-4.29 5.219-7.518 5.219z"
    />
    <path
      fill="#324654"
      d="M30.901 61.334c-4.124 0-7.896-2.612-9.388-6.502-.214-.558-5.272-13.757-8.271-22.125-3.449-9.623-1.13-16.052 1.423-19.752 4.006-5.801 11.362-8.622 22.49-8.622H58.84c11.168 0 18.545 2.82 22.552 8.621 2.554 3.7 4.873 10.129 1.423 19.753-2.992 8.348-8.057 21.565-8.271 22.124-1.491 3.889-5.263 6.502-9.386 6.502a9.914 9.914 0 0 1-3.656-.698c-5.16-2.036-7.729-7.943-5.727-13.168.051-.132 5.211-13.601 8.115-21.702.137-.382.239-.712.315-.995-.952-.23-2.531-.465-5.004-.465H36.856c-2.474 0-4.053.235-5.004.465.076.282.178.613.315.995 2.904 8.1 8.064 21.569 8.115 21.7 2.003 5.227-.566 11.133-5.727 13.169a9.88 9.88 0 0 1-3.654.7zm6.253-53c-9.85 0-15.95 2.19-19.198 6.894-2.021 2.928-3.826 8.104-.949 16.13 2.984 8.327 8.027 21.486 8.24 22.042.917 2.39 3.135 3.935 5.652 3.934.747 0 1.482-.141 2.186-.418 3.129-1.234 4.681-4.83 3.46-8.016-.05-.13-5.227-13.643-8.145-21.784-.695-1.94-.783-3.145-.773-3.69a1.998 1.998 0 0 1 1.004-1.7c.923-.53 3.22-1.419 8.225-1.419h22.345c5.003 0 7.3.889 8.224 1.419a2 2 0 0 1 1.004 1.7c.01.545-.078 1.75-.773 3.69-2.919 8.141-8.096 21.654-8.146 21.786-1.221 3.184.331 6.78 3.46 8.014a5.928 5.928 0 0 0 2.188.418c2.517 0 4.735-1.544 5.651-3.934.214-.557 5.264-13.734 8.241-22.042 2.877-8.026 1.072-13.202-.949-16.13-3.204-4.639-9.504-6.894-19.26-6.894H37.154z"
    />
    <path
      fill="#B8CBCD"
      d="M113.886 109.521c0 5.626-21.01 9.813-47.335 9.813-26.326 0-48-4.187-48-9.813s21.341-10.188 47.667-10.188c26.325.001 47.668 4.562 47.668 10.188z"
    />
    <circle fill="#B9E3ED" cx={48.371} cy={69.523} r={35.91} />
    <path
      fill="#324654"
      d="M48.371 107.434c-20.904 0-37.91-17.007-37.91-37.911s17.006-37.911 37.91-37.911 37.91 17.007 37.91 37.911-17.006 37.911-37.91 37.911zm0-71.821c-18.698 0-33.91 15.212-33.91 33.911 0 18.698 15.212 33.911 33.91 33.911 18.698 0 33.91-15.212 33.91-33.911S67.07 35.613 48.371 35.613z"
    />
    <path
      fill="#FFF"
      d="M25.947 100.146a2.99 2.99 0 0 1-2.121-.878C16.472 91.915 12.409 82.2 12.384 71.913c-.025-10.304 4.006-20.024 11.352-27.37 15.201-15.201 38.977-15.238 54.129-.088a3 3 0 1 1-4.243 4.243c-12.772-12.773-32.821-12.733-45.643.087-6.208 6.209-9.616 14.417-9.595 23.113.021 8.69 3.46 16.904 9.684 23.127a3 3 0 0 1-2.121 5.121z"
    />
    <path
      fill="#324654"
      d="M48.371 108.434c-21.455 0-38.91-17.455-38.91-38.911 0-9.059 2.707-18.025 7.303-24.385a780.93 780.93 0 0 1-4.463-12.093c-3.592-10.022-1.145-16.765 1.541-20.657 4.207-6.092 11.833-9.054 23.313-9.054H58.84c11.521 0 19.167 2.961 23.375 9.053 2.687 3.893 5.134 10.636 1.542 20.659a901.763 901.763 0 0 1-4.662 12.615 38.913 38.913 0 0 1 8.187 23.863c-.001 21.455-17.456 38.91-38.911 38.91zm-11.217-99.1c-9.492 0-15.331 2.053-18.375 6.462-1.888 2.735-3.564 7.597-.831 15.224 1.351 3.77 2.899 7.952 5.018 13.561a3 3 0 0 1-.486 2.962c-4.396 5.362-7.019 13.579-7.019 21.98 0 18.147 14.764 32.911 32.91 32.911s32.91-14.764 32.91-32.911a32.91 32.91 0 0 0-7.883-21.358 3 3 0 0 1-.526-3.008c2.226-5.897 3.988-10.653 5.236-14.136 2.734-7.627 1.057-12.489-.831-15.225-3.003-4.348-9.034-6.462-18.437-6.462H37.154zm-3.488 29.303a3.001 3.001 0 0 1-2.809-1.947 642.824 642.824 0 0 1-3.396-9.236c-.712-1.986-.846-3.296-.832-4.045a3 3 0 0 1 1.506-2.55c1.233-.708 3.443-1.525 8.418-1.525h23c4.973 0 7.138.816 8.372 1.524a3.002 3.002 0 0 1 1.507 2.551c.013.749-.121 2.059-.832 4.044a640.266 640.266 0 0 1-3.281 8.926 2.998 2.998 0 0 1-3.96 1.719c-3.468-1.439-8.326-2.265-13.329-2.265-4.852 0-9.281.859-13.163 2.553a2.995 2.995 0 0 1-1.201.251zm-.517-13.094c.714 1.99 1.418 3.917 2.271 6.216 3.873-1.279 8.1-1.925 12.608-1.925 4.577 0 8.972.592 12.694 1.692a566.667 566.667 0 0 0 2.185-5.982c-.88-.132-2.089-.237-3.707-.237H36.856c-1.618 0-2.827.105-3.707.236z"
    />
    <path
      fill="#324654"
      d="M48.371 107.434c-20.904 0-37.91-17.007-37.91-37.911s17.006-37.911 37.91-37.911 37.91 17.007 37.91 37.911-17.006 37.911-37.91 37.911zm0-71.821c-18.698 0-33.91 15.212-33.91 33.911 0 18.698 15.212 33.911 33.91 33.911 18.698 0 33.91-15.212 33.91-33.911S67.07 35.613 48.371 35.613z"
    />
    <path
      opacity={0.2}
      fill="#324654"
      d="M44.235 106.511c-8.922.001-17.622-3.467-24.617-10.462a2.998 2.998 0 0 1 0-4.242 2.998 2.998 0 0 1 4.242 0c12.27 12.269 30.993 11.357 44.52-2.169 13.731-13.732 14.915-31.728 2.879-43.764a2.998 2.998 0 0 1 0-4.242 2.998 2.998 0 0 1 4.242 0c14.534 14.534 13.35 36.019-2.878 52.249-8.393 8.392-18.527 12.63-28.388 12.63z"
    />
    <path
      fill="#324654"
      d="M50.551 86.334a2 2 0 0 1-2-2v-6h-10a2 2 0 0 1-1.691-3.068l12-19a2 2 0 0 1 3.691 1.067v17h4a2 2 0 0 1 0 4h-4v6a2 2 0 0 1-2 2.001zm-8.372-12h6.372V64.246l-6.372 10.088z"
    />
    <path
      fill="#B2EDA6"
      d="M83.145 80.059A6.367 6.367 0 0 1 77.212 76c-.158-.407-3.908-10.052-6.11-16.109-2.398-6.596-.82-10.953.926-13.445 2.881-4.113 7.958-6.112 15.523-6.112h16.528c7.565 0 12.642 1.999 15.523 6.112 1.746 2.493 3.324 6.849.926 13.445-2.202 6.057-5.951 15.702-6.11 16.109a6.364 6.364 0 0 1-11.862-4.613c.039-.099 3.857-9.922 6.011-15.845.326-.896.425-1.476.453-1.801-.544-.242-2.468-.407-5.468-.407h-16c-3 0-4.396.166-4.94.407.027.325.127.905.453 1.801 2.154 5.925 5.972 15.746 6.01 15.845a6.364 6.364 0 0 1-5.93 8.672z"
    />
    <path
      fill="#3C663E"
      d="M108.486 82.06a8.343 8.343 0 0 1-3.031-.57c-4.298-1.673-6.436-6.529-4.764-10.827.037-.095 3.623-9.32 5.796-15.26-.7-.039-1.654-.068-2.937-.068h-16c-1.037 0-1.823.021-2.416.05 1.909 5.218 4.882 12.905 5.772 15.197 1.703 4.378-.433 9.235-4.732 10.907a8.338 8.338 0 0 1-3.03.57 8.315 8.315 0 0 1-7.796-5.333c-.411-1.059-4.006-10.32-6.125-16.151-2.693-7.405-.852-12.394 1.167-15.276 3.283-4.687 8.896-6.964 17.161-6.964h16.528c8.265 0 13.879 2.278 17.161 6.964 2.018 2.882 3.86 7.871 1.167 15.276-2.243 6.17-6.088 16.055-6.126 16.151a8.31 8.31 0 0 1-7.795 5.334zM87.551 51.334h16c4.976 0 5.923.421 6.28.58a2 2 0 0 1 1.181 1.995c-.055.656-.246 1.435-.566 2.316-2.161 5.942-5.989 15.79-6.027 15.887a4.37 4.37 0 0 0 4.067 5.947 4.335 4.335 0 0 0 4.067-2.784c.039-.099 3.866-9.939 6.095-16.068 2.104-5.788.789-9.51-.685-11.614-2.479-3.539-7.021-5.259-13.885-5.259H87.551c-6.864 0-11.406 1.72-13.885 5.259-1.474 2.104-2.789 5.827-.685 11.614 2.106 5.791 5.686 15.015 6.087 16.048.666 1.711 2.263 2.804 4.076 2.804a4.37 4.37 0 0 0 4.065-5.947c-.956-2.464-4.118-10.638-6.026-15.887-.32-.88-.511-1.659-.566-2.317a2 2 0 0 1 1.181-1.995c.678-.3 1.843-.579 5.753-.579z"
    />
    <circle fill="#B2EDA6" cx={95.726} cy={88.475} r={24.859} />
    <path
      fill="#3C663E"
      d="M95.726 115.334c-14.81 0-26.859-12.049-26.859-26.859 0-14.811 12.049-26.859 26.859-26.859 14.811 0 26.86 12.049 26.86 26.859 0 14.81-12.05 26.859-26.86 26.859zm0-49.719c-12.604 0-22.859 10.255-22.859 22.859s10.254 22.859 22.859 22.859c12.605 0 22.86-10.255 22.86-22.859s-10.255-22.859-22.86-22.859z"
    />
    <path
      fill="#B2EDA6"
      d="M113.886 71.499c6.7 11.96 5.956 21.898-3.752 31.606-9.708 9.709-25.448 9.709-35.156 0"
    />
    <path
      opacity={0.2}
      fill="#3C663E"
      d="M92.556 112.386c-7.174 0-13.919-2.794-18.992-7.867a2 2 0 1 1 2.828-2.828 22.712 22.712 0 0 0 16.164 6.695 22.712 22.712 0 0 0 16.164-6.695c8.771-8.771 9.826-17.781 3.421-29.214a2.001 2.001 0 0 1 3.491-1.955c7.328 13.082 6.03 23.885-4.083 33.998-5.074 5.072-11.819 7.866-18.993 7.866z"
    />
    <path
      fill="#FFF"
      d="M81.329 110.456a1.992 1.992 0 0 1-1.414-.586c-5.073-5.073-7.867-11.818-7.867-18.992 0-7.174 2.794-13.919 7.867-18.992 5.081-5.082 11.653-7.912 18.503-7.968h.207c6.813 0 13.315 2.753 18.329 7.768a2 2 0 1 1-2.828 2.828c-4.3-4.3-9.869-6.645-15.675-6.595-5.794.047-11.373 2.461-15.708 6.796a22.712 22.712 0 0 0-6.695 16.164 22.712 22.712 0 0 0 6.695 16.164 2 2 0 0 1-1.414 3.413z"
    />
    <path
      fill="#3C663E"
      d="M101.316 101.334H90.139a2 2 0 0 1-2-2c0-3.164 2.773-5.704 5.71-8.393 2.287-2.095 4.879-4.469 4.879-6.313 0-2.4-2.021-3.294-3.294-3.294-1.45 0-3.883.504-3.883 3.882a2 2 0 0 1-4 0c0-5.445 3.959-7.882 7.883-7.882 3.585 0 7.294 2.729 7.294 7.294 0 3.605-3.141 6.482-6.178 9.263-1.18 1.081-2.534 2.321-3.427 3.443h8.193a2 2 0 0 1 0 4z"
    />
    <path
      fill="#3C663E"
      d="M95.726 116.334c-15.627 0-28.341-12.498-28.341-27.859 0-5.876 1.926-12.339 4.99-16.981-.277-.707-.569-1.445-.871-2.207-1.036-2.62-2.21-5.59-3.221-8.371-2.839-7.809-.867-13.115 1.288-16.191 3.483-4.973 9.365-7.391 17.98-7.391h16.528c8.615 0 14.497 2.417 17.98 7.391 2.155 3.076 4.128 8.382 1.288 16.192a645.536 645.536 0 0 1-4.009 10.67c2.804 4.415 4.505 10.706 4.505 16.888-.001 15.361-12.614 27.859-28.117 27.859zm-8.175-73c-6.514 0-10.788 1.581-13.066 4.833-1.918 2.738-2.113 6.438-.563 10.699.982 2.702 2.141 5.631 3.162 8.215.53 1.339 1.032 2.61 1.475 3.757a3.001 3.001 0 0 1-.434 2.926c-2.835 3.631-4.74 9.543-4.74 14.711 0 11.849 10.231 21.859 22.341 21.859 12.195 0 22.117-9.806 22.117-21.859 0-5.538-1.67-11.29-4.255-14.653a2.998 2.998 0 0 1-.42-2.908 678.289 678.289 0 0 0 4.541-12.047c1.549-4.261 1.354-7.961-.563-10.699-2.278-3.252-6.552-4.833-13.066-4.833H87.551zm17.693 24.072c-.375 0-.75-.07-1.105-.211-2.178-.863-5.087-1.32-8.412-1.32-3.595 0-6.315.378-8.317 1.156a3.004 3.004 0 0 1-3.889-1.724 540.623 540.623 0 0 1-3.276-8.741c-.351-.962-.56-1.829-.622-2.574a3 3 0 0 1 1.77-2.993c.899-.4 2.291-.666 6.159-.666h16c5.188 0 6.2.45 6.686.666a3 3 0 0 1 1.771 2.993c-.063.744-.272 1.61-.623 2.574a557.47 557.47 0 0 1-3.34 8.912 2.999 2.999 0 0 1-2.802 1.928zm-9.518-7.531c2.103 0 4.92.166 7.716.834.609-1.606 1.138-3.02 1.633-4.36-.444-.01-.95-.016-1.524-.016h-16c-.365 0-.697.002-.999.007.48 1.301.991 2.665 1.587 4.236 2.153-.47 4.662-.701 7.587-.701z"
    />
    <path
      fill="#3C663E"
      d="M95.726 115.334c-14.81 0-26.859-12.049-26.859-26.859 0-14.811 12.049-26.859 26.859-26.859 14.811 0 26.86 12.049 26.86 26.859 0 14.81-12.05 26.859-26.86 26.859zm0-49.719c-12.604 0-22.859 10.255-22.859 22.859s10.254 22.859 22.859 22.859c12.605 0 22.86-10.255 22.86-22.859s-10.255-22.859-22.86-22.859z"
    />
  </svg>
);

export default Weight;
