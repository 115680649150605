import React from "react";

const Return = props => (
  <svg viewBox="0 0 48 48"   {...props}>
    <path
      fill="#F16362"
      d="M43.988 12.508h-7v12a1 1 0 0 1-1 1 .963.963 0 0 1-.544-.184l-1.456-.816-1.456.816a.976.976 0 0 1-.544.184 1 1 0 0 1-1-1v-12h-10a1 1 0 0 0-1 1v23a1 1 0 0 0 1 1h23a1 1 0 0 0 1-1v-23a1 1 0 0 0-1-1z"
    />
    <path
      fill="#384D68"
      d="M4.465 15.508l4.213-4.237a1.048 1.048 0 0 0 0-1.474 1.03 1.03 0 0 0-1.464 0l-4.87 4.897a1.018 1.018 0 0 0-.248.45c-.161.38-.091.835.217 1.146l4.87 4.897a1.03 1.03 0 0 0 1.464 0 1.046 1.046 0 0 0 0-1.474l-4.182-4.205zm6.994 1h4.529a7 7 0 0 1 7 7v10a1 1 0 1 0 2 0v-10a9 9 0 0 0-9-9h-4.529l3.219-3.237a1.048 1.048 0 0 0 0-1.474 1.03 1.03 0 0 0-1.464 0l-4.87 4.897a1.018 1.018 0 0 0-.248.45c-.161.38-.091.835.217 1.146l4.87 4.897a1.03 1.03 0 0 0 1.464 0 1.048 1.048 0 0 0 0-1.474l-3.188-3.205zm33.529-7h-11a1 1 0 0 0-1 1V20.64l-1.445-.964-.01.016c-.159-.106-.339-.184-.544-.184s-.386.077-.544.184l-.01-.016-1.445.964V10.508a1 1 0 0 0-1-1h-7a1 1 0 1 0 0 2h6v11a1 1 0 0 0 1 1 .963.963 0 0 0 .544-.184l.01.016 2.445-1.63 2.445 1.63.01-.016a.976.976 0 0 0 .544.184 1 1 0 0 0 1-1v-11h9v25h-26v-9a1 1 0 1 0-2 0v10a1 1 0 0 0 1 1h28a1 1 0 0 0 1-1v-27a1 1 0 0 0-1-1z"
    />
  </svg>
);

export default Return;
