import React from "react";

const Pedestal = props => (
  <svg viewBox="0 0 128 128"   {...props}>
    <path
      fill="#B9E3ED"
      d="M88.178 29.491l-4.971-8.285c-.566-.943-1.929-1.715-3.029-1.715h-31c-1.1 0-2.463.771-3.029 1.715l-4.971 8.285"
    />
    <path
      fill="#324654"
      d="M88.18 31.491c-.68 0-1.342-.346-1.717-.971l-4.971-8.285c-.205-.341-.917-.744-1.314-.744h-31c-.398 0-1.109.403-1.314.744l-4.971 8.285a2 2 0 1 1-3.43-2.059l4.971-8.285c.934-1.556 2.929-2.685 4.744-2.685h31c1.815 0 3.81 1.13 4.744 2.685l4.971 8.285a2 2 0 0 1-1.713 3.03z"
    />
    <path
      fill="#B9E3ED"
      d="M118.959 59.491l-9.753-10.489c-.749-.806-2.262-1.467-3.362-1.47l-15.667-.036a2 2 0 0 0-2 1.995v10"
    />
    <path
      fill="#324654"
      d="M118.959 61.491a1.997 1.997 0 0 1-1.465-.638l-9.752-10.489c-.374-.403-1.014-.872-1.564-.873h-16v10a2 2 0 0 1-4 0v-10c0-1.067.417-2.071 1.175-2.827a3.963 3.963 0 0 1 2.819-1.168h.011l15.666.036c1.663.005 3.691.891 4.822 2.108l9.752 10.488a2 2 0 0 1-1.464 3.363z"
    />
    <path
      fill="#B9E3ED"
      d="M10.178 73.491l7.687-11.345c.617-.91 2.022-1.655 3.122-1.655h18.191c1.1 0 2 .9 2 2v11"
    />
    <path
      fill="#324654"
      d="M10.176 75.491a2 2 0 0 1-1.654-3.122l7.687-11.345c.996-1.468 3.005-2.533 4.778-2.533h18.191c2.206 0 4 1.794 4 4v11a2 2 0 0 1-4 0v-11H20.986c-.439 0-1.22.414-1.467.777l-7.686 11.345a1.995 1.995 0 0 1-1.657.878z"
    />
    <path
      fill="#B8CBCD"
      d="M113.513 109.679c0 5.625-21.01 9.813-47.335 9.813-26.326 0-48-4.188-48-9.813 0-5.626 21.341-10.188 47.667-10.188 26.325 0 47.668 4.562 47.668 10.188z"
    />
    <path fill="#B9E3ED" d="M88.178 59.491v-30h-47v44h-31v34h109v-48z" />
    <path
      fill="#324654"
      d="M119.178 109.491h-109a2 2 0 0 1-2-2v-34a2 2 0 0 1 2-2h29v-42a2 2 0 0 1 2-2h47a2 2 0 0 1 2 2v28h29a2 2 0 0 1 2 2v48a2 2 0 0 1-2 2zm-107-4h105v-44h-29a2 2 0 0 1-2-2v-28h-43v42a2 2 0 0 1-2 2h-29v30z"
    />
    <path
      fill="#324654"
      d="M65.178 61.491a2 2 0 0 1-2-2V45.999l-1.466 1.753a1.999 1.999 0 1 1-3.068-2.566l5-5.978a1.999 1.999 0 1 1 3.534 1.283v19a2 2 0 0 1-2 2z"
    />
    <path
      fill="#324654"
      d="M61.239 61.496a2 2 0 0 1-.001-4l7.939-.005h.002a2 2 0 0 1 .001 4l-7.939.005h-.002z"
    />
    <path
      fill="#324654"
      d="M82.178 19.491l6 10v18l19.667.046 11.114 11.954h.219v48h-109v-34l8.809-13h22.191v-31l6-10h35m0-4h-35a4 4 0 0 0-3.43 1.942l-6 10a4.008 4.008 0 0 0-.57 2.058v27H18.986a4 4 0 0 0-3.311 1.756l-8.809 13a3.998 3.998 0 0 0-.689 2.244v34a4 4 0 0 0 4 4h109a4 4 0 0 0 4-4v-48a3.995 3.995 0 0 0-1.953-3.436l-10.451-11.241a4 4 0 0 0-2.92-1.276l-15.676-.036v-14.01c0-.725-.197-1.437-.57-2.058l-6-10a3.996 3.996 0 0 0-3.429-1.943z"
    />
    <path
      fill="#324654"
      d="M107.178 91.491h-10a2 2 0 0 1-2-2c0-2.759 2.458-5.077 5.061-7.532 1.878-1.771 4.215-3.975 4.215-5.374 0-1.954-1.773-2.394-2.394-2.394-1.913 0-2.883.97-2.883 2.883a2 2 0 0 1-4 0c0-4.755 3.457-6.883 6.883-6.883 3.143 0 6.394 2.392 6.394 6.394 0 3.125-2.781 5.748-5.471 8.284-.845.797-1.854 1.749-2.621 2.622h6.815a2 2 0 0 1 .001 4zm-80.83 9c-3.12 0-6.347-2.374-6.347-6.347a2 2 0 0 1 4 0c0 1.916 1.738 2.347 2.347 2.347.29 0 2.83-.079 2.83-2.83 0-.014-.044-1.381-1.623-1.381a2 2 0 0 1 0-4c1.562 0 1.623 0 1.623-1.864 0-2.118-1.641-2.347-2.347-2.347-1.878 0-2.83.952-2.83 2.83a2 2 0 0 1-4 0c0-4.718 3.43-6.83 6.83-6.83 3.154 0 6.347 2.18 6.347 6.347 0 1.765-.348 3.113-1.065 4.069a5.374 5.374 0 0 1 1.065 3.176c0 4.437-3.519 6.83-6.83 6.83z"
    />
    <path
      opacity={0.2}
      fill="#324654"
      d="M117.178 107.491h-105a3 3 0 1 1 0-6h105a3 3 0 1 1 0 6z"
    />
  </svg>
);

export default Pedestal;
