import React from "react";

const Dna = props => (
  <svg viewBox="0 0 671.431 1010"   {...props}>
    <path d="M339.885 425.157h-83.56V384.35h83.56z" fill="#43525b" />
    <path d="M415.085 425.157h-75.2V384.35h75.2z" fill="#ffab00" />
    <path d="M339.885 351.63H206.192v-40.807h133.693z" fill="#43525b" />
    <path d="M465.238 351.63H339.885v-40.807h125.353z" fill="#ffab00" />
    <path d="M339.885 278.095H156.049v-40.808h183.836z" fill="#43525b" />
    <path d="M515.351 278.095H339.885v-40.808H515.35z" fill="#ffab00" />
    <path d="M339.885 204.568H89.218V163.76h250.667z" fill="#43525b" />
    <path d="M582.183 204.568H339.885V163.76h242.298z" fill="#ffab00" />
    <path d="M339.885 131.052H55.802V90.244h284.083z" fill="#43525b" />
    <path d="M615.599 131.052H339.885V90.244h275.714z" fill="#ffab00" />
    <path d="M339.885 57.526H22.377V16.718h317.508z" fill="#43525b" />
    <path d="M649.034 57.526h-309.15V16.718h309.15z" fill="#ffab00" />
    <path d="M339.885 625.65h-83.56v-40.807h83.56z" fill="#43525b" />
    <path d="M415.085 625.65h-75.2v-40.807h75.2z" fill="#ffab00" />
    <path d="M339.885 699.197H206.192V658.39h133.693z" fill="#43525b" />
    <path d="M465.238 699.197H339.885V658.39h125.353z" fill="#ffab00" />
    <path d="M339.885 772.723H156.049v-40.808h183.836z" fill="#43525b" />
    <path d="M515.351 772.723H339.885v-40.808H515.35z" fill="#ffab00" />
    <path d="M339.885 846.25H122.634v-40.809h217.25z" fill="#43525b" />
    <path d="M548.767 846.25H339.885v-40.809h208.882z" fill="#ffab00" />
    <path d="M339.885 919.775h-233.96v-40.807h233.96z" fill="#43525b" />
    <path d="M565.465 919.775h-225.58v-40.807h225.58z" fill="#ffab00" />
    <path d="M339.885 993.303H89.218v-40.809h250.667z" fill="#43525b" />
    <path d="M582.183 993.303H339.885v-40.809h242.298z" fill="#ffab00" />
    <g fill="#43525b">
      <path d="M584.195 1010h-.02c-11.258 0-20.384-9.126-20.384-20.404.04-163.57-188.378-407.164-339.765-602.876C98.862 224.903 0 97.088 0 20.404 0 9.136 9.136 0 20.404 0s20.404 9.136 20.404 20.404c0 62.737 104.68 198.073 215.498 341.35C419.568 572.827 604.64 812.096 604.6 989.595c0 11.278-9.146 20.404-20.404 20.404z" />
      <path d="M87.195 1010c-11.268 0-20.403-9.126-20.403-20.404-.03-177.5 185.03-416.748 348.313-627.833C525.933 218.486 630.621 83.15 630.621 20.403 630.622 9.137 639.768 0 651.026 0c11.279 0 20.405 9.136 20.405 20.404 0 76.684-98.872 204.509-224.046 366.336C295.988 582.451 107.57 826.025 107.6 989.596c0 11.278-9.137 20.404-20.405 20.404z" />
    </g>
  </svg>
);

export default Dna;
