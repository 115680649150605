import React from "react";

const Certification = props => (
  <svg viewBox="0 0 48 48"   {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#FFDA8E"
      d="M45.74 37.036l-6.388-6.528a16.343 16.343 0 0 0 1.987-7.834c0-8.931-7.092-16.171-15.841-16.171-8.748 0-15.841 7.24-15.841 16.171 0 2.841.723 5.508 1.983 7.827l-6.383 6.517c-.37.377-.283 1.167.087 1.544.331.338.585.283.585.283h5.591v5.707c.022.21.122.439.28.601.369.377 1.114.437 1.483.06l7.099-7.246c1.606.561 3.322.879 5.115.879 1.787 0 3.499-.316 5.102-.873l7.112 7.27c.369.378 1.114.318 1.483-.06a1.05 1.05 0 0 0 .28-.602v-5.714h5.591s.254.055.585-.283c.371-.379.459-1.17.09-1.548zM31.635 24.099c2.297 7.86.064 9.449-6.696 4.769-6.818 4.598-9.029 2.981-6.635-4.85-6.512-5.019-5.646-7.606 2.594-7.766 2.794-7.699 5.541-7.683 8.239.051 8.237.26 9.07 2.858 2.498 7.796z"
    />
    <path
      fill="#384D68"
      d="M45.721 34.603l-6.854-6.863A16.911 16.911 0 0 0 41 19.504c0-9.389-7.611-17-17-17s-17 7.611-17 17a16.9 16.9 0 0 0 2.129 8.229l-6.851 6.85c-.396.396-.303 1.228.094 1.624.354.354.628.297.628.297h6v6c.024.221.131.462.301.632.396.396 1.195.459 1.592.063l7.617-7.618c1.725.589 3.566.924 5.49.924 1.918 0 3.755-.332 5.475-.918l7.633 7.643c.396.397 1.195.335 1.592-.063.17-.17.276-.411.301-.632v-6.008h6s.272.058.628-.298c.394-.398.488-1.23.092-1.626zM10.95 40.268v-4.86a1 1 0 0 0-.276-.607c-.335-.335-.606-.291-.606-.291l-4.861.016 5.047-5.047a17.06 17.06 0 0 0 6.23 5.257l-5.534 5.532zm13.049-5.765c-8.284 0-15-6.716-15-15s6.716-15 15-15 15 6.716 15 15-6.716 15-15 15zm13.931.026s-.271-.044-.605.291a.992.992 0 0 0-.275.608v4.866l-5.548-5.555a17.042 17.042 0 0 0 6.237-5.255l5.054 5.06-4.863-.015zm-9.793-20.226c-2.698-7.733-5.445-7.75-8.239-.051-8.24.159-9.105 2.747-2.594 7.766-2.395 7.831-.184 9.447 6.635 4.85 6.761 4.681 8.993 3.091 6.696-4.769 6.572-4.938 5.739-7.536-2.498-7.796zm.068 6.626c2.554 7.324 1.823 7.838-4.254 2.945-6.021 4.966-6.676 4.404-4.217-2.995-6.106-3.694-5.994-4.797 1.648-4.797 2.119-7.038 3.114-7.059 5.235.03 7.58 0 7.859 1.048 1.588 4.817z"
    />
  </svg>
);

export default Certification;
