import React from "react";

const MoonPhases = props => (
  <svg viewBox="0 0 64 64"   {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M32 0c-3.85 0-7 3.15-7 7s3.15 7 7 7 7-3.15 7-7-3.15-7-7-7m0 1c3.308 0 6 2.692 6 6s-2.692 6-6 6-6-2.692-6-6 2.692-6 6-6"
        fill="#f2b430"
      />
      <path
        d="M32 0c3.85 0 7 3.15 7 7s-3.15 7-7 7V0zM7 25c-3.85 0-7 3.15-7 7s3.15 7 7 7 7-3.15 7-7-3.15-7-7-7m0 1c3.308 0 6 2.692 6 6s-2.692 6-6 6-6-2.692-6-6 2.692-6 6-6"
        fill="#f2b430"
      />
      <path
        d="M57 39c-3.85 0-7-3.15-7-7s3.15-7 7-7 7 3.15 7 7-3.15 7-7 7m-7 18c-3.85 0-7-3.15-7-7s3.15-7 7-7 7 3.15 7 7-3.15 7-7 7zm4-7c0-2.959-2.173-5.429-5-5.909A5.893 5.893 0 0 1 50 44c3.3 0 6 2.7 6 6s-2.7 6-6 6c-.341 0-.674-.035-1-.091 2.827-.48 5-2.95 5-5.909z"
        fill="#1d7ed0"
      />
      <path
        d="M14 43c-3.85 0-7 3.15-7 7s3.15 7 7 7 7-3.15 7-7-3.15-7-7-7m0 1c3.308 0 6 2.692 6 6s-2.692 6-6 6-6-2.692-6-6 2.692-6 6-6"
        fill="#f2b430"
      />
      <path
        d="M10 50c0-2.959 2.173-5.429 5-5.909A5.893 5.893 0 0 0 14 44c-3.3 0-6 2.7-6 6s2.7 6 6 6c.341 0 .674-.035 1-.091-2.827-.48-5-2.95-5-5.909m4-43c-3.85 0-7 3.15-7 7s3.15 7 7 7 7-3.15 7-7-3.15-7-7-7m0 1c3.308 0 6 2.692 6 6s-2.692 6-6 6-6-2.692-6-6 2.692-6 6-6"
        fill="#f2b430"
      />
      <path
        d="M50 21c-3.85 0-7-3.15-7-7s3.15-7 7-7 7 3.15 7 7-3.15 7-7 7zm-4-7c0-2.959 2.173-5.429 5-5.909A5.893 5.893 0 0 0 50 8c-3.3 0-6 2.7-6 6s2.7 6 6 6c.341 0 .674-.035 1-.091-2.827-.48-5-2.95-5-5.909zM32 50c-3.85 0-7 3.15-7 7s3.15 7 7 7 7-3.15 7-7-3.15-7-7-7m0 1c3.308 0 6 2.692 6 6s-2.692 6-6 6-6-2.692-6-6 2.692-6 6-6"
        fill="#1d7ed0"
      />
      <path d="M32 50c-3.85 0-7 3.15-7 7s3.15 7 7 7V50z" fill="#1d7ed0" />
      <path
        d="M18 14c0-2.959-2.173-5.429-5-5.909A5.893 5.893 0 0 1 14 8c3.3 0 6 2.7 6 6s-2.7 6-6 6c-.341 0-.674-.035-1-.091 2.827-.48 5-2.95 5-5.909"
        fill="#f2b430"
      />
    </g>
  </svg>
);

export default MoonPhases;
