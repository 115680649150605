import React from "react";

const GiftPackaging = props => (
  <svg viewBox="0 0 48 48"   {...props}>
    <path
      fill="#F16362"
      d="M44 5H7a1 1 0 0 0-1 1v38a1 1 0 0 0 1 1h37a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1z"
    />
    <path
      fill="#384D68"
      d="M45 2H3a1 1 0 0 0-1 1v42a1 1 0 0 0 1 1h42a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1zM4 4h18a1 1 0 0 1 1 1v11c-.303-.585-.69-1.003-1.193-1.502a5.181 5.181 0 0 0-7.298 0 5.11 5.11 0 0 0 0 7.257c.52.517.936.926 1.491 1.245H5a1 1 0 0 1-1-1V4zm19 39a1 1 0 0 1-1 1H4V26a1 1 0 0 1 1-1h14.813c-.213.154-.427.307-.62.498C17.429 27.252 17.037 33.52 17 35a1 1 0 1 0 2 0s.025-5.404 1.596-7.995c.487-.802 1.545-2.042 2.404-2.005v18zm0-20c-.128.127-4.381-.046-6.976-2.641-1.237-1.236-1.265-3.207-.056-4.41 1.209-1.202 3.199-1.181 4.436.056C22.999 18.601 23.029 22.971 23 23zm21 21H26a1 1 0 0 1-1-1V25c.859-.037 1.917 1.203 2.404 2.005C28.975 29.596 29 35 29 35a1 1 0 1 0 2 0c-.037-1.48-.429-7.748-2.193-9.502-.192-.191-.407-.344-.619-.498H43a1 1 0 0 1 1 1v18zM27.586 16.014c1.236-1.236 3.217-1.262 4.423-.056s1.181 3.186-.056 4.423C29.354 22.979 25.127 23.127 25 23c-.029-.029.022-4.423 2.586-6.986zM44 22a1 1 0 0 1-1 1H32a9.52 9.52 0 0 0 1.465-1.22 5.146 5.146 0 1 0-7.278-7.278c-.499.499-.816.879-1.187 1.498V5a1 1 0 0 1 1-1h18v18z"
    />
  </svg>
);

export default GiftPackaging;
