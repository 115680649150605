import React from "react";

const AirportDrive = props => (
  <svg viewBox="0 0 32 32"   {...props}>
    <path
      d="M30 0H2a2 2 0 0 0-2 2v28a2 2 0 0 0 2 2h28a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z"
      fill="#f1f1f1"
    />
    <path
      d="M18.07 10.987c-.556.019-1.229.318-1.628.722-.358.357-.671.929-.587 1.477.62.042 1.253-.271 1.64-.675.385-.405.646-.965.575-1.524zm.608 5.385c-.012-1.254 1.015-1.855 1.061-1.885-.576-.852-1.476-.968-1.796-.98-.765-.079-1.492.453-1.88.453s-.986-.443-1.621-.432c-.834.014-1.603.488-2.031 1.242-.866 1.515-.222 3.759.622 4.987.413.602.905 1.276 1.55 1.252.623-.024.858-.405 1.61-.405.751 0 .963.405 1.62.393.67-.012 1.094-.612 1.504-1.216.473-.697.668-1.373.68-1.407-.016-.008-1.306-.505-1.319-2.002z"
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#4d4d4d"
    />
  </svg>
);

export default AirportDrive;
