import React from "react";

const Helmet = props => (
  <svg viewBox="0 0 128 128"   {...props}>
    <path
      fill="#B8CBCD"
      d="M117.051 110.521c0 5.625-21.675 9.813-48 9.813-26.326 0-47.335-4.188-47.335-9.813 0-5.626 21.341-10.188 47.667-10.188 26.325.001 47.668 4.562 47.668 10.188z"
    />
    <path
      fill="#FFF"
      d="M59.52 112.642s34.781 6.067 34.781-34.933c0-12.881 6.616-19.499 14.594-22.525 0 0 0-10.267-4-14.85 0 0-17.375.625-28.875 22.125s-16.5 50.183-16.5 50.183z"
    />
    <path
      fill="#324654"
      d="M65.023 114.921c-2.683 0-4.457-.271-4.664-.305a2 2 0 0 1 .633-3.95c.182.027 13.597 2.016 23.145-6.143 6.579-5.622 9.915-14.771 9.915-27.19 0-11.548 4.695-19.44 13.96-23.479-.177-3.305-.989-9.367-3.467-12.207a2 2 0 1 1 3.013-2.63c4.442 5.089 4.493 15.715 4.493 16.165a2 2 0 0 1-1.291 1.87c-8.552 3.245-12.709 9.878-12.709 20.28 0 13.663-3.818 23.843-11.349 30.258-7.328 6.245-16.316 7.331-21.679 7.331z"
    />
    <path
      fill="#B9E3ED"
      d="M79.051 71.334c0-18.094 20.872-23.089 29.844-24.471-6.326-21.127-25.907-36.529-49.094-36.529-28.305 0-51.25 22.945-51.25 51.25s22.945 51.25 51.25 51.25c3.464 0 2.604.489 5.875-.167 0 0 13.375-.333 13.375-41.333z"
    />
    <path
      fill="#324654"
      d="M63.188 115.009c-.366 0-.685-.028-1.081-.066-.499-.048-1.12-.109-2.306-.109-29.362 0-53.25-23.888-53.25-53.25s23.888-53.25 53.25-53.25c23.342 0 44.318 15.608 51.01 37.956a2 2 0 0 1-1.612 2.55c-10.513 1.619-28.148 6.695-28.148 22.494 0 40.296-12.817 43.138-15.119 43.322-1.3.257-2.053.353-2.744.353zM59.801 12.334c-27.156 0-49.25 22.093-49.25 49.25s22.094 49.25 49.25 49.25c1.38 0 2.139.074 2.692.127.254.025.459.048.694.048.304 0 .768-.037 2.096-.303a1.97 1.97 0 0 1 .343-.039c.05-.008 11.425-1.512 11.425-39.334 0-18.036 18.895-24.091 29.227-26.05-6.853-19.51-25.646-32.949-46.477-32.949z"
    />
    <path
      fill="#FFF"
      d="M103.925 50.126a3.002 3.002 0 0 1-2.873-2.141c-6.106-20.393-26.013-35.193-47.334-35.193a3 3 0 1 1 0-6c23.909 0 46.233 16.601 53.082 39.473a3 3 0 0 1-2.875 3.861z"
    />
    <path
      fill="#B9E3ED"
      d="M7.968 57.251c0 28.305 30.154 55.588 58.458 55.588"
    />
    <path
      opacity={0.2}
      fill="#324654"
      d="M66.426 115.839c-14.399 0-29.669-6.557-41.893-17.989C12.1 86.221 4.968 71.423 4.968 57.25a3 3 0 1 1 6 0c0 26.048 27.988 52.588 55.458 52.588a3 3 0 1 1 0 6.001z"
    />
    <circle fill="#B9E3ED" cx={52.551} cy={76.834} r={10.5} />
    <path
      fill="#FFF"
      d="M62.051 81.834a2 2 0 0 1-2-2c0-5.005-3.084-8.5-7.5-8.5s-7.5 3.495-7.5 8.5a2 2 0 0 1-4 0c0-7.243 4.837-12.5 11.5-12.5s11.5 5.257 11.5 12.5a2 2 0 0 1-2 2z"
    />
    <path
      fill="#324654"
      d="M52.551 89.334c-6.893 0-12.5-5.607-12.5-12.5s5.607-12.5 12.5-12.5 12.5 5.607 12.5 12.5-5.607 12.5-12.5 12.5zm0-21c-4.687 0-8.5 3.813-8.5 8.5 0 4.687 3.813 8.5 8.5 8.5 4.686 0 8.5-3.813 8.5-8.5 0-4.687-3.814-8.5-8.5-8.5z"
    />
    <path
      fill="#B9E3ED"
      d="M121.051 94.334l-22.375-3.313c-.045.495-.076.993-.076 1.5 0 9.112 7.426 19.125 16.538 19.125.507 0 5.418.656 5.913.611V94.334z"
    />
    <path
      fill="#324654"
      d="M120.987 114.26c-.48 0-1.539-.119-3.673-.37-.839-.099-1.987-.234-2.22-.245-10.566.001-18.494-11.151-18.494-21.124 0-.512.026-1.046.084-1.681a1.999 1.999 0 0 1 2.285-1.798l22.375 3.313c.98.146 1.707.987 1.707 1.979v17.924a2 2 0 0 1-1.819 1.992 3.98 3.98 0 0 1-.245.01zm-20.364-20.928c.428 7.583 6.74 16.315 14.515 16.315.297 0 .8.055 2.645.271.367.043.819.097 1.269.148V96.06l-18.429-2.728zm-38.892 21.507h-.001l-1.929-.005c-29.362 0-53.25-23.888-53.25-53.25s23.888-53.25 53.25-53.25c23.342 0 44.318 15.608 51.01 37.956a2 2 0 0 1-1.612 2.55c-10.513 1.619-28.148 6.695-28.148 22.494 0 40.7-13.084 43.023-15.21 43.131l-.896.116c-1.632.213-1.972.258-3.214.258zm-1.93-102.505c-27.156 0-49.25 22.093-49.25 49.25s22.094 49.25 49.25 49.25l1.929.005h.002c.98 0 1.11-.017 2.695-.224l.994-.129c.077-.01.156-.015.234-.016.05-.007 11.396-1.344 11.396-39.135 0-18.036 18.895-24.091 29.227-26.05-6.853-19.512-25.646-32.951-46.477-32.951z"
    />
    <path fill="#B9E3ED" d="M121.051 94.334l-50-7v-15l50 11z" />
    <path
      fill="#324654"
      d="M121.051 96.334c-.092 0-.185-.006-.277-.02l-50-7a2 2 0 0 1-1.723-1.98v-15a1.999 1.999 0 0 1 2.43-1.954l50 11a2 2 0 0 1 1.57 1.953v11a2 2 0 0 1-2 2.001zm-48-10.74l46 6.44v-7.093l-46-10.12v10.773z"
    />
    <path
      fill="#324654"
      d="M111.051 112.334a2 2 0 0 1-2-2v-29a2 2 0 0 1 4 0v29a2 2 0 0 1-2 2z"
    />
    <path
      fill="#324654"
      d="M59.801 10.334c21.24 0 39.446 12.929 47.218 31.339 3.03 4.98 3.032 13.511 3.032 13.511-7.977 3.026-14 9.27-14 22.15 0 .169-.006.331-.007.498l25.007 5.502v28.924c-.018.002-.041.002-.069.002-.769 0-5.357-.571-5.844-.614-9.134-.796-16.538-10.012-16.538-19.125 0-.446.03-.885.067-1.321l-4.136-.579c-5.09 19.829-21.652 22.288-29.566 22.288-.252 0-.497-.003-.731-.007a31.464 31.464 0 0 1-3.216-.091c-.329.015-.721.022-1.218.022-28.305 0-51.25-22.945-51.25-51.25s22.946-51.249 51.251-51.249m0-4c-30.465 0-55.25 24.785-55.25 55.25s24.785 55.25 55.25 55.25c.447 0 .819-.006 1.141-.017a35.766 35.766 0 0 0 3.295.085c.234.004.478.006.728.006 7.094 0 22.909-1.889 30.668-17.682 2.704 8.688 10.385 15.639 19.157 16.404.143.013.568.064 1.12.128 3.328.388 4.465.501 5.072.501.177 0 .321-.009.431-.019a4 4 0 0 0 3.638-3.984V83.334a4 4 0 0 0-3.141-3.907l-21.756-4.787c.641-8.021 4.282-13.048 11.316-15.717a4.001 4.001 0 0 0 2.581-3.741c0-.973-.1-9.549-3.456-15.321-8.721-20.377-28.627-33.527-50.794-33.527z"
    />
  </svg>
);

export default Helmet;
