import React from "react";

const EnergySavingLightbulb = props => (
  <svg viewBox="0 0 32 32"   {...props}>
    <circle fill="#FFD878" cx={15.5} cy={14.5} r={13.5} />
    <path fill="#FFF" d="M10.5 15.5v3l2 2v3h6v-3z" />
    <path
      fill="#FFF"
      d="M24.14 16.07c-.37.64-.979 1.01-1.67 1.01-.54 0-.95-.21-1.06-.28L10 10.21c-.1-.05-.44-.27-.7-.67-.41-.62-.42-1.37-.03-2.06.27-.47.88-1.02 1.71-1.02.35 0 .69.1 1.02.29l11.41 6.59c.84.48 1.38 1.6.73 2.73zm-1.87 4.64c-.369.64-.97 1-1.659 1-.54 0-.95-.21-1.061-.27L8.14 14.86c-.1-.06-.44-.28-.71-.68-.41-.62-.42-1.37-.02-2.06.27-.47.88-1.02 1.7-1.02.35 0 .69.1 1.03.29l11.41 6.59c.84.48 1.37 1.6.72 2.73zM13.61 6.32c-.41-.63-.42-1.38-.03-2.06.27-.47.89-1.03 1.71-1.03.35 0 .69.1 1.03.29l6.5 3.76c.1.06.439.28.71.67.41.63.42 1.38.029 2.06-.369.65-.989 1.01-1.68 1.01-.54 0-.939-.21-1.06-.27l-6.5-3.76a2.31 2.31 0 0 1-.709-.67z"
    />
    <path fill="#BFBFBF" d="M10 23h11v5H10z" />
    <path
      fill="none"
      stroke="#4D5152"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      d="M12.5 23.5v-3l-2-2V16"
    />
    <path
      fill="none"
      stroke="#4D5152"
      strokeLinecap="round"
      strokeMiterlimit={10}
      d="M18.5 20.5v3"
    />
    <path fill="#AAA" d="M12 27v2s0 2 2 2h3s2 0 2-2v-2h-7z" />
    <path fill="#4D5152" d="M20 24v3h-9v-3h9m1-1H10v5h11v-5z" />
    <path
      fill="#4D5152"
      d="M18 28v1c0 .806-.55.988-1 1h-3c-.805 0-.988-.55-1-1v-1h5m1-1h-7v2s0 2 2 2h3s2 0 2-2v-2zM9.116 12.101c.156 0 .332.043.525.155l11.406 6.587s.867.5.367 1.366c-.232.401-.543.509-.81.509-.309 0-.558-.144-.558-.144L8.641 13.988s-.866-.5-.366-1.366c0 0 .301-.521.841-.521m0-1.001c-.825 0-1.435.551-1.707 1.022-.395.685-.385 1.435.027 2.059.264.399.606.616.706.673l11.405 6.586c.113.065.523.277 1.058.277.694 0 1.305-.367 1.676-1.009.396-.685.385-1.436-.028-2.06a2.224 2.224 0 0 0-.705-.673L10.141 11.39a2.037 2.037 0 0 0-1.025-.29zM10.978 7.461c.156 0 .332.043.525.155l11.407 6.586s.865.5.365 1.366c-.232.402-.543.509-.809.509-.308 0-.557-.144-.557-.144L10.503 9.348s-.866-.5-.366-1.366c0 0 .301-.521.841-.521m0-1.001c-.825 0-1.435.551-1.707 1.022-.395.685-.385 1.435.027 2.059.264.399.606.616.705.673L21.41 16.8c.112.064.521.277 1.057.277.693 0 1.304-.368 1.675-1.009.649-1.125.108-2.247-.73-2.732L12.003 6.75a2.034 2.034 0 0 0-1.025-.29zM15.29 4.235c.156 0 .332.043.526.155l6.506 3.758s.867.5.367 1.366c-.232.402-.543.51-.81.51-.309 0-.558-.144-.558-.144l-6.506-3.758s-.867-.5-.367-1.366c.001 0 .302-.521.842-.521m0-1.001c-.825 0-1.436.551-1.708 1.022-.395.684-.385 1.435.028 2.06.264.399.607.616.706.672l6.506 3.758c.113.065.522.278 1.058.278.694 0 1.306-.368 1.676-1.01.396-.684.385-1.435-.028-2.06a2.204 2.204 0 0 0-.705-.672l-6.506-3.758a2.047 2.047 0 0 0-1.027-.29z"
    />
  </svg>
);

export default EnergySavingLightbulb;
