import React from "react";

const Package = props => (
  <svg viewBox="0 0 48 48"   {...props}>
    <path
      fill="#FFDA8E"
      d="M23 8a1 1 0 0 0 1-1V3a1 1 0 1 0-2 0v4a1 1 0 0 0 1 1zm5.762.692L31.7 5.754a1.04 1.04 0 0 0-1.469-1.47l-2.938 2.938a1.04 1.04 0 0 0 1.469 1.47zm-11.524 0a1.04 1.04 0 0 0 1.469-1.47l-2.938-2.938a1.041 1.041 0 0 0-1.47 0 1.041 1.041 0 0 0 0 1.47l2.939 2.938zM42 22h-9.382l-3.724-7.447-.009.005A.99.99 0 0 0 28 14H10a1 1 0 0 0-1 1c0 .161.047.308.115.442l-.009.005L11 19.236V45a1 1 0 0 0 1 1h30a1 1 0 0 0 1-1V23c0-1.057-1-1-1-1z"
    />
    <path
      fill="#384D68"
      d="M46 11a1 1 0 0 0-1-1H35a1 1 0 1 0 0 2h8.382l-4 8h-6.764l-4.724-9.447-.009.005A.99.99 0 0 0 27 10H3a1 1 0 0 0-1 1c0 .161.047.308.115.442l-.009.005L7 21.236V43a1 1 0 0 0 1 1h32a1 1 0 0 0 1-1V21.236l4.895-9.789-.009-.005A.983.983 0 0 0 46 11zM4.618 12h21.764l4 8H8.618l-4-8zM31 41a1 1 0 0 1-1 1H9V22h21a1 1 0 0 1 1 1v18zm8 1h-5a1 1 0 0 1-1-1V23a1 1 0 0 1 1-1h5v20z"
    />
  </svg>
);

export default Package;
