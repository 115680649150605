import React from "react";

const PingPongRacket = props => (
  <svg viewBox="0 0 128 128"   {...props}>
    <path
      fill="#B8CBCD"
      d="M112.386 110.647c0 5.626-21.343 10.187-47.668 10.187-26.326 0-47.667-4.561-47.667-10.187 0-5.626 21.674-9.813 48-9.813 26.325 0 47.335 4.187 47.335 9.813z"
    />
    <path
      fill="#B9E3ED"
      d="M32.806 73.964l-16.88 25.12c-2.074 3.36-.235 8.676 3.125 10.75s9.801 3.048 11.875-.313L45.9 82.113l10.235 1.144c12.121-.641 24.558-8.02 32.265-20.505 11.557-18.722 8.104-41.813-7.712-51.577s-38.007-2.502-49.564 16.22c-6.783 10.988-8.389 23.479-5.365 33.9l-.008-.005.029.081 7.026 12.593z"
    />
    <path
      fill="#324654"
      d="M25.88 114.723c-2.85 0-6.07-.896-8.404-2.336-4.449-2.746-7.21-9.844-4.102-14.878a2.69 2.69 0 0 1 .063-.098L29.29 73.819l-6.13-10.987a3.012 3.012 0 0 1-.282-.703c-3.397-11.71-1.322-24.946 5.694-36.311 8.371-13.561 22.37-21.985 36.533-21.985 6.152 0 12.086 1.656 17.16 4.788 17.199 10.617 21.097 35.606 8.689 55.706-7.909 12.812-21.19 21.213-34.66 21.925-.165.01-.329.004-.492-.014l-8.235-.92-14.008 25.642a2.711 2.711 0 0 1-.08.137c-1.444 2.339-4.142 3.626-7.599 3.626zm-7.425-14.024c-.484.82-.536 2.069-.139 3.356.424 1.372 1.309 2.607 2.31 3.226 1.397.862 3.508 1.442 5.253 1.442h.001c1.206 0 2.142-.281 2.466-.736l14.921-27.313a2.998 2.998 0 0 1 2.966-1.543l9.984 1.116c11.453-.699 22.788-7.987 29.63-19.071 10.669-17.284 7.648-38.569-6.735-47.449-4.186-2.583-8.898-3.894-14.008-3.894-12.098 0-24.141 7.332-31.427 19.136-6.078 9.846-7.933 21.218-5.107 31.244l6.856 12.288c.55.986.499 2.197-.13 3.135l-16.841 25.063z"
    />
    <path
      fill="#B9E3ED"
      d="M32.806 73.964l-16.88 25.12c-2.074 3.36-.235 8.676 3.125 10.75s9.801 3.048 11.875-.313L45.9 82.113l10.235 1.144c12.121-.641 24.558-8.02 32.265-20.505 11.557-18.722 8.104-41.813-7.712-51.577s-38.007-2.502-49.564 16.22c-6.783 10.988-8.389 23.479-5.365 33.9l-.008-.005.029.081"
    />
    <path
      fill="#324654"
      d="M25.885 114.723c-.172 0-.346-.003-.523-.009-2.738-.099-5.687-.969-7.887-2.327-4.449-2.746-7.21-9.844-4.102-14.878a2.69 2.69 0 0 1 .063-.098l16.88-25.12a3.001 3.001 0 0 1 4.981 3.347L18.456 100.7c-.483.82-.536 2.07-.138 3.356.424 1.372 1.309 2.607 2.31 3.226 1.315.812 3.259 1.375 4.952 1.437 1.346.046 2.416-.238 2.767-.732l14.921-27.312a2.998 2.998 0 0 1 2.966-1.543l9.984 1.116c11.453-.699 22.788-7.987 29.63-19.071 10.669-17.284 7.648-38.569-6.735-47.449-6.886-4.251-15.362-5.06-23.871-2.274-8.688 2.843-16.347 9.064-21.565 17.516-6.127 9.926-7.963 21.403-5.037 31.489a2.994 2.994 0 0 1-.001 1.676 2.986 2.986 0 0 1-1.863 2.02c-1.559.56-3.263-.215-3.82-1.774a2.903 2.903 0 0 1-.078-.251c-3.397-11.711-1.322-24.945 5.694-36.311 5.961-9.657 14.771-16.784 24.805-20.067 10.214-3.345 20.473-2.324 28.887 2.87 17.199 10.617 21.097 35.606 8.689 55.706-7.908 12.811-21.189 21.212-34.659 21.924-.165.01-.329.004-.492-.014l-8.235-.92-14.008 25.642a2.711 2.711 0 0 1-.08.137c-1.45 2.349-4.134 3.626-7.594 3.626z"
    />
    <path
      fill="#FFF"
      d="M26.776 59.176a3 3 0 0 1-3-3c0-23.666 10.663-39.399 30.836-45.499 14.457-4.373 25.184-2.617 35.868 5.87a3 3 0 0 1-3.732 4.698c-9.077-7.211-17.884-8.611-30.399-4.825-21.962 6.641-26.572 25.285-26.572 39.756a3.001 3.001 0 0 1-3.001 3z"
    />
    <path
      fill="#324654"
      d="M25.88 114.723c-2.85 0-6.07-.896-8.404-2.336-4.449-2.746-7.21-9.844-4.102-14.878a2.69 2.69 0 0 1 .063-.098l15.687-23.344c-4.314-6.005-6.145-11.6-6.228-11.857a2.807 2.807 0 0 1-.075-.279c-3.317-11.662-1.226-24.811 5.751-36.112 8.371-13.561 22.37-21.985 36.533-21.985 6.152 0 12.086 1.656 17.16 4.788 17.199 10.617 21.097 35.606 8.689 55.706-7.909 12.811-21.19 21.212-34.66 21.924-.165.01-.329.004-.492-.014l-8.235-.92-14.008 25.642a2.711 2.711 0 0 1-.08.137c-1.444 2.339-4.142 3.626-7.599 3.626zm-7.425-14.024c-.484.82-.536 2.069-.139 3.356.424 1.372 1.309 2.607 2.31 3.226 1.397.862 3.508 1.442 5.253 1.442h.001c1.206 0 2.142-.281 2.466-.736l14.921-27.313a2.998 2.998 0 0 1 2.966-1.543l9.984 1.116c11.453-.699 22.788-7.987 29.63-19.071 10.669-17.284 7.648-38.569-6.735-47.449-4.186-2.583-8.898-3.894-14.008-3.894-12.098 0-24.141 7.332-31.427 19.136-6.127 9.926-7.963 21.403-5.037 31.489l.014.05c.324.928 2.324 6.342 6.499 11.587a3.002 3.002 0 0 1 .143 3.542l-16.841 25.062z"
    />
    <path
      fill="#324654"
      d="M66.716 82.578c-.358 0-.721-.097-1.049-.299l-41.032-25.33a1.999 1.999 0 1 1 2.101-3.403l41.032 25.33a2 2 0 0 1-1.052 3.702z"
    />
    <path
      fill="#FFBAB0"
      d="M44.05 78.832L16.697 95.12c-3.224 2.281-2.217 9.245.064 12.468 2.281 3.224 8.514 6.489 11.737 4.207l24.415-20.343 8.973 5.056c11.406 4.151 25.738 2.225 37.715-6.251 17.959-12.71 23.813-35.312 13.076-50.484-10.737-15.172-34-17.169-51.959-4.459-10.541 7.46-16.904 18.328-18.198 29.102l-.005-.008-.005.085"
    />
    <path
      fill="#783741"
      d="M25.844 115.561c-1.465 0-3.059-.344-4.72-1.035-2.734-1.137-5.345-3.131-6.812-5.205-1.467-2.073-2.48-5.198-2.643-8.155-.21-3.825.959-6.842 3.295-8.495.064-.045.13-.088.198-.129l27.353-16.288a2.997 2.997 0 0 1 4.113 1.043 2.999 2.999 0 0 1-1.043 4.112L18.373 97.614c-.432.373-.809 1.488-.713 3.222.102 1.835.724 3.853 1.55 5.02.826 1.167 2.521 2.425 4.218 3.131 1.604.668 2.781.684 3.276.4L50.993 89.15a3 3 0 0 1 3.393-.309l8.753 4.932c10.811 3.837 24.096 1.563 34.729-5.962 8.108-5.738 13.836-13.772 16.128-22.622 2.245-8.666.907-17.076-3.768-23.681-4.674-6.605-12.161-10.664-21.08-11.429-9.105-.784-18.59 1.948-26.698 7.686-9.521 6.738-15.7 16.583-16.952 27.01a3.005 3.005 0 0 1-.672 1.562 2.974 2.974 0 0 1-2.486 1.111c-1.654-.095-2.92-1.471-2.825-3.125.005-.089.014-.178.027-.266 1.455-12.106 8.541-23.474 19.443-31.189 9.263-6.556 20.155-9.671 30.677-8.766 10.709.919 19.752 5.87 25.464 13.941s7.374 18.246 4.679 28.651c-2.647 10.221-9.207 19.459-18.471 26.015-12.289 8.698-27.797 11.237-40.474 6.621a3.058 3.058 0 0 1-.447-.205l-7.17-4.04-22.825 19.018a2.6 2.6 0 0 1-.188.144c-1.238.875-2.73 1.314-4.386 1.314z"
    />
    <path
      fill="#FFBAB0"
      d="M44.05 78.832L16.697 95.12c-3.224 2.281-2.217 9.245.064 12.468 2.281 3.224 8.514 6.489 11.737 4.207l24.415-20.343 8.973 5.056c11.406 4.151 25.738 2.225 37.715-6.251 17.959-12.71 23.813-35.312 13.076-50.484-10.737-15.172-34-17.169-51.959-4.459-10.541 7.46-16.904 18.328-18.198 29.102l-.005-.008-.005.085 1.54 14.339z"
    />
    <path
      fill="#FFF"
      d="M45.77 62.279a3 3 0 0 1-2.82-4.025c4.671-12.865 16.152-23.006 30.71-27.127 14.711-4.164 29.949-1.531 40.755 7.044a3 3 0 1 1-3.73 4.7c-9.299-7.379-22.528-9.612-35.392-5.971-12.708 3.597-22.69 12.345-26.705 23.402a2.998 2.998 0 0 1-2.818 1.977z"
    />
    <path
      fill="#783741"
      d="M25.843 115.558c-4.456 0-9.362-3.171-11.531-6.237-1.467-2.073-2.48-5.198-2.643-8.155-.21-3.825.959-6.842 3.295-8.495.064-.045.13-.088.198-.129l25.52-15.197c-1.663-8.601-1.206-12.833-1.144-13.319.007-.06.017-.119.028-.177 1.51-12.029 8.578-23.313 19.418-30.984 8.125-5.75 17.761-8.917 27.133-8.917 12.097 0 22.67 5.136 29.008 14.092 5.712 8.071 7.374 18.246 4.679 28.651-2.647 10.221-9.207 19.459-18.471 26.015-8.119 5.746-17.749 8.911-27.117 8.911-4.689 0-9.183-.771-13.356-2.29a3.058 3.058 0 0 1-.447-.205l-7.17-4.04L30.418 114.1a2.6 2.6 0 0 1-.188.144c-1.231.872-2.707 1.314-4.387 1.314zm-7.47-17.944c-.432.373-.809 1.488-.713 3.222.102 1.835.724 3.853 1.55 5.02 1.211 1.712 4.373 3.702 6.633 3.703.262 0 .614-.031.86-.172l24.289-20.238a3 3 0 0 1 3.393-.309l8.753 4.932c3.45 1.225 7.176 1.846 11.078 1.846 8.137 0 16.537-2.773 23.651-7.808 8.108-5.738 13.836-13.772 16.128-22.622 2.245-8.666.907-17.076-3.768-23.681-5.198-7.345-13.986-11.558-24.111-11.558-8.141 0-16.546 2.775-23.667 7.815-9.521 6.738-15.7 16.583-16.952 27.01a2.587 2.587 0 0 1-.018.127c-.056.731-.279 5.078 1.502 13.296a3.001 3.001 0 0 1-1.397 3.213L18.373 97.614z"
    />
    <path
      fill="#783741"
      d="M72.614 100.096a1.996 1.996 0 0 1-1.634-.845L43.246 60.063a2 2 0 1 1 3.265-2.311L74.244 96.94a2 2 0 0 1-1.63 3.156z"
    />
    <circle fill="#FFF" cx={80.551} cy={55.334} r={9.5} />
    <path
      fill="#783741"
      d="M80.551 66.834c-6.341 0-11.5-5.159-11.5-11.5s5.159-11.5 11.5-11.5 11.5 5.159 11.5 11.5-5.159 11.5-11.5 11.5zm0-19c-4.136 0-7.5 3.364-7.5 7.5s3.364 7.5 7.5 7.5 7.5-3.364 7.5-7.5-3.364-7.5-7.5-7.5z"
    />
  </svg>
);

export default PingPongRacket;
