import React from "react";

const EolicEnergy = props => (
  <svg viewBox="0 0 32 32"   {...props}>
    <path
      fill="#67DDE0"
      d="M24.5 15.5c0-.28.22-.5.5-.5h5c0-7.73-6.27-14-14-14-4.75 0-8.96 2.37-11.48 6h5.98c.23-.01.5-.1.5-.5s-.27-.49-.51-.5a.508.508 0 0 1-.49-.51c.01-.27.23-.49.5-.49.6 0 1.5.4 1.5 1.5S11.1 8 10.5 8H3.88C3 9.51 2.4 11.2 2.14 13H8c.28 0 .5.22.5.5s-.22.5-.5.5H2.04c-.03.33-.04.66-.04 1 0 3.92 1.61 7.46 4.21 10H11c.28 0 .5.22.5.5s-.22.5-.5.5H7.35c2.39 1.88 5.39 3 8.65 3 4.3 0 8.15-1.94 10.71-5H21c-.28 0-.5-.22-.5-.5s.22-.5.5-.5h6.48c1.409-2.01 2.3-4.41 2.479-7H25c-.28 0-.5-.22-.5-.5zm-7-4.5c-.28 0-.5-.22-.5-.5s.22-.5.5-.5h7c.22-.01.5-.1.5-.5s-.28-.49-.51-.5a.508.508 0 0 1-.49-.51c.01-.27.23-.49.5-.49.6 0 1.5.4 1.5 1.5s-.9 1.5-1.5 1.5h-7z"
    />
    <path fill="#E88C77" d="M14.5 17.5l-1 14h5l-1-14" />
    <path fill="#4D5152" d="M14 29h4v1h-4z" />
    <path
      fill="none"
      stroke="#4D5152"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      d="M14.5 17.5l-1 14h5l-1-14"
    />
    <path
      fill="#BFBFBF"
      d="M16 15s-.227 3.599-3.688 5.604c-3.461 2.006-6.695.414-6.695.414s.227-3.6 3.688-5.605S16 15 16 15zm0 0s.227 3.599 3.688 5.604c3.461 2.006 6.695.414 6.695.414s-.227-3.6-3.688-5.605S16 15 16 15zm0-12s3 2 3 6-3 6-3 6-3-2-3-6 3-6 3-6z"
    />
    <path
      fill="#4D5152"
      d="M13.041 15.4c.748 0 1.387.129 1.834.255a6.473 6.473 0 0 1-3.064 4.083 6.376 6.376 0 0 1-3.236.878 6.812 6.812 0 0 1-1.832-.254c.252-1.016.983-2.879 3.063-4.084a6.37 6.37 0 0 1 3.235-.878m0-1a7.379 7.379 0 0 0-3.736 1.012c-3.461 2.005-3.688 5.605-3.688 5.605s1.217.599 2.958.599a7.37 7.37 0 0 0 3.738-1.013C15.773 18.599 16 15 16 15s-1.218-.6-2.959-.6zm5.918 1c1.142 0 2.229.295 3.235.878a6.473 6.473 0 0 1 3.064 4.084 6.802 6.802 0 0 1-1.833.254 6.377 6.377 0 0 1-3.237-.878 6.473 6.473 0 0 1-3.063-4.083 6.817 6.817 0 0 1 1.834-.255m0-1C17.218 14.4 16 15 16 15s.227 3.599 3.688 5.604a7.38 7.38 0 0 0 3.738 1.013c1.74 0 2.957-.599 2.957-.599s-.227-3.6-3.688-5.605a7.37 7.37 0 0 0-3.736-1.013zm-2.96-10.101C16.751 5.024 18 6.592 18 9a6.476 6.476 0 0 1-1.999 4.701C15.25 12.976 14 11.408 14 9a6.478 6.478 0 0 1 1.999-4.701M16 3s-3 2-3 6 3 6 3 6 3-2 3-6-3-6-3-6z"
    />
    <circle fill="#BFBFBF" cx={16} cy={15} r={2} />
    <path
      fill="#4D5152"
      d="M16 13a2 2 0 1 1-.001 4.001A2 2 0 0 1 16 13m0-1c-1.654 0-3 1.346-3 3s1.346 3 3 3 3-1.346 3-3-1.346-3-3-3z"
    />
  </svg>
);

export default EolicEnergy;
