import React from "react";

const World = props => (
  <svg viewBox="0 0 32 32"   {...props}>
    <circle fill="#67DDE0" cx={16} cy={16} r={14} />
    <path
      fill="#75EA9C"
      d="M29.5 15.5a5.424 5.424 0 0 0-.077-.072c-2.024-1.842-5.936-.915-6.923.072-1 1 1.235 3.479-2 5-3.021 1.42-.813 4.688-4 6-2.067.852-4-2-3-5s-5.888-1.634-4-7c1.05-2.983 3.885-2.252 8-2 3.897.239 3-4 1-5s-7 3-8 1 4-6 4-6 6.811-.475 10.752 3.434 4.222 9.542 4.222 9.542"
    />
    <path
      fill="#4D5152"
      d="M16 3c7.168 0 13 5.832 13 13s-5.832 13-13 13S3 23.168 3 16 8.832 3 16 3m0-1C8.268 2 2 8.268 2 16s6.268 14 14 14 14-6.268 14-14S23.732 2 16 2z"
    />
    <path
      fill="none"
      stroke="#4D5152"
      strokeMiterlimit={10}
      d="M14.5 2.5s-5 4-4 6 6-2 8-1 2.897 5.239-1 5c-4.115-.252-6.95-.983-8 2-1.888 5.366 5 4 4 7s.932 5.852 3 5c3.188-1.313.979-4.58 4-6 3.235-1.521 1-4 2-5s5-1.938 7 0"
    />
  </svg>
);

export default World;
