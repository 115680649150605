import React from "react";

const CloudDownload = props => (
  <svg viewBox="0 0 32 23"   {...props}>
    <path
      d="M26.976 9.301c.003-.102.015-.201.015-.304C26.991 4.028 22.96 0 17.989 0a9.005 9.005 0 0 0-8.283 5.471A5.452 5.452 0 0 0 7.5 5.006a5.499 5.499 0 0 0-5.466 6.073A6.966 6.966 0 0 0 0 16.007 6.996 6.996 0 0 0 6.998 23l.016-.001V23h17.974v-.001l.015.001a6.991 6.991 0 1 0 1.973-13.699z"
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#48a0dc"
    />
    <path
      d="M19.675 13.293a1.04 1.04 0 0 0-1.466 0L17 14.497V9a1 1 0 1 0-2 0v5.545l-1.246-1.25c-.403-.404-1.057-.404-1.46 0s-.403 1.061 0 1.466l2.921 2.93c.194.195.446.285.7.292.029.002.055.017.085.017a.97.97 0 0 0 .51-.16.954.954 0 0 0 .153-.103c.016-.015.035-.023.05-.038.009-.009.021-.014.029-.022l2.933-2.923a1.03 1.03 0 0 0 0-1.461z"
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#fff"
    />
  </svg>
);

export default CloudDownload;
