import React from "react";

const EnvironmentalCompany = props => (
  <svg viewBox="0 0 32 32"   {...props}>
    <path
      d="M22 22.67V30H2V14h4V6h8v12h2.33c.12.36.27.69.45 1 .479.82 1.13 1.48 1.84 2 .12.1.25.19.38.27.67.45 1.37.79 2 1.04.36.15.7.27 1 .36"
      fill="#aaa"
    />
    <g fill="#4d5152">
      <path d="M5 25h2v2H5zM9 25h2v2H9zM13 25h2v2h-2zM17 25h2v2h-2zM5 21h2v2H5zM5 17h2v2H5zM9 21h2v2H9zM9 17h2v2H9zM9 13h2v2H9zM9 9h2v2H9zM13 21h2v2h-2zM19 21.27V23h-2v-2h1.62c.12.1.25.19.38.27" />
    </g>
    <path
      d="M30 16c0 4.15-4.47 5.54-6 5.9v3.6c0 .28-.22.5-.5.5s-.5-.22-.5-.5v-3.6c-1.53-.36-6-1.75-6-5.9 0-5 6.5-14 6.5-14S30 11 30 16"
      fill="#75ea9c"
    />
    <path
      d="M21 22.31V29H3V15h4V7h6v12h3.78c-.181-.31-.33-.64-.45-1H14V6H6v8H2v16h20v-7.33c-.3-.09-.64-.21-1-.36"
      fill="#4d5152"
    />
    <path
      d="M23.5 2S17 11 17 16c0 4.15 4.47 5.54 6 5.9v3.6c0 .28.22.5.5.5s.5-.22.5-.5v-3.6c1.53-.36 6-1.75 6-5.9 0-5-6.5-14-6.5-14m.5 18.91V17.7l2.85-2.85c.2-.19.2-.51 0-.7a.481.481 0 0 0-.698 0L24 16.3v-3.6l1.85-1.85c.2-.19.2-.51 0-.7a.481.481 0 0 0-.698 0L24 11.3V8.5c0-.28-.22-.5-.5-.5s-.5.22-.5.5v4.8l-1.15-1.15a.482.482 0 0 0-.699 0c-.2.19-.2.51 0 .7L23 14.7v3.6l-2.15-2.148a.482.482 0 0 0-.699 0c-.2.188-.2.51 0 .698L23 19.7v1.21c-1.5-.37-5.04-1.601-5.04-4.91 0-3.63 3.85-9.77 5.54-12.3 1.69 2.53 5.54 8.67 5.54 12.3 0 3.31-3.54 4.54-5.04 4.91"
      fill="#4d5152"
    />
  </svg>
);

export default EnvironmentalCompany;
