import React from "react";

const EnvironmentalHouse = props => (
  <svg viewBox="0 0 32 32"  {...props}>
    <path
      fill="#FFD878"
      d="M29.92 20.77a.494.494 0 0 1-.69.15L28 20.13V31H3V20.13l-1.23.79a.5.5 0 0 1-.69-.15.5.5 0 0 1 .15-.69l14-9c.16-.11.38-.11.54 0l1.48.95c-.09.36-.15.72-.19 1.06-.04.32-.06.62-.06.91 0 4.27 3.94 6.03 6 6.67v3.83c0 .83.67 1.5 1.5 1.5s1.5-.67 1.5-1.5v-3.83c.3-.09.64-.21 1-.36.56-.22 1.17-.51 1.76-.88l1.01.65c.23.15.3.46.15.69z"
    />
    <path
      fill="#E88C77"
      d="M11 31v-5.5c0-1.557.94-4.5 4.5-4.5s4.5 2.943 4.5 4.5V31h-9z"
    />
    <path
      fill="#4D5152"
      d="M29.77 20.08l-1.01-.65c-.59.37-1.2.66-1.76.88V30h-7v-4.5c0-1.56-.94-4.5-4.5-4.5S11 23.94 11 25.5V30H4V19.5h-.02l11.52-7.41 1.56 1c.04-.34.101-.7.19-1.06l-1.48-.95a.483.483 0 0 0-.54 0l-14 9a.5.5 0 0 0-.15.69c.15.23.46.3.69.15L3 20.13V31h25V20.13l1.23.79a.494.494 0 0 0 .69-.15.5.5 0 0 0-.15-.69zM19 30h-7v-4.5c0-.14.06-3.5 3.5-3.5s3.5 3.36 3.5 3.5V30z"
    />
    <path
      fill="#75EA9C"
      d="M31 14c0 4.15-4.47 5.54-6 5.9v4.6c0 .28-.22.5-.5.5s-.5-.22-.5-.5v-4.6c-1.53-.36-6-1.75-6-5.9 0-5 6.5-14 6.5-14S31 9 31 14"
    />
    <path
      fill="#4D5152"
      d="M24.5 0S18 9 18 14c0 4.15 4.47 5.54 6 5.9v4.6c0 .28.22.5.5.5s.5-.22.5-.5v-4.6c1.53-.36 6-1.75 6-5.9 0-5-6.5-14-6.5-14m.5 18.91V15.7l2.85-2.85c.2-.19.2-.51 0-.7a.481.481 0 0 0-.698 0L25 14.3v-3.6l1.85-1.85c.2-.19.2-.51 0-.7a.481.481 0 0 0-.698 0L25 9.3V6.5c0-.28-.22-.5-.5-.5s-.5.22-.5.5v4.8l-1.15-1.15a.482.482 0 0 0-.699 0c-.2.19-.2.51 0 .7L24 12.7v3.6l-2.15-2.148a.482.482 0 0 0-.699 0c-.2.188-.2.51 0 .698L24 17.7v1.21c-1.5-.37-5.04-1.601-5.04-4.91 0-3.63 3.85-9.77 5.54-12.3 1.69 2.53 5.54 8.67 5.54 12.3 0 3.31-3.54 4.54-5.04 4.91"
    />
  </svg>
);

export default EnvironmentalHouse;
