import React from "react";

const IpadGrey = props => (
  <svg viewBox="0 0 21 32.001"   {...props}>
    <path
      d="M19 0H2c-.896 0-2 1.042-2 2.001v28c0 .907 1.044 2 2 2h17c.95 0 1.999-1.024 2-2v-28C21 1.042 19.928 0 19 0z"
      fill="#ccc"
    />
    <path
      d="M12.037 12.001c-.278.01-.615.159-.814.36-.179.178-.336.463-.294.736.311.021.627-.136.82-.337.193-.201.323-.481.288-.759zm-.037 3c0-1.188.849-1.24.872-1.255-.289-.424-.739-.482-.898-.489-.383-.039-.747.227-.941.227-.193 0-.493-.221-.811-.215a1.203 1.203 0 0 0-1.017.619c-.434.755-.11 1.875.312 2.487.207.3.452.637.775.624.312-.012.43-.202.806-.202s.481.202.811.196c.335-.006.547-.306.752-.606.237-.348.334-.685.34-.702-.008-.004-1.001.063-1.001-.684z"
      fillRule="evenodd"
      clipRule="evenodd"
      fill="gray"
    />
    <circle cx={5} cy={4.999} r={1} fill="gray" />
  </svg>
);

export default IpadGrey;
