import React from "react";

const ReviewItems = props => (
  <svg viewBox="0 0 48 48"   {...props}>
    <path
      fill="#898CC5"
      d="M43 6h-5v4a2 2 0 0 1-2 2h-2a2 2 0 0 1-2-2V6H18v4a2 2 0 0 1-2 2h-2a2 2 0 0 1-2-2V6H9a1 1 0 0 0-1 1v38a1 1 0 0 0 1 1h34a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1z"
    />
    <path
      fill="#384D68"
      d="M15.715 28.285a.959.959 0 0 0-1.357 0L13 29.643l-1.357-1.357a.959.959 0 1 0-1.357 1.357L11.643 31l-1.357 1.357a.959.959 0 1 0 1.357 1.357L13 32.357l1.357 1.357a.959.959 0 1 0 1.357-1.357L14.357 31l1.357-1.357a.96.96 0 0 0 .001-1.358zm0-10a.959.959 0 0 0-1.357 0L13 19.643l-1.357-1.357a.959.959 0 1 0-1.357 1.357L11.643 21l-1.357 1.357a.959.959 0 1 0 1.357 1.357L13 22.357l1.357 1.357a.959.959 0 1 0 1.357-1.357L14.357 21l1.357-1.357a.96.96 0 0 0 .001-1.358zM41 6h-5V4a2 2 0 0 0-2-2h-2a2 2 0 0 0-2 2v2H16V4a2 2 0 0 0-2-2h-2a2 2 0 0 0-2 2v2H5a1 1 0 0 0-1 1v38a1 1 0 0 0 1 1h36a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1zm-9-1a1 1 0 1 1 2 0v4a1 1 0 1 1-2 0V5zM12 5a1 1 0 1 1 2 0v4a1 1 0 1 1-2 0V5zm28 39H6V8h4v2a2 2 0 0 0 2 2h2a2 2 0 0 0 2-2V8h14v2a2 2 0 0 0 2 2h2a2 2 0 0 0 2-2V8h4v36zm-6-24H20a1 1 0 1 0 0 2h14a1 1 0 1 0 0-2zm-4 10H20a1 1 0 1 0 0 2h10a1 1 0 1 0 0-2z"
    />
  </svg>
);

export default ReviewItems;
