import React from "react";

const Capsule2 = props => (
  <svg viewBox="0 0 64 60"   {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M32 18c-9.014 0-14.015-2.448-14.224-2.553l.447-.894C18.272 14.577 23.243 17 32 17c8.659 0 13.73-2.425 13.781-2.449l.438.898C46.006 15.554 40.888 18 32 18m-18.23 3.28l1.732 1a.994.994 0 0 1 .463.607.996.996 0 0 1-.098.758l-8 13.856a1.005 1.005 0 0 1-1.365.366l-1.732-1-.5.866 1.732 1a2.004 2.004 0 0 0 2.732-.732l8-13.856A2.005 2.005 0 0 0 16 21.413l-1.732-1-.5.866zm35.96-.867l-1.731 1a2.005 2.005 0 0 0-.733 2.732l8 13.856a2.004 2.004 0 0 0 2.733.732l1.731-1-.5-.866-1.731 1a1.005 1.005 0 0 1-1.366-.366l-8-13.856a.996.996 0 0 1-.099-.758.994.994 0 0 1 .465-.608l1.731-1-.5-.866zM63 46v1.836c0 .862-.587 1.599-1.429 1.794-.473.11-.999.246-1.593.399C55.949 51.067 48.451 53 32 53c-16.568 0-23.985-1.927-27.97-2.963a71.96 71.96 0 0 0-1.604-.404A1.834 1.834 0 0 1 1 47.84V46l-1-1v2.84c0 1.324.91 2.467 2.199 2.767C5.963 51.484 12.917 54 32 54c18.917 0 26.021-2.519 29.798-3.395A2.841 2.841 0 0 0 64 47.836V45"
        fill="#f2b430"
      />
      <path
        d="M43.426 11L62.86 44.583a.996.996 0 0 1-.562 1.459C58.552 47.18 48.718 49 31.999 49c-16.74 0-26.557-1.907-30.294-3.044a.996.996 0 0 1-.562-1.459L20.575 11 20 10 .275 44a1.996 1.996 0 0 0 1.133 2.918C5.242 48.093 15.115 50 32 50c16.798 0 26.726-1.826 30.587-3a1.995 1.995 0 0 0 1.138-2.92L44 10m16.444 40.406l2.417 4.177a.995.995 0 0 1-.562 1.458c-3.747 1.14-13.581 2.96-30.3 2.96-16.74 0-26.558-1.908-30.294-3.046a.995.995 0 0 1-.562-1.458l2.359-4.067-.906-.43-2.321 4a1.996 1.996 0 0 0 1.133 2.918C5.242 58.093 15.115 60 32 60c16.798 0 26.726-1.826 30.587-3a1.996 1.996 0 0 0 1.138-2.92l-2.262-3.91-1.02.236z"
        fill="#1d7ed0"
      />
      <path
        d="M32 0c-6.234 0-12 3.891-12 10.004V11s3.239 2 12 2c8.727 0 12-2 12-2v-1c0-6.05-5.4-10-12-10m0 1c6.374 0 11 3.785 11 9v.373C41.83 10.871 38.46 12 32 12c-6.489 0-9.844-1.13-11-1.624v-.372c0-3.259 1.783-5.337 3.278-6.507C26.308 1.91 29.122 1 32 1m6 28c.551 0 1 .449 1 1v19h1V30a2 2 0 0 0-2-2H26a2 2 0 0 0-2 2v19h1V30c0-.551.449-1 1-1h12"
        fill="#1d7ed0"
      />
      <path
        d="M32 31a5 5 0 1 0 .001 10.001A5 5 0 0 0 32 31m0 1c2.206 0 4 1.794 4 4s-1.794 4-4 4-4-1.794-4-4 1.794-4 4-4"
        fill="#1d7ed0"
      />
      <path
        d="M34.5 36a.5.5 0 0 1-.5-.5c0-.827-.673-1.5-1.5-1.5a.5.5 0 0 1 0-1c1.378 0 2.5 1.121 2.5 2.5a.5.5 0 0 1-.5.5"
        fill="#f2b430"
      />
    </g>
  </svg>
);

export default Capsule2;
