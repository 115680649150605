import React from "react";

const Leafs = props => (
  <svg viewBox="0 0 32 32"   {...props}>
    <path
      fill="#75EA9C"
      d="M26.521 20.247s-5.303-.353-7.424-2.474c-2.122-2.122.353-4.598.353-4.598s2.475-2.475 4.598-.352c2.12 2.122 2.473 7.424 2.473 7.424zM8.843 11.055s.353 5.303 2.474 7.424c2.122 2.123 4.597-.353 4.597-.353s2.475-2.475.353-4.597c-2.121-2.121-7.424-2.474-7.424-2.474zm8.485-8.485s.354 5.303 2.475 7.424 4.596-.354 4.596-.354 2.476-2.475.354-4.597c-2.122-2.12-7.425-2.473-7.425-2.473zm.707 26.162s-5.303-.353-7.424-2.474c-2.122-2.122.353-4.597.353-4.597s2.475-2.475 4.597-.354c2.121 2.123 2.474 7.425 2.474 7.425z"
    />
    <path
      fill="#4D5152"
      d="M3.186 30.854a.5.5 0 0 1-.707-.707L24.027 8.594a.5.5 0 0 1 .707 0l-.188-.303c.195.195.048 1.154-.147 1.35L3.186 30.854z"
    />
    <path
      fill="#4D5152"
      d="M15.914 18.126a.5.5 0 0 0 0-.707l-2.122-2.122a.5.5 0 1 0-.707.707l2.122 2.122a.5.5 0 0 0 .707 0zm-2.122 6.364a.5.5 0 0 0 0-.707l-2.121-2.121a.5.5 0 1 0-.707.707l2.121 2.121a.499.499 0 0 0 .707 0zm8.485-8.486a.5.5 0 0 0 0-.707l-2.121-2.121a.5.5 0 0 0-.707 0 .502.502 0 0 0 0 .708l2.121 2.121a.5.5 0 0 0 .707-.001zm-.707-9.191a.5.5 0 0 1 .707 0l2.122 2.121a.5.5 0 0 1-.708.707L21.57 7.52a.5.5 0 0 1 0-.707z"
    />
    <path
      fill="#4D5152"
      d="M22.116 12.964c.441 0 .842.186 1.224.567 1.188 1.189 1.798 3.836 2.05 5.586-1.749-.251-4.394-.859-5.586-2.051-1.331-1.331.063-2.888.35-3.18.26-.256 1.123-.922 1.962-.922m0-1c-1.455 0-2.667 1.212-2.667 1.212s-2.475 2.476-.353 4.598c2.121 2.121 7.424 2.474 7.424 2.474s-.353-5.302-2.474-7.423c-.637-.638-1.306-.861-1.93-.861zm-12.142.222c1.75.253 4.397.862 5.586 2.051 1.33 1.331-.063 2.888-.353 3.183-.239.238-1.111.92-1.96.92-.441 0-.841-.186-1.223-.567-1.189-1.19-1.797-3.836-2.05-5.587m-1.131-1.131s.353 5.303 2.474 7.424c.637.637 1.306.86 1.93.86 1.455 0 2.667-1.213 2.667-1.213s2.475-2.475.353-4.597c-2.121-2.121-7.424-2.474-7.424-2.474zM18.459 3.7c1.749.251 4.394.859 5.586 2.051 1.33 1.331-.063 2.887-.351 3.179-.262.257-1.124.923-1.964.923-.44 0-.84-.185-1.221-.566-1.188-1.189-1.797-3.836-2.05-5.587m-1.131-1.13s.354 5.303 2.475 7.424c.637.636 1.305.859 1.928.859 1.455 0 2.668-1.212 2.668-1.212s2.476-2.475.354-4.597c-2.122-2.121-7.425-2.474-7.425-2.474zm-3.695 18.879c.44 0 .84.186 1.221.566 1.188 1.188 1.797 3.836 2.05 5.587-1.749-.251-4.394-.859-5.586-2.051-1.331-1.331.063-2.887.353-3.183.239-.237 1.112-.918 1.962-.919m-.001-1c-1.455.001-2.668 1.213-2.668 1.213s-2.475 2.475-.353 4.597c2.121 2.121 7.424 2.474 7.424 2.474s-.354-5.303-2.474-7.424c-.637-.637-1.305-.86-1.929-.86z"
    />
  </svg>
);

export default Leafs;
