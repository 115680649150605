import React from "react";

const Planet3 = props => (
  <svg viewBox="0 0 64 50"   {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M57.703 2.553l1.155.26c.19.053.19.322 0 .374l-1.155.26a.271.271 0 0 0-.2.178l-.317 1.233c-.052.19-.32.19-.373 0l-.317-1.233a.27.27 0 0 0-.2-.177l-1.154-.261c-.19-.052-.19-.321 0-.373l1.154-.261a.273.273 0 0 0 .2-.177l.317-1.234c.052-.19.321-.19.373 0l.317 1.234a.273.273 0 0 0 .2.177m-2.364 15.97C50.124 17.887 33.205 4.141 32.217.631l.963-.27c.914 3.246 17.443 16.58 22.28 17.17l-.121.992zm.535 8.96c-.102-.025-10.209-2.698-17.18-8.087C32.102 14.299 26.474 4.022 26.5 1.132l1 .009c-.022 2.334 5.193 12.352 11.806 17.464 6.811 5.265 16.72 7.885 16.82 7.91l-.252.969zm-7.33 15.226c-.398-.879-.691-1.742-.975-2.578-.79-2.328-1.605-4.736-4.907-7.763-1.663-1.524-3.505-2.527-5.454-3.59-2.816-1.534-5.728-3.12-8.588-6.452-7.633-8.896-8.103-18.547-8.12-18.954l1-.04c.004.097.483 9.723 7.88 18.342 2.739 3.194 5.569 4.736 8.307 6.227 2.004 1.093 3.898 2.123 5.65 3.73 3.492 3.2 4.35 5.731 5.18 8.18.288.85.559 1.65.938 2.483l-.91.415zm-19.04 6.356c-.345-2.622-16.125-13.79-20.59-14.573l.172-.985c3.886.682 20.933 11.805 21.41 15.427l-.992.131zm6.996-.3c-.077-4.233-1.453-12.247-9.796-18.361-5.052-3.703-7.539-9.123-9.537-13.477-1.493-3.254-2.673-5.824-4.345-6.46l.356-.934c2.054.78 3.309 3.515 4.897 6.977 1.95 4.249 4.378 9.537 9.221 13.087 8.689 6.369 10.123 14.73 10.204 19.149l-1 .019zM7 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0"
        fill="#f2b430"
      />
      <path
        d="M32 0C18.193 0 7 11.193 7 25s11.193 25 25 25 25-11.193 25-25S45.807 0 32 0m0 1c13.234 0 24 10.766 24 24S45.234 49 32 49 8 38.234 8 25 18.766 1 32 1"
        fill="#1d7ed0"
      />
      <path
        d="M9.441 5c2.263 0 4.814.315 7.558.916l.46-.915C14.508 4.339 11.8 4 9.44 4 5.201 4 2.09 5.097.731 7.391c-3.739 6.317 7.23 19.321 24.5 29.046C36.226 42.63 47.125 46 54.558 46c4.24 0 7.352-1.097 8.71-3.391 2.147-3.627-.556-9.459-6.516-15.61l-.416 1.003c3.341 3.498 5.6 6.893 6.38 9.803.462 1.724.358 3.169-.309 4.295-1.419 2.397-5.05 2.9-7.849 2.9-7.568 0-18.349-3.527-28.838-9.434-12.804-7.21-22.625-16.602-24.438-23.371-.462-1.724-.358-3.169.31-4.295C3.01 5.503 6.642 5 9.44 5"
        fill="#f2b430"
      />
      <path
        d="M56.646 29.218c.233-1.371.354-2.78.354-4.218h-1l-.001.293a24.153 24.153 0 0 1-.316 3.612l.963.313zM15.63 7.464a24.26 24.26 0 0 1 3.758-2.877l-.671-.771a25.109 25.109 0 0 0-3.923 3.047l.836.6z"
        fill="#1d7ed0"
      />
    </g>
  </svg>
);

export default Planet3;
