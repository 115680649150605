import React from "react";

const Rocket = props => (
  <svg viewBox="0 0 780.192 1010"   {...props}>
    <path
      fill="#FFAB00"
      d="M390.1 663.746c-38.032 0-68.864 30.842-68.864 68.865v125.88h137.712V732.61c0-38.023-30.797-68.865-68.847-68.865z"
    />
    <path
      fill="#78909C"
      d="M266.142 335.79h-41.331L18.208 542.402V666.37h247.934M514.069 335.79h41.285l206.63 206.612V666.37H514.07"
    />
    <path
      fill="#EBEEF0"
      d="M236.697 330.34c0-123.967 153.404-247.935 153.404-247.935S543.512 206.373 543.512 330.34v387.558H236.697V330.34z"
    />
    <path
      fill="#AEBCC4"
      d="M431.422 422.56c0 22.803-18.492 41.313-41.321 41.313-22.804 0-41.323-18.51-41.323-41.313 0-22.822 18.519-41.323 41.323-41.323 22.83 0 41.321 18.501 41.321 41.323zm0-137.748c0 22.813-18.492 41.332-41.321 41.332-22.804 0-41.323-18.519-41.323-41.332 0-22.813 18.519-41.322 41.323-41.322 22.83 0 41.321 18.51 41.321 41.322zm0 275.477c0 22.822-18.492 41.332-41.321 41.332-22.804 0-41.323-18.51-41.323-41.332 0-22.804 18.519-41.331 41.323-41.331 22.83 0 41.321 18.527 41.321 41.331z"
    />
    <g fill="#43525B">
      <path d="M344.36 899.83h36.415V1010H344.36zM399.418 899.83h36.414V1010h-36.414zM390.1 363.03c-32.823 0-59.53 26.706-59.53 59.53 0 32.823 26.707 59.52 59.53 59.52 32.824 0 59.531-26.697 59.531-59.52 0-32.824-26.707-59.53-59.53-59.53zm0 82.636c-12.748 0-23.114-10.367-23.114-23.106 0-12.749 10.366-23.115 23.115-23.115 12.75 0 23.114 10.366 23.114 23.115 0 12.74-10.365 23.106-23.114 23.106zm0-220.384c-32.823 0-59.53 26.707-59.53 59.53 0 32.833 26.707 59.54 59.53 59.54 32.824 0 59.531-26.707 59.531-59.54 0-32.823-26.707-59.53-59.53-59.53zm0 82.654c-12.748 0-23.114-10.375-23.114-23.124 0-12.749 10.366-23.115 23.115-23.115 12.75 0 23.114 10.366 23.114 23.115 0 12.749-10.365 23.124-23.114 23.124zm0 192.815c-32.823 0-59.53 26.706-59.53 59.538 0 32.832 26.707 59.54 59.53 59.54 32.824 0 59.531-26.708 59.531-59.54s-26.707-59.538-59.53-59.538zm0 82.662c-12.748 0-23.114-10.375-23.114-23.124 0-12.748 10.366-23.123 23.115-23.123 12.75 0 23.114 10.375 23.114 23.123 0 12.75-10.365 23.124-23.114 23.124z" />
      <path d="M780.192 534.445L561.722 332.18v-1.84c0-117.549-123.08-230.377-153.415-256.399V18.207C408.307 8.152 400.155 0 390.101 0c-10.055 0-18.208 8.152-18.208 18.207v55.734C341.568 99.963 218.49 212.801 218.49 330.34v1.787L0 534.445v150.131h218.49v51.53h84.538v140.591h174.127V736.106h84.566v-51.53h218.47v-150.13zM36.415 648.162V550.36L218.49 381.762v266.4H36.415zm404.326 192.121h-32.434V758.26h-36.414v82.023h-32.45V736.105h101.298v104.178zM254.905 699.69V353.997h56.667v-36.415h-55.85c7.077-77.222 75.569-154.577 116.171-194.068v35.89c0 10.055 8.153 18.208 18.208 18.208 10.054 0 18.206-8.153 18.206-18.208v-35.89c40.611 39.491 109.104 116.846 116.18 194.068H468.62v36.415h56.685V699.69h-270.4zm488.873-51.528H561.72V381.797L743.778 550.36v97.803z" />
    </g>
  </svg>
);

export default Rocket;
