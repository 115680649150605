import React from "react";

const HomeDelivery1 = props => (
  <svg viewBox="0 0 48 48"   {...props}>
    <path
      fill="#FFDA8E"
      d="M15 21.205c-.018.166-.001.221 0 0zm0 0l.001-.006v-.02l-.001.026zm25.981-.157a.985.985 0 0 0-.278-.583l.005-.005-.029-.029-.004-.004L28.708 8.784s-.086-.116-.348-.215a.878.878 0 0 0-.672-.009c-.299.098-.395.224-.395.224l-11.975 11.65c-.222.205-.296.559-.318.764v22.368c0 .515.416.933.929.933h24.143a.931.931 0 0 0 .929-.933l-.02-22.518z"
    />
    <path
      fill="#384D68"
      d="M14.001 26.499h13.648l-3.373 3.362a.952.952 0 0 0 0 1.351.963.963 0 0 0 1.357 0l5.072-5.053a.952.952 0 0 0 0-1.351l-5.072-5.053a.963.963 0 0 0-1.357 0 .952.952 0 0 0 0 1.351l3.403 3.393H14.001a1 1 0 1 0 0 2zm-3 12a3 3 0 1 0 0 6 3 3 0 0 0 0-6zm0 4a1 1 0 1 1 0-2 1 1 0 1 1 0 2zm34.703-19.706L26.66 3.795a1.006 1.006 0 0 0-1.421 0l-9.941 9.916a.998.998 0 0 0 0 1.416 1.004 1.004 0 0 0 1.42 0L25.95 5.92l18.334 18.29a1.006 1.006 0 0 0 1.42 0 .999.999 0 0 0 0-1.417zm-5.703.706a1 1 0 0 0-1 1v18h-6v-7a1 1 0 0 0-1-1h-3a1 1 0 1 0 0 2h2v7a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-19a1 1 0 0 0-1-1zm-18 15a3 3 0 1 0 0 6 3 3 0 0 0 0-6zm0 4a1 1 0 1 1 0-2 1 1 0 1 1 0 2zm3-8H9.858L7.989 22.347l-.018.003a.987.987 0 0 0-.97-.851h-4a1 1 0 1 0 0 2h3.143l1.869 12.152.019-.003c.075.479.47.851.97.851h16a1 1 0 1 0-.001-2z"
    />
  </svg>
);

export default HomeDelivery1;
