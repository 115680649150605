import React from "react";

const HighHeelSandals2 = props => (
  <svg viewBox="0 0 32 28"   {...props}>
    <path
      d="M27 20c-1 0-1.553.106-2 1.001-1 2-4 6-4 6h4s3-5 3-6S28 20 27 20zM17.975 3.282a.98.98 0 0 0-.689-1.199L5 0S0 3.001 0 8h3S2 4 5 2l11.781 1.975a.974.974 0 0 0 1.194-.693zM23 18c-1 0-1.293.294-2 1.001l-7 7 4 1s6-7 6-8S24 18 23 18z"
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#ed7161"
    />
    <path
      d="M14.978 1.435L12.481.986c-.689-.124-1.351.354-1.478 1.066-.126.713.33 1.391 1.019 1.515l2.497.448c.69.125 1.352-.353 1.478-1.065.127-.713-.33-1.391-1.019-1.515z"
      fill="#999"
    />
    <path
      d="M30 25.001c-1 0-3 1-13 1-1 0-3.422-5.739-7-12-4-7-8-7-9-7s-1 0-1 .999v1.001s3 4 3 18a.999.999 0 1 0 2 0v-12c0-1 0-2 1-2 .594 0 1.496 1.136 2 2C15 27.001 13 28 16 28c11 0 16-.999 16-1.999s-1-1-2-1z"
      fill="#666"
    />
  </svg>
);

export default HighHeelSandals2;
