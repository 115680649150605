import React from "react";

const Genetics = props => (
  <svg viewBox="0 0 1010 917.877"   {...props}>
    <path
      fill="#43525B"
      d="M434.632 779.693H150.646L8.655 533.793l141.993-245.94h283.985l141.953 245.94-141.953 245.9zm-260.416-40.837h236.846l118.384-205.063L411.062 328.69H174.216L55.792 533.793l118.424 205.063z"
    />
    <path
      fill="#43525B"
      d="M825.216 554.212H541.23l-141.952-245.94L541.23 62.332h283.986l141.993 245.94-141.993 245.94zM564.8 513.375h236.848L920.07 308.273 801.648 103.17H564.8L446.416 308.272 564.8 513.375zM145.282 319.358L89.85 233.696l34.297-22.172 55.432 85.662-34.297 22.172zM452.438 850.48l-47.217-80.917 35.254-20.578 47.217 80.917-35.254 20.577z"
    />
    <path
      fill="#FFAB00"
      d="M223.686 759.274c0-33.818-27.437-61.255-61.255-61.255s-61.255 27.437-61.255 61.255c0 33.819 27.437 61.256 61.255 61.256s61.255-27.437 61.255-61.256z"
    />
    <path
      fill="#43525B"
      d="M162.43 840.948c-45.043 0-81.673-36.629-81.673-81.674 0-45.044 36.63-81.673 81.674-81.673 45.045 0 81.674 36.63 81.674 81.673 0 45.045-36.63 81.674-81.674 81.674zm0-122.51c-22.511 0-40.836 18.325-40.836 40.836 0 22.513 18.325 40.837 40.837 40.837 22.512 0 40.838-18.324 40.838-40.837 0-22.511-18.326-40.837-40.838-40.837z"
    />
    <path
      fill="#AEBCC4"
      d="M876.083 82.551c0-34.297-27.836-62.133-62.173-62.133s-62.173 27.836-62.173 62.133c0 34.377 27.836 62.173 62.173 62.173s62.173-27.796 62.173-62.173z"
    />
    <path
      fill="#43525B"
      d="M813.91 165.143c-45.543 0-82.592-37.049-82.592-82.592C731.318 37.028 768.368 0 813.91 0c45.543 0 82.592 37.028 82.592 82.551 0 45.543-37.049 82.592-82.592 82.592zm0-124.306c-23.03 0-41.754 18.704-41.754 41.714 0 23.031 18.724 41.755 41.754 41.755 23.03 0 41.754-18.724 41.754-41.755 0-23.01-18.724-41.714-41.754-41.714z"
    />
    <path
      fill="#FFAB00"
      d="M530.284 851.517c0-28.196-22.851-51.046-51.047-51.046-28.195 0-51.046 22.85-51.046 51.046 0 28.195 22.851 51.046 51.046 51.046 28.196 0 51.047-22.851 51.047-51.046z"
    />
    <path
      fill="#43525B"
      d="M479.237 917.877c-36.59 0-66.36-29.77-66.36-66.36s29.77-66.36 66.36-66.36 66.36 29.77 66.36 66.36-29.77 66.36-66.36 66.36zm0-102.093c-19.7 0-35.732 16.032-35.732 35.733 0 19.7 16.032 35.732 35.732 35.732 19.701 0 35.733-16.031 35.733-35.732 0-19.7-16.032-35.733-35.733-35.733z"
    />
    <path
      fill="#FFAB00"
      d="M473.894 308.272c0-28.195-22.852-51.046-51.046-51.046-28.196 0-51.047 22.851-51.047 51.046s22.851 51.046 51.047 51.046c28.194 0 51.046-22.851 51.046-51.046z"
    />
    <path
      fill="#43525B"
      d="M422.848 374.632c-36.59 0-66.36-29.77-66.36-66.36s29.77-66.36 66.36-66.36 66.359 29.77 66.359 66.36-29.77 66.36-66.36 66.36zm0-102.093c-19.702 0-35.733 16.032-35.733 35.734 0 19.7 16.031 35.732 35.733 35.732 19.7 0 35.732-16.032 35.732-35.732 0-19.702-16.032-35.734-35.732-35.734z"
    />
    <path
      fill="#AEBCC4"
      d="M624.76 533.793c0-39.481-32.024-71.465-71.505-71.465-39.441 0-71.425 31.984-71.425 71.465 0 39.441 31.984 71.465 71.425 71.465 39.481 0 71.505-32.024 71.505-71.465z"
    />
    <path
      fill="#43525B"
      d="M553.255 625.677c-50.648 0-91.844-41.216-91.844-91.884 0-50.667 41.196-91.883 91.844-91.883 50.687 0 91.923 41.216 91.923 91.883 0 50.668-41.236 91.884-91.923 91.884zm0-142.93c-28.115 0-51.007 22.89-51.007 51.046 0 28.155 22.892 51.047 51.007 51.047 28.175 0 51.086-22.892 51.086-51.047 0-28.155-22.911-51.046-51.086-51.046z"
    />
    <path
      fill="#FFAB00"
      d="M994.687 314.334c0-28.195-22.852-51.047-51.047-51.047-28.196 0-51.046 22.852-51.046 51.047 0 28.195 22.85 51.046 51.046 51.046 28.195 0 51.047-22.85 51.047-51.046z"
    />
    <path
      fill="#43525B"
      d="M943.64 380.694c-36.59 0-66.36-29.77-66.36-66.36s29.77-66.36 66.36-66.36 66.36 29.77 66.36 66.36-29.77 66.36-66.36 66.36zm0-102.092c-19.7 0-35.733 16.03-35.733 35.732 0 19.7 16.032 35.732 35.733 35.732 19.7 0 35.732-16.032 35.732-35.732 0-19.701-16.031-35.732-35.732-35.732z"
    />
    <g>
      <path
        fill="#AEBCC4"
        d="M142.93 184.524c0-33.818-27.398-61.255-61.256-61.255-33.819 0-61.255 27.437-61.255 61.255 0 33.859 27.436 61.256 61.255 61.256 33.858 0 61.256-27.397 61.256-61.256z"
      />
      <path
        fill="#43525B"
        d="M81.674 266.198C36.63 266.198 0 229.57 0 184.524c0-45.044 36.63-81.674 81.674-81.674 45.045 0 81.675 36.63 81.675 81.674 0 45.045-36.63 81.674-81.675 81.674zm0-122.51c-22.512 0-40.837 18.324-40.837 40.836 0 22.513 18.325 40.837 40.837 40.837 22.512 0 40.837-18.324 40.837-40.837 0-22.512-18.325-40.837-40.837-40.837z"
      />
    </g>
  </svg>
);

export default Genetics;
