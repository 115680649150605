import React from "react";

const Syringe = props => (
  <svg viewBox="0 0 1000.535 1010"   {...props}>
    <path
      fill="#FFC44C"
      d="M334.903 858.205c-4.583 4.623-10.71 7.154-17.235 7.154h-.011c-6.525 0-12.673-2.53-17.285-7.154l-74.204-74.203c-.03-.04-.03-.08-.07-.08-.04-.04-.06-.04-.1-.08l-74.194-74.184c-9.534-9.545-9.534-25.047-.01-34.591L481.12 345.733l183.158 183.138-329.374 329.334z"
    />
    <path
      fill="#FFAB00"
      d="M612.5 605.586L347.377 870.68c-16.42 16.438-43.05 16.419-59.469 0L139.331 722.112c-16.439-16.46-16.439-43.04 0-59.498l68.455-57.028"
    />
    <path
      fill="#43525B"
      d="M436.993 630.334c-9.813 9.764-9.813 25.664 0 35.428 9.784 9.803 25.654 9.803 35.429 0 9.793-9.764 9.793-25.664 0-35.428-9.775-9.784-25.645-9.784-35.429 0m-82.732 82.692c-9.784 9.803-9.784 25.664 0 35.448 9.793 9.784 25.674 9.784 35.458 0 9.793-9.784 9.793-25.645 0-35.449-9.784-9.802-25.665-9.802-35.458 0m23.642-189.015c-9.783 9.783-9.783 25.624 0 35.428 9.794 9.802 25.675 9.802 35.448 0 9.784-9.804 9.784-25.645 0-35.428-9.773-9.804-25.654-9.804-35.448 0m-47.264 94.488c-9.784 9.803-9.784 25.684 0 35.467 9.793 9.784 25.674 9.784 35.458 0 9.793-9.783 9.793-25.664 0-35.467-9.784-9.763-25.665-9.763-35.458 0m-82.702 59.08c-9.794 9.803-9.794 25.684 0 35.448 9.793 9.803 25.655 9.803 35.438 0 9.784-9.764 9.784-25.645 0-35.448-9.783-9.784-25.645-9.784-35.438 0"
    />
    <path
      fill="#AEBCC4"
      d="M629.785 444.545l-64.35-64.36c.07-.04.179-.08.239-.16l168.134-168.054 64.221 64.24-168.094 168.135c-.07.04-.09.14-.15.199z"
    />
    <path
      fill="#EBEEF0"
      d="M708.89 187.044L540.757 355.118c-.07.08-.1.18-.16.239l-34.561-34.551 168.313-168.314 34.541 34.552zm-19.686 316.92l-34.581-34.571c.07-.06.159-.08.23-.14L822.965 301.1l34.561 34.552-168.323 168.313z"
    />
    <path
      fill="#AEBCC4"
      d="M908.657 165.584l-85.661 85.7-64.27-64.26 85.68-85.721c.379-.379.498-.877.837-1.276l64.69 64.72c-.39.319-.897.458-1.276.837z"
    />
    <path
      fill="#43525B"
      d="M994.557 195.572L804.973 5.978c-7.97-7.97-20.882-7.97-28.852 0-7.97 7.97-7.97 20.882 0 28.852l40.49 40.49-82.803 82.881L629.318 53.72c-7.972-7.97-20.883-7.97-28.853 0-7.971 7.97-7.971 20.882 0 28.852l45.022 45.013-520.592 520.612c-24.33 24.35-24.33 63.982.01 88.341l59.857 59.847L5.977 975.17c-7.97 7.97-7.97 20.882 0 28.852 3.986 3.986 9.206 5.978 14.427 5.978s10.441-1.992 14.426-5.978l178.785-178.784 59.867 59.868c11.796 11.794 27.478 18.29 44.155 18.3h.02c16.679 0 32.37-6.505 44.147-18.3l520.63-520.603 45.013 45.012a20.338 20.338 0 0 0 14.427 5.978c5.22 0 10.44-1.992 14.426-5.978 7.97-7.97 7.97-20.882 0-28.852L851.839 276.21l82.82-82.84 31.046 31.053a20.336 20.336 0 0 0 14.426 5.978c5.22 0 10.441-1.992 14.426-5.978 7.97-7.97 7.97-20.882 0-28.852zM569.41 380.244l164.398-164.328 60.286 60.295L629.745 440.57l-60.336-60.326zm135.545-193.19L540.557 351.402l-30.585-30.587L674.34 156.438l30.615 30.616zM332.94 856.262c-4.085 4.086-9.514 6.337-15.293 6.337s-11.218-2.262-15.312-6.347L228.2 782.118c-.06-.05-.07-.13-.129-.188-.06-.06-.14-.07-.19-.131l-74.114-74.104c-8.438-8.459-8.438-22.207-.01-30.656L481.12 349.67l179.231 179.2-327.41 327.39zm356.264-356.243l-30.605-30.596 164.367-164.378 30.606 30.606-164.368 164.368zm133.782-252.66l-30.158-30.148-30.167-30.157 82.79-82.882 60.356 60.346-82.82 82.841z"
    />
  </svg>
);

export default Syringe;
