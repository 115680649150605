import React from "react";

const Planet2 = props => (
  <svg viewBox="0 0 64 54"   {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M32 2C18.193 2 7 13.193 7 27s11.193 25 25 25 25-11.193 25-25S45.807 2 32 2m0 1c13.234 0 24 10.766 24 24S45.234 51 32 51 8 40.234 8 27 18.766 3 32 3"
        fill="#1d7ed0"
      />
      <path
        d="M56.407 3.106l2.309.522c.379.104.379.64 0 .745l-2.31.522a.543.543 0 0 0-.398.355l-.635 2.466c-.104.379-.641.379-.745 0l-.635-2.466a.54.54 0 0 0-.4-.355l-2.31-.522c-.377-.104-.377-.641 0-.745l2.31-.522a.543.543 0 0 0 .4-.355l.635-2.467c.104-.378.64-.378.745 0l.634 2.467a.544.544 0 0 0 .4.355M43 40a1 1 0 1 1-2 0 1 1 0 0 1 2 0M9 44a1 1 0 1 1-2 0 1 1 0 0 1 2 0m42 8a2 2 0 1 1-3.999.001A2 2 0 0 1 51 52"
        fill="#f2b430"
      />
      <path
        d="M32 2a24.886 24.886 0 0 0-12.342 3.254 25.11 25.11 0 0 0-6.251 5.034A24.908 24.908 0 0 0 7 27c0 13.807 11.193 25 25 25s25-11.193 25-25S45.807 2 32 2m0 1c13.234 0 24 10.766 24 24S45.234 51 32 51 8 40.234 8 27 18.766 3 32 3"
        fill="#1d7ed0"
      />
      <path
        d="M9.441 7c2.263 0 4.814.315 7.558.916l.46-.915C14.508 6.339 11.8 6 9.44 6 5.201 6 2.09 7.097.731 9.391c-3.739 6.317 7.23 19.321 24.5 29.046C36.226 44.63 47.125 48 54.558 48c4.24 0 7.352-1.097 8.71-3.391 2.147-3.627-.556-9.459-6.516-15.61l-.416 1.003c3.341 3.498 5.6 6.893 6.38 9.803.462 1.724.358 3.169-.309 4.295-1.419 2.397-5.05 2.9-7.849 2.9-7.568 0-18.349-3.527-28.838-9.434-12.804-7.21-22.625-16.602-24.438-23.371-.462-1.724-.358-3.169.31-4.295C3.01 7.503 6.642 7 9.44 7"
        fill="#f2b430"
      />
      <path
        d="M56.646 31.218c.233-1.371.354-2.78.354-4.218h-1l-.001.293a24.153 24.153 0 0 1-.316 3.612l.963.313zM15.63 9.464a24.26 24.26 0 0 1 3.758-2.877l-.671-.771a25.109 25.109 0 0 0-3.923 3.047l.836.6z"
        fill="#1d7ed0"
      />
    </g>
  </svg>
);

export default Planet2;
