import React from "react";

const EcoFriendlyVehicle = props => (
  <svg viewBox="0 0 32 32"   {...props}>
    <path
      fill="#75EA9C"
      d="M13.96 14c0 4.15-4.47 5.54-6 5.9v3.6c0 .28-.22.5-.5.5s-.5-.22-.5-.5v-3.6c-1.53-.36-6-1.75-6-5.9 0-5 6.5-14 6.5-14s6.5 9 6.5 14"
    />
    <path
      fill="#4D5152"
      d="M7.46 0S.96 9 .96 14c0 4.15 4.47 5.54 6 5.9v3.6c0 .28.22.5.5.5s.5-.22.5-.5v-3.6c1.53-.36 6-1.75 6-5.9 0-5-6.5-14-6.5-14m.5 18.91V15.7l2.85-2.85c.2-.19.2-.51 0-.7a.482.482 0 0 0-.699 0L7.96 14.3v-3.6l1.85-1.85c.2-.19.2-.51 0-.7a.482.482 0 0 0-.699 0L7.96 9.3V6.5c0-.28-.22-.5-.5-.5s-.5.22-.5.5v4.8l-1.15-1.15c-.19-.2-.51-.2-.7 0-.2.19-.2.51 0 .7l1.85 1.85v3.6l-2.15-2.149c-.19-.2-.51-.2-.7 0-.2.189-.2.51 0 .699l2.85 2.85v1.21c-1.5-.37-5.04-1.6-5.04-4.91 0-3.63 3.85-9.77 5.54-12.3C9.15 4.23 13 10.37 13 14c0 3.31-3.54 4.54-5.04 4.91"
    />
    <path
      fill="#4D5152"
      d="M30.49 18.88c-1.01-1.07-2.51-1.54-8.01-2.04-1.5-3-4-3.84-8-3.84-4.5 0-8 2.5-12 4-.94.35-1.54.83-1.54 1.58s1.04 2.99 1.45 4.61c.29 1.149 1.08 2.239 3.62 2.64A3.489 3.489 0 0 0 9.48 29c1.76 0 3.2-1.31 3.45-3h9.1c.24 1.69 1.689 3 3.45 3 1.76 0 3.199-1.31 3.449-3h2.051c.53 0 1.02-.54 1.02-1.02 0-2.48-.47-5.02-1.51-6.1m-15.51-4.86c3.47.07 5.4.88 6.59 3.27l.06.12L19.771 19H14.98v-4.98zm-1 0V19H9.29l-1.55-3.15c2.06-.92 4.08-1.72 6.24-1.83M9.48 28a2.5 2.5 0 0 1 0-5 2.5 2.5 0 0 1 0 5m16 0a2.5 2.5 0 0 1 0-5 2.5 2.5 0 0 1 0 5m5.51-3.07l-.06.07h-2c-.25-1.69-1.689-3-3.449-3a3.491 3.491 0 0 0-3.45 3h-9.1c-.25-1.69-1.69-3-3.45-3-1.69 0-3.11 1.2-3.43 2.8-1.54-.31-2.46-.95-2.76-1.95-.81-2.779-1.25-3.81-1.25-3.81-.17-.55.17-.9.87-1.14 1.36-.471 2.65-1.061 3.92-1.641l1.7 3.46A.508.508 0 0 0 8.98 20h11c.13 0 .26-.05.35-.15l2.31-1.989c5.381.51 6.44.989 7.12 1.71.71.739 1.23 2.93 1.23 5.14v.219z"
    />
    <path
      fill="#ACF0F2"
      d="M13.98 14.02V19H9.29l-1.55-3.15c2.06-.92 4.08-1.72 6.24-1.83m7.65 3.39L19.77 19h-4.79v-4.98c3.47.07 5.4.88 6.59 3.27l.06.12z"
    />
    <path
      fill="#AAA"
      d="M27.98 25.5a2.5 2.5 0 0 1-5 0 2.5 2.5 0 0 1 5 0m-16 0a2.5 2.5 0 0 1-5 0 2.5 2.5 0 0 1 5 0"
    />
    <path
      fill="#E88C77"
      d="M30.99 24.71v.22l-.06.07h-2c-.25-1.69-1.689-3-3.449-3a3.491 3.491 0 0 0-3.45 3h-9.1c-.25-1.69-1.69-3-3.45-3-1.69 0-3.11 1.2-3.43 2.8-1.54-.31-2.46-.95-2.76-1.95-.81-2.779-1.25-3.81-1.25-3.81-.17-.55.17-.9.87-1.14 1.36-.471 2.65-1.061 3.92-1.641l1.7 3.46A.508.508 0 0 0 8.98 20h11c.13 0 .26-.05.35-.15l2.31-1.989c5.381.51 6.44.989 7.12 1.71.71.739 1.23 2.929 1.23 5.139"
    />
    <path
      fill="#4D5152"
      d="M16 28.5a.5.5 0 0 0-.5-.5h-13a.5.5 0 0 0 0 1h13a.5.5 0 0 0 .5-.5m16 0a.5.5 0 0 0-.5-.5h-13a.5.5 0 0 0 0 1h13a.5.5 0 0 0 .5-.5"
    />
  </svg>
);

export default EcoFriendlyVehicle;
