import React from "react";

const SaleCoupon = props => (
  <svg viewBox="0 0 48 48"   {...props}>
    <path
      fill="#FFDA8E"
      d="M22.998 19.082l-3.157-2.735.836-3.347H7a1 1 0 0 0-1 1v12.022l.299.025 1.56 3.881 3.571-2.165 3.536 2.222 1.619-3.856 4.164-.287-.95-4.073 3.199-2.687z"
    />
    <path
      fill="#384D68"
      d="M45 10H3a1 1 0 0 0-1 1v28a1 1 0 0 0 1 1h42a1 1 0 0 0 1-1V11a1 1 0 0 0-1-1zM4 12h9.99l1.424.119-.749 2.951 2.334 1.989-2.365 1.954.702 2.962-3.077.209-1.197 2.805-2.614-1.615-2.639 1.574-1.152-2.822L4 22.071V12zm40 26H4V25.793l.858 2.136 3.571-2.165 3.536 2.222 1.619-3.856 4.164-.287-.95-4.073 3.199-2.687-3.157-2.735.588-2.348H44v26zM30 27a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm0-4a1 1 0 1 1 0 2 1 1 0 1 1 0-2zm9 7a3 3 0 1 0 0 6 3 3 0 0 0 0-6zm0 4a1 1 0 1 1 0-2 1 1 0 1 1 0 2zm-11 2a.997.997 0 0 0 .707-.293l13-13a1 1 0 1 0-1.414-1.414l-13 13A1 1 0 0 0 28 36z"
    />
  </svg>
);

export default SaleCoupon;
