import React from "react";

const Weight = props => (
  <svg viewBox="0 0 40 44"   {...props}>
    <path
      fill="#898CC5"
      d="M36 18a1 1 0 0 0-1-1H10a1 1 0 0 0-1 1L5 43a1 1 0 0 0 1 1h33a1 1 0 0 0 1-1l-4-25z"
    />
    <path
      fill="#384D68"
      d="M39.973 42.861l.018-.003-4-28-.018.003A.99.99 0 0 0 35 14h-5v-4c0-5.522-4.477-10-10-10S10 4.478 10 10v4H5a.988.988 0 0 0-.972.861l-.018-.003-4 28 .018.003C.021 42.908 0 42.951 0 43a1 1 0 0 0 1 1h38a1 1 0 0 0 1-1c0-.049-.021-.092-.027-.139zM12 10a8 8 0 0 1 16 0v4h-2v-4a6 6 0 0 0-12 0v4h-2v-4zm12 0v4h-8v-4a4 4 0 0 1 8 0zM2.153 42l3.714-26h28.266l3.715 26H2.153zm9.889-9.053v-8.295h-1.47v8.49c0 1.5 1.17 2.67 2.685 2.67h1.095v-1.471h-.915a1.38 1.38 0 0 1-1.395-1.394zm7.663-5.415h-2.25v-2.88h-1.47v11.16h3.72c1.5 0 2.67-1.17 2.67-2.67v-2.94c0-1.5-1.17-2.67-2.67-2.67zm1.2 5.415c0 .78-.615 1.395-1.395 1.395h-2.055v-5.354h2.055c.78 0 1.395.63 1.395 1.396v2.563zm6.212-1.95h-.766a1.065 1.065 0 0 1-1.049-1.064c0-.57.479-1.065 1.049-1.065h2.836v-1.335h-2.955c-1.336 0-2.37 1.021-2.37 2.385 0 1.38 1.034 2.415 2.37 2.415h.765c.585 0 1.065.495 1.065 1.08 0 .57-.48 1.065-1.065 1.065h-3.135v1.335h3.255c1.335 0 2.385-1.021 2.385-2.4 0-1.366-1.05-2.416-2.385-2.416zm4.305 4.815h1.859v-1.86h-1.859v1.86z"
    />
  </svg>
);

export default Weight;
