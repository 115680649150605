import React from "react";

const DeliveryGuy = props => (
  <svg viewBox="0 0 48 48"   {...props}>
    <path
      fill="#FFDA8E"
      d="M16.034 6.109c.023.128.048.162 0 0zm21.932 0c-.048.162-.023.128 0 0zM37 5H17c-1.026 0-1.019.786-.968 1.096.24.721 1.876 5.632 1.968 5.904v10c0 4.603 4.444 8 9 8s9-3.397 9-8V12l1.968-5.904C38.019 5.786 38.026 5 37 5zm.968 1.096l-.002.014c.009-.031.019-.065.034-.11l-.032.096zm-21.934.013l-.002-.013L16 6l.034.109z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#384D68"
      d="M12.103 3.471l-.052-.155.005-.002c.024.075.038.122.047.157l1.948 5.845.005-.002a.993.993 0 0 0 .509.572A8.882 8.882 0 0 0 14 13v7c0 4.971 5.029 9 10 9s10-4.029 10-9v-7a8.898 8.898 0 0 0-.524-3H41a1 1 0 1 0 0-2h-6.613l1.511-4.529a1.84 1.84 0 0 1 .047-.156l.005.002-.052.154C35.797 3.876 36.573 2 35 2H13c-1.574 0-.798 1.875-.897 1.471zM32 20c0 3.866-4.134 7-8 7s-8-3.134-8-7v-7c0-1.077.254-2.091.69-3h14.618c.438.909.692 1.923.692 3v7zm1.613-16l-1.334 4H15.721l-1.334-4h19.226zM36 33H12C6.477 33 2 37.478 2 43v2a1 1 0 1 0 2 0v-2a8 8 0 0 1 8-8h24a8 8 0 0 1 8 8v2a1 1 0 1 0 2 0v-2c0-5.522-4.477-10-10-10zM24 21a1 1 0 0 0 1-1v-4a1 1 0 1 0-2 0v4a1 1 0 0 0 1 1zm4-8a1 1 0 1 0 0 2 1 1 0 1 0 0-2zm-8 2a1 1 0 1 0 0-2 1 1 0 1 0 0 2zm8 6a.998.998 0 0 0-.722.314C26.549 22.037 25.096 23 24 23c-1.104 0-2.573-1.01-3.297-1.734 0 0-.178-.266-.703-.266a1 1 0 0 0-1 1c0 .316.156.587.386.77C20.488 23.833 22.389 25 24 25c1.656 0 3.625-1.203 4.711-2.289l-.004-.004A1 1 0 0 0 28 21z"
    />
  </svg>
);

export default DeliveryGuy;
