import React from "react";

const Calendar = props => (
  <svg viewBox="0 0 32 32"   {...props}>
    <path d="M0 10v20a2 2 0 0 0 2 2h28a2 2 0 0 0 2-2V10H0z" fill="#ccc" />
    <path d="M2 10.001v19a1 1 0 0 0 1 1h26a1 1 0 0 0 1-1v-19H2z" fill="#fff" />
    <path
      d="M19 21v-.999a1 1 0 1 0-2 0V21h-2.279l3.145-5.484a1.01 1.01 0 0 0-.366-1.375.996.996 0 0 0-1.366.368l-4 6.977c-.016.028-.016.059-.029.087-.021.044-.031.091-.046.138-.022.077-.042.15-.046.228-.002.021-.013.04-.013.062 0 .045.02.083.025.126.009.062.017.121.037.18.024.075.059.14.1.206a.952.952 0 0 0 .08.119.965.965 0 0 0 .194.172c.024.017.039.042.064.057.013.007.028.007.041.014a.963.963 0 0 0 .244.083c.046.01.09.024.137.028.027.002.05.016.078.016h4V25a1 1 0 1 0 2 0v-1.999A1 1 0 0 0 19 21z"
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#48a0dc"
    />
    <path
      d="M30 0H2a2 2 0 0 0-2 2.001V10h32V2.001A2 2 0 0 0 30 0zM12 6.001a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm8 0A1 1 0 1 1 20.001 4 1 1 0 0 1 20 6z"
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#ed7261"
    />
  </svg>
);

export default Calendar;
