import React from "react";

const Shuttle = props => (
  <svg viewBox="0 0 38 64"   {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M10 58H4v1.343a4 4 0 0 0 1.172 2.829L7 64l1.828-1.828A4 4 0 0 0 10 59.343V58zm-1 1v.343c0 .801-.312 1.555-.879 2.121L7 62.586l-1.121-1.122A2.978 2.978 0 0 1 5 59.343V59h4zm25-1h-6v1.343a4 4 0 0 0 1.172 2.829L31 64l1.828-1.828A4 4 0 0 0 34 59.343V58zm-1 1v.343c0 .801-.312 1.555-.879 2.121L31 62.586l-1.121-1.122A2.978 2.978 0 0 1 29 59.343V59h4z"
        fill="#1d7ed0"
      />
      <path
        d="M3 56v2c0 .55.45 1 1 1h6c.55 0 1-.45 1-1v-1h-1v1H4v-2H3zm24 1v1c0 .55.45 1 1 1h6c.55 0 1-.45 1-1v-2h-1v2h-6v-1h-1zM15.592 24.289a.5.5 0 0 1 .12-.697c1.077-.761 5.47-1.02 6.626.039a.5.5 0 0 1-.676.738c-.727-.668-4.567-.53-5.374.039a.5.5 0 0 1-.696-.119z"
        fill="#f2b430"
      />
      <path
        d="M18.999 1.012l6.65 10.984.032.052.037.048A6.023 6.023 0 0 1 27 15.803V42.24l1 2.077V15.803a6.998 6.998 0 0 0-1.495-4.325L19.787.382A.995.995 0 0 0 19 0a.993.993 0 0 0-.786.382L11.496 11A7 7 0 0 0 10 15.325v29.02l1-2.466V15.325c0-1.338.456-2.655 1.283-3.707l.03-.04.028-.043 6.658-10.523"
        fill="#1d7ed0"
      />
      <path
        d="M19 15l2.984 10.146A24.99 24.99 0 0 1 23 32.2v19.357l1 .374V32.2c0-2.483-.356-4.954-1.057-7.336l-2.984-10.146A.98.98 0 0 0 19 14a.98.98 0 0 0-.959.718l-2.984 10.146A25.988 25.988 0 0 0 14 32.2v19.628l1-.305V32.2c0-2.389.342-4.762 1.016-7.054l2.972-10.144L19 15"
        fill="#1d7ed0"
      />
      <path
        d="M31 14.401l2.16 3.24c.55.824.84 1.784.84 2.774v27.86l1 .624V20.415a5.998 5.998 0 0 0-1.008-3.328l-2.576-3.865A.496.496 0 0 0 31 13a.496.496 0 0 0-.416.222l-2.576 3.865A5.998 5.998 0 0 0 27 20.415v22.651l1 1.25v-23.9c0-.99.29-1.95.84-2.775l2.16-3.24m-24 0l2.16 3.24c.55.824.84 1.784.84 2.774v23.93L11 43V20.415a5.998 5.998 0 0 0-1.008-3.328l-2.576-3.865A.496.496 0 0 0 7 13a.496.496 0 0 0-.416.222l-2.576 3.865A5.998 5.998 0 0 0 3 20.415V49l1-.67V20.415c0-.99.29-1.95.84-2.774L7 14.401"
        fill="#f2b430"
      />
      <path
        d="M14 34.068v17.863l1-.409V27l-4.449 15.408A6.014 6.014 0 0 1 8.742 45l-6.265 3.77a4.998 4.998 0 0 0-1.749 3.133L0 56l12.428 1.657.036-1.004-11.297-1.506.551-3.103a3.995 3.995 0 0 1 1.344-2.46l6.195-3.727.066-.04.059-.049c.96-.8 1.69-1.846 2.111-3.023l.01-.029.009-.03L14 34.068m10 0l2.488 8.618.009.03.01.03a7.013 7.013 0 0 0 2.111 3.022l.059.05.066.04 6.195 3.726a4.02 4.02 0 0 1 1.349 2.494l.546 3.07-11.362 1.513-.032 1.014L38 56l-.728-4.097a4.998 4.998 0 0 0-1.749-3.134L29.258 45a6.014 6.014 0 0 1-1.809-2.592L23 27v24.557l1 .238V34.068M22 30h-6c0-1.1.9-2 2-2h2c1.1 0 2 .9 2 2"
        fill="#1d7ed0"
      />
      <path
        d="M15.5 51a3.51 3.51 0 0 0-3.5 3.5V59c0 .55.45 1 1 1h5c.55 0 1-.45 1-1v-4.5a3.51 3.51 0 0 0-3.5-3.5m0 1c1.378 0 2.5 1.122 2.5 2.5V59h-5v-4.5c0-1.378 1.122-2.5 2.5-2.5m7-1a3.51 3.51 0 0 0-3.5 3.5V59c0 .55.45 1 1 1h5c.55 0 1-.45 1-1v-4.5a3.51 3.51 0 0 0-3.5-3.5m0 1c1.378 0 2.5 1.122 2.5 2.5V59h-5v-4.5c0-1.378 1.122-2.5 2.5-2.5"
        fill="#f2b430"
      />
      <path
        d="M13.5 64a.501.501 0 0 1-.5-.5v-2c0-.275.225-.5.5-.5s.5.225.5.5v2c0 .275-.225.5-.5.5m2 0a.501.501 0 0 1-.5-.5v-2c0-.275.225-.5.5-.5s.5.225.5.5v2c0 .275-.225.5-.5.5m2 0a.501.501 0 0 1-.5-.5v-2c0-.275.225-.5.5-.5s.5.225.5.5v2c0 .275-.225.5-.5.5m3 0a.501.501 0 0 1-.5-.5v-2c0-.275.225-.5.5-.5s.5.225.5.5v2c0 .275-.225.5-.5.5m2 0a.501.501 0 0 1-.5-.5v-2c0-.275.225-.5.5-.5s.5.225.5.5v2c0 .275-.225.5-.5.5m2 0a.501.501 0 0 1-.5-.5v-2c0-.275.225-.5.5-.5s.5.225.5.5v2c0 .275-.225.5-.5.5"
        fill="#1d7ed0"
      />
    </g>
  </svg>
);

export default Shuttle;
