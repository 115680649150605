import React from "react";

const PostageCustoms = props => (
  <svg viewBox="0 0 48 48"   {...props}>
    <circle fill="#F16362" cx={18.995} cy={25.5} r={13} />
    <path
      fill="#384D68"
      d="M15.995 13.5c-5.523 0-10 4.478-10 10s4.477 10 10 10 10-4.478 10-10-4.476-10-10-10zm0 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16zm18.51-8.77c.663.601 2.307 2.781 5.367 2.781 3.035 0 5.055-2.719 5.366-3 .336-.281.767-.448.767-1 0-.553-.343-1-.767-1a.647.647 0 0 0-.435.177c-.925.638-2.338 2.823-4.932 2.823-3.061 0-4.747-2.55-5.367-3.188-.649-.668-2.307-2.813-5.366-2.813-.167 0-.318.035-.479.051a14.005 14.005 0 0 0-1.552-2.553c.589-.291 1.254-.498 2.03-.498 3.06 0 4.664 2.581 5.366 3.219.663.601 2.307 2.781 5.367 2.781 3.035 0 5.055-2.719 5.366-3 .336-.281.767-.448.767-1 0-.553-.343-1-.767-1a.647.647 0 0 0-.435.177c-.925.637-2.338 2.823-4.932 2.823-3.061 0-4.747-2.55-5.367-3.188-.649-.668-2.307-2.813-5.366-2.813-1.293 0-2.387.492-3.258 1.076A13.956 13.956 0 0 0 15.995 9.5c-7.732 0-14 6.268-14 14 0 7.731 6.268 14 14 14 5.556 0 10.341-3.244 12.603-7.935.179-.021.351-.054.541-.054 3.06 0 4.664 2.581 5.366 3.219.663.601 2.307 2.781 5.367 2.781 3.035 0 5.055-2.719 5.366-3 .336-.281.767-.448.767-1 0-.553-.343-1-.767-1a.647.647 0 0 0-.435.177c-.925.638-2.338 2.823-4.932 2.823-3.061 0-4.747-2.55-5.367-3.188-.63-.648-2.22-2.668-5.106-2.79.283-.941.471-1.924.552-2.937l-.011-.003c.028-.362.056-.726.056-1.095 0-.311-.026-.613-.047-.919l.013.003a14.093 14.093 0 0 0-.543-3.044c2.87.159 4.408 2.576 5.087 3.192zM15.995 35.5c-6.627 0-12-5.373-12-12 0-6.628 5.373-12 12-12s12 5.372 12 12c0 6.627-5.373 12-12 12zM29.95 24.598c2.529.403 3.919 2.556 4.555 3.133.663.601 2.307 2.781 5.367 2.781 3.035 0 5.055-2.719 5.366-3 .336-.281.767-.448.767-1 0-.553-.343-1-.767-1a.647.647 0 0 0-.435.177c-.925.637-2.338 2.823-4.932 2.823-3.061 0-4.747-2.55-5.367-3.188-.588-.604-2.014-2.401-4.544-2.74a13.21 13.21 0 0 1-.01 2.014z"
    />
  </svg>
);

export default PostageCustoms;
