import React from "react";

const Balloons = props => (
  <svg viewBox="0 0 28 31.999"   {...props}>
    <path
      d="M17.35 26.026c-.458-.112-.927.128-1.047.538l-1.301 4.454c-.12.41.155.833.613.946.458.112.928-.128 1.047-.538l1.302-4.454c.119-.409-.156-.833-.614-.946z"
      fill="#ccc"
    />
    <path
      d="M22.588 6.294c-3.917-1.1-7.787.724-9.151 6.056-1.365 5.333.499 11.353 4.416 12.451 3.917 1.1 8.404-3.138 9.769-8.471 1.365-5.332-1.116-8.937-5.034-10.036z"
      fill="#48a0dc"
    />
    <path
      d="M18.15 24.057c-1.418-.405-2.973 1.272-3.122 1.868s.231.986.609 1.095l3.3.944c.378.107.891-.027 1.041-.623.15-.596-.41-2.878-1.828-3.284z"
      fill="#387ba8"
    />
    <path
      d="M8.995 23.999a1 1 0 0 0-1 1v6a1 1 0 1 0 2 0v-6c-.001-.552-.449-1-1-1z"
      fill="#ccc"
    />
    <path
      d="M8.995 0C4.027 0 0 3.372 0 10c0 6.627 4.027 13 8.995 13s8.995-6.373 8.995-13c-.001-6.628-4.028-10-8.995-10z"
      fill="#ed7161"
    />
    <path
      d="M8.995 21.999c-1.718 0-2.998 2.307-2.998 3 0 .694.541 1.001 1 1.001h3.998c.458 0 .999-.307.999-1.001-.001-.693-1.282-3-2.999-3z"
      fill="#d46557"
    />
  </svg>
);

export default Balloons;
