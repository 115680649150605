import React from "react";

const KeepDry = props => (
  <svg viewBox="0 0 48 48"   {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#F16362"
      d="M44 7H7a1 1 0 0 0-1 1v34a1 1 0 0 0 1 1h37a1 1 0 0 0 1-1V8a1 1 0 0 0-1-1zm-6.857 14H14.857c-.473 0-.857-.398-.857-.889 0-.229.217-.604.217-.604C15.653 15.859 20.648 13 26 13s10.382 3.414 11.756 6.48c0 0 .244.311.244.631 0 .491-.384.889-.857.889z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#384D68"
      d="M45 4H3a1 1 0 0 0-1 1v38a1 1 0 0 0 1 1h42a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1zm-1 38H4V6h40v36zM11 19h12v15a4 4 0 0 0 8 0 1 1 0 1 0-2 0 2 2 0 0 1-4 0V19h12a1 1 0 0 0 1-1c0-.36-.285-.71-.285-.71C36.113 13.841 30.244 10 24 10s-12.071 3.217-13.746 7.321c0 0-.254.422-.254.679a1 1 0 0 0 1 1zm13-7c4.825 0 8.693 1.987 11.105 5H12.892c2.429-3.043 6.288-5 11.108-5z"
    />
  </svg>
);

export default KeepDry;
