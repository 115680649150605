import React from "react";

const Baseball = props => (
  <svg viewBox="0 0 128 128"   {...props}>
    <path
      fill="#B8CBCD"
      d="M115.846 110.679c0 5.625-21.343 9.813-47.668 9.813-26.326 0-47.668-4.188-47.668-9.813 0-5.626 21.341-10.188 47.667-10.188 26.325 0 47.669 4.562 47.669 10.188z"
    />
    <circle fill="#FFF" cx={36.678} cy={90.166} r={17.621} />
    <path
      fill="#783741"
      d="M36.678 110.788a20.488 20.488 0 0 1-14.582-6.04c-8.04-8.04-8.04-21.123 0-29.163a20.485 20.485 0 0 1 14.581-6.04 20.49 20.49 0 0 1 14.581 6.04c8.041 8.04 8.041 21.123 0 29.163a20.481 20.481 0 0 1-14.58 6.04zm0-35.243a14.53 14.53 0 0 0-10.339 4.282c-5.701 5.701-5.701 14.978 0 20.679a14.525 14.525 0 0 0 10.339 4.282c3.905 0 7.577-1.521 10.338-4.282 5.701-5.701 5.701-14.977 0-20.679a14.526 14.526 0 0 0-10.338-4.282z"
    />
    <path
      fill="#783741"
      d="M23.797 92.124c-1.773 0-3.559-.3-5.277-.918a2 2 0 0 1-1.323-1.815l-.007-.174a2 2 0 0 1 3.324-1.565c4.065 1.201 8.526.089 11.534-2.918 3.221-3.222 4.155-6.915 2.778-10.978a2 2 0 0 1 3.788-1.285c1.88 5.547.588 10.765-3.738 15.091-2.968 2.967-6.99 4.562-11.079 4.562zm14.198 17.302c-.88 0-1.687-.586-1.929-1.477-1.472-5.41.069-11.226 4.021-15.177 4.228-4.229 9.332-5.562 14.767-3.85a1.999 1.999 0 0 1 1.307 2.508 1.995 1.995 0 0 1-2.508 1.307c-4.031-1.269-7.543-.333-10.737 2.863-2.94 2.939-4.086 7.27-2.99 11.3a2 2 0 0 1-1.931 2.526z"
    />
    <path
      fill="#F7BB83"
      d="M23.792 9.172c-1.913.477-3.667 1.341-5.1 2.747-2.435 2.381-3.354 5.698-3.244 9.457.129 4.374 2.223 8.453 5.568 11.276l79.482 70.427-2.996 2.997c-2.197 2.194-2.197 5.787 0 7.984 1.859 1.859 9.326-2.593 14.502-7.769 5.176-5.175 9.6-12.614 7.74-14.474-2.197-2.197-5.79-2.197-7.987 0l-1.984 1.985-70.781-79.41c-3.722-4.402-9.612-6.627-15.2-5.22z"
    />
    <path
      fill="#6E4123"
      d="M98.777 117.491c-1.725 0-2.799-.712-3.396-1.31a8.584 8.584 0 0 1-2.526-6.114c0-2.314.898-4.486 2.527-6.114l.742-.741-77.097-68.315c-4.008-3.382-6.425-8.295-6.577-13.433-.145-4.927 1.25-8.86 4.145-11.691 1.718-1.686 3.897-2.869 6.471-3.512 1.34-.338 2.731-.51 4.127-.51 5.274 0 10.541 2.506 14.09 6.702l68.62 76.987a8.584 8.584 0 0 1 5.848-2.271c2.315 0 4.486.897 6.115 2.527 2.947 2.946.18 7.933-.73 9.57-1.693 3.052-4.249 6.386-7.01 9.146-3.649 3.65-10.748 9.079-15.349 9.079zm-.004-6l.004 3v-3h-.004zm-71.58-99.739c-.903 0-1.8.11-2.667.329-1.55.387-2.769 1.034-3.732 1.979-1.652 1.615-2.44 4.046-2.347 7.229.102 3.434 1.744 6.741 4.505 9.071l79.537 70.475a3.003 3.003 0 0 1 1.01 2.154 3.007 3.007 0 0 1-.877 2.212l-2.996 2.997a2.62 2.62 0 0 0-.77 1.87c0 .49.129.958.372 1.364 1.917-.437 6.621-3.227 10.656-7.261 4.178-4.177 6.817-8.837 7.236-10.625-.995-.599-2.401-.445-3.241.394l-1.983 1.985a2.998 2.998 0 0 1-4.361-.125L36.752 16.388c-2.474-2.925-6.028-4.636-9.559-4.636z"
    />
    <path
      fill="#FFF"
      d="M18.531 32.388a3.003 3.003 0 0 1-2.875-2.147c-2.001-6.732-.012-12.506 5.914-17.161 1.887-1.491 4.181-2.434 6.811-2.795 6.292-.876 12.589 1.817 17.732 7.587a3 3 0 1 1-4.48 3.992c-2.181-2.447-6.7-6.437-12.43-5.636-1.582.217-2.863.729-3.921 1.565-2.837 2.229-5.51 5.243-3.876 10.739a3.002 3.002 0 0 1-2.875 3.856zm46.104 38.913c1.436 1.435 5.76-.563 9.259-4.062 3.5-3.499 5.438-7.765 4.003-9.2l7.832 8.899c1.436 1.436-.237 4.899-3.736 8.398-3.499 3.499-6.966 5.173-8.401 3.737l-8.957-7.772z"
    />
    <path
      fill="#6E4123"
      d="M74.969 81.588c-1.076 0-2.02-.359-2.732-1.041l-8.724-7.571a3.278 3.278 0 0 1-.292-.261 2 2 0 1 1 2.7-2.946l.065.002c1.058 0 3.772-1.225 6.494-3.945 3.022-3.023 3.981-5.77 3.896-6.488a2 2 0 0 1 2.935-2.712c.085.084.164.173.239.265l7.646 8.689c.757.79 1.74 2.531.334 5.614-.786 1.724-2.212 3.646-4.124 5.558-1.806 1.806-5.305 4.836-8.437 4.836zm-.043-4.003l.043.003c.936 0 3.091-1.146 5.609-3.665 2.947-2.948 3.737-5.052 3.667-5.642l-.018-.021-4.739-5.385c-.923 2.004-2.47 4.068-4.181 5.778-1.862 1.862-3.923 3.33-5.824 4.207l5.418 4.703a4.1 4.1 0 0 0 .025.022z"
    />
    <path
      fill="#FFF"
      d="M81.881 86.582c1.436 1.436 4.777.42 8.276-3.079s4.497-6.823 3.062-8.259l7.883 8.849c1.436 1.434.652 4.009-2.847 7.508-3.499 3.499-6.067 4.274-7.503 2.839l-8.871-7.858z"
    />
    <path
      fill="#6E4123"
      d="M92.429 97.122a4.281 4.281 0 0 1-3.042-1.22l-8.614-7.63a4.43 4.43 0 0 1-.306-.276 2 2 0 0 1 2.74-2.911l.1.089a.785.785 0 0 0 .277.038c.939 0 2.854-.818 5.159-3.123 3-3 3.268-5.064 3.073-5.412l-.091-.103a1.999 1.999 0 0 1 2.907-2.744c.094.094.182.19.265.292l7.665 8.604c.745.769 1.79 2.407.848 5.024-.568 1.58-1.827 3.351-3.741 5.265-2.764 2.763-5.132 4.107-7.24 4.107zm-5.517-8.755l5.166 4.575c.03.026.06.055.088.083.061.06.104.097.263.097.246 0 1.618-.142 4.412-2.936 1.861-1.861 2.554-3.089 2.806-3.792.163-.452.178-.751.041-.887a1.589 1.589 0 0 1-.079-.085l-4.605-5.169c-.713 1.509-1.868 3.101-3.432 4.663-1.563 1.564-3.148 2.73-4.66 3.451z"
    />
    <path
      fill="#6E4123"
      d="M98.777 117.491c-1.725 0-2.799-.712-3.396-1.31a8.584 8.584 0 0 1-2.526-6.114c0-2.314.898-4.486 2.527-6.114l.742-.741-77.097-68.315c-4.008-3.382-6.425-8.295-6.577-13.433-.145-4.927 1.25-8.86 4.145-11.691 1.718-1.686 3.897-2.869 6.471-3.512 1.34-.338 2.731-.51 4.127-.51 5.274 0 10.541 2.506 14.09 6.702l68.62 76.987a8.584 8.584 0 0 1 5.848-2.271c2.315 0 4.486.897 6.115 2.527 2.947 2.946.18 7.933-.73 9.57-1.693 3.052-4.249 6.386-7.01 9.146-3.649 3.65-10.748 9.079-15.349 9.079zm-.004-6l.004 3v-3h-.004zm-71.58-99.739c-.903 0-1.8.11-2.667.329-1.55.387-2.769 1.034-3.732 1.979-1.652 1.615-2.44 4.046-2.347 7.229.102 3.434 1.744 6.741 4.505 9.071l79.537 70.475a3.003 3.003 0 0 1 1.01 2.154 3.007 3.007 0 0 1-.877 2.212l-2.996 2.997a2.62 2.62 0 0 0-.77 1.87c0 .49.129.958.372 1.364 1.917-.437 6.621-3.227 10.656-7.261 4.178-4.177 6.817-8.837 7.236-10.625-.995-.599-2.401-.445-3.241.394l-1.983 1.985a2.998 2.998 0 0 1-4.361-.125L36.752 16.388c-2.474-2.925-6.028-4.636-9.559-4.636z"
    />
  </svg>
);

export default Baseball;
