import React from "react";

const TestingMouse = props => (
  <svg viewBox="0 0 1009.995 440.914"   {...props}>
    <path
      fill="#AEBCC4"
      d="M808.138 312.778c2.684-50.79-33.43-171.456-115.032-234.257-247.725-193.604-526.709 133.521-525.5 109.467 1.193-24.087-9.127-58.592-62.577-51.718-48.579 6.261-37.231 69.725-25.23 104.663 12.052 34.82-39.666 94.507-39.666 94.507-58.933 73.37 13.227 88.511 13.227 88.511h573.6c109.483 0 156.811-59.686 156.811-59.686 2.236-5.202 19.614-38.566 24.368-51.487"
    />
    <path fill="#43525B" d="M155.538 279.199h35.111v33.927h-35.111z" />
    <path
      fill="#43525B"
      d="M999.837 240.518c-27.367-37.803-108.356-67.224-163.47-59.488-9.277 1.309-15.737 9.89-14.429 19.167 1.309 9.277 9.857 15.804 19.166 14.429 45.938-6.478 112.797 20.31 131.268 45.788 5.101 7.057 3.611 10.071 3.048 11.215-2.486 5.02-21.404 29.354-150.285 35.268.082-62.834-41.215-179.887-121.576-241.744C648.23 21.916 586.737 0 520.79 0 373.22 0 241.589 109.102 185.38 155.685a708.089 708.089 0 0 1-4.58 3.794c-2.55-7.654-6.676-15.473-13.22-22.332-11.82-12.423-28.451-18.735-49.431-18.735-4.73 0-9.808.331-15.291 1.027-17.709 2.287-31.64 10.851-40.28 24.75-19.73 31.772-5.748 82.1 1.193 102.292 6.005 17.362-18.206 56.722-36.867 78.34-23.821 29.653-31.88 55.943-23.954 78.124 10.445 29.173 43.22 36.827 46.922 37.605 1.152.248 2.32.364 3.487.364H626.96c116.341 0 167.96-63.414 170.097-66.114a16.773 16.773 0 0 0 2.303-3.843c.645-1.508 2.7-5.6 5.334-10.884a1781.76 1781.76 0 0 0 9.193-18.736c112.864-3.926 175.78-21.816 191.965-54.716 7.14-14.512 5.003-30.895-6.014-46.102zm-230.462 114.47c-7.604 8.48-51.056 52-142.416 52H55.556c-4.316-1.276-17.303-5.882-20.691-15.572-3.512-10.04 3.056-26.14 18.09-44.86 9.707-11.215 57.392-69.229 42.888-111.14-10.817-31.475-12.556-60.25-4.44-73.32 2.187-3.529 5.964-7.737 15.788-8.995 17.046-2.253 29.098.38 35.815 7.421 7.703 8.101 7.919 21.32 7.662 26.622-.24 4.637 1.706 10.204 4.92 13.567 3.197 3.362 8.308 5.515 12.954 5.515h.026c9.028-.015 15.256-5.184 38.457-24.416 53.391-44.248 178.43-147.883 313.764-147.883 58.195 0 112.664 19.498 161.964 58.046 75.44 58.047 109.698 168.772 108.538 217.31-3.628 8.88-11.86 25.428-16.963 35.665a865.72 865.72 0 0 0-4.953 10.04z"
    />
  </svg>
);

export default TestingMouse;
