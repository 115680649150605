import React from "react";

const PackageTracking2 = props => (
  <svg viewBox="0 0 48 48"   {...props}>
    <circle fill="#F16362" cx={27} cy={19} r={10} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#384D68"
      d="M28.442 13.114l.005-.009-4-2-.005.009C24.308 11.047 24.161 11 24 11s-.308.047-.442.114l-.005-.009-4 2 .004.009A.994.994 0 0 0 19 14v5c0 .391.23.721.557.886l-.004.009 4 2 .005-.009c.134.067.281.114.442.114s.308-.047.442-.114l.005.009 4-2-.005-.009A.993.993 0 0 0 29 19v-5a.993.993 0 0 0-.558-.886zM23 19.382l-2-1v-2.764l2 1v2.764zm1-4.5L22.236 14 24 13.118l1.764.882-1.764.882zm3 3.5l-2 1v-2.764l2-1v2.764zM24 2C15.716 2 9 8.716 9 17c0 13.891 14.626 29 15 29 .174 0 15-14.966 15-29 0-8.284-6.716-15-15-15zm0 41c-.324 0-13-14.05-13-26.046C11 9.8 16.82 4 24 4s13 5.8 13 12.954C37 29.074 24.15 43 24 43zm0-37c-6.075 0-11 4.925-11 11s4.925 11 11 11 11-4.925 11-11S30.075 6 24 6zm0 20c-4.971 0-9-4.029-9-9s4.029-9 9-9 9 4.029 9 9-4.029 9-9 9z"
    />
  </svg>
);

export default PackageTracking2;
