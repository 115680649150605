import React from "react";

const Alien = props => (
  <svg viewBox="0 0 54 64"   {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M27.003 0C12.113 0 0 11.932 0 26.6 0 40.581 16.908 64 27.003 64 37.096 64 54 40.581 54 26.6 54 11.932 41.889 0 27.003 0m0 1C41.338 1 53 12.484 53 26.6 53 39.867 36.479 63 27.003 63 17.525 63 1 39.867 1 26.6 1 12.484 12.665 1 27.003 1"
        fill="#1d7ed0"
      />
      <path
        d="M34.889 41c-2.92 0-5.281-.747-5.381-.779a.736.736 0 0 1-.505-.769c.028-.305.753-7.495 5.562-10.7C36.309 27.59 38.514 27 41.119 27c2.917 0 5.275.746 5.374.778a.736.736 0 0 1 .504.769c-.028.304-.75 7.497-5.56 10.701C39.697 40.41 37.495 41 34.889 41m-22.326-1.752c-4.81-3.204-5.532-10.396-5.56-10.701a.737.737 0 0 1 .505-.77c.099-.03 2.457-.777 5.373-.777 2.605 0 4.81.59 6.554 1.753 4.809 3.205 5.534 10.394 5.562 10.7a.735.735 0 0 1-.504.769c-.1.03-2.462.778-5.382.778-2.606 0-4.808-.59-6.548-1.752"
        fill="#1d7ed0"
      />
      <path
        d="M29.5 54h-5a.501.501 0 0 1-.5-.5c0-.275.225-.5.5-.5h5c.275 0 .5.225.5.5s-.225.5-.5.5"
        fill="#f2b430"
      />
    </g>
  </svg>
);

export default Alien;
