import React from "react";

const Cupcake = props => (
  <svg viewBox="0 0 28 32.001"   {...props}>
    <ellipse cx={14} cy={16.001} rx={12} ry={5} fill="#f1f1f1" />
    <path
      d="M22 16.001c-4 0-4 1-8 1s-4-1-8-1-6 1-6 3 1.024 2 1.024 2l.976 1h24l1-1s1 0 1-2-2-3-6-3z"
      fill="#fc6"
    />
    <path
      d="M26.812 20.416a.945.945 0 0 0-.812-.415c-2 0-2 2-4 2s-2-2-4-2-2 2-4 2-2-2-4.001-2c-2 0-2 2-3.999 2-2 0-2-2-4-2a1 1 0 0 0-.949 1.316l4 10a1 1 0 0 0 .949.684h16a.997.997 0 0 0 .948-.684l4-10a.999.999 0 0 0-.136-.901z"
      fill="#48a0dc"
    />
    <path
      d="M13 21.77v10.231h2V21.77a2.2 2.2 0 0 1-1 .231 2.22 2.22 0 0 1-1-.231zm-9.91-1.499L7 32.001h2L5.651 21.956c-1.241-.174-1.642-1.188-2.561-1.685zm6.91-.27c-.906 0-1.399.413-1.856.862L10 32.001h2l-2-12zm12.349 1.955L19 32.001h2l3.91-11.729c-.919.496-1.32 1.51-2.561 1.684zM18 20.001l-2 12h2l1.856-11.138c-.457-.449-.95-.862-1.856-.862z"
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#408ec2"
    />
    <path d="M15 6.001h-2a1 1 0 0 0-1 1v4h4v-4a1 1 0 0 0-1-1z" fill="#f06eaa" />
    <path
      d="M14 0c0 3.02-2 2.476-2 4.2 0 .994.896 1.801 2 1.801s2-.807 2-1.801-.167-2.55-2-4.2z"
      fill="#fc6"
    />
  </svg>
);

export default Cupcake;
