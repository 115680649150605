import React from "react";

const Satellite2 = props => (
  <svg viewBox="0 0 64 64"   {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M26.671 48.869l-1.5 3.9a1 1 0 0 1-1.64.348L10.883 40.469a.98.98 0 0 1-.27-.914.982.982 0 0 1 .618-.726l3.884-1.494-.865-.74-3.378 1.3a2 2 0 0 0-.696 3.282l12.647 12.647c.4.399.907.587 1.41.587.784 0 1.554-.46 1.871-1.283l1.337-3.476-.77-.783zM6.147 3.853l.707-.707-1-1a.5.5 0 0 0-.708.708l1 1z"
        fill="#f2b430"
      />
      <path
        d="M10 0L0 10l5 5L15 5l-5-5zm0 1.414L13.586 5 5 13.586 1.414 10 10 1.414zM16 6L6 16l5 5 10-10-5-5zm0 1.414L19.586 11 11 19.586 7.414 16 16 7.414zM22 12L12 22l5 5 10-10-5-5zm0 1.414L25.586 17 17 25.586 13.414 22 22 13.414z"
        fill="#1d7ed0"
      />
      <path
        d="M12.854 9.146l-1-1-.707.707 1 1 .707-.707zm6 6l-1-1-.707.707 1 1 .707-.707zM26 22.294l-2.146-2.146-.708.707L25.293 23l.707-.707zm-3 3l-2.146-2.146-.708.707L22.293 26l.707-.707zm-8.854-7.44l1 1 .707-.707-1-1-.707.707zm-11-11l.708-.707-1-1a.5.5 0 0 0-.708.708l1 1zm5 5l1 1 .707-.707-1-1-.707.707zm49.707 48.294l-.706.706 1 1a.5.5 0 0 0 .706-.706l-1-1zM34 14.293L49.708 30l-.061 1.353-17-17 1.353-.06z"
        fill="#f2b430"
      />
      <path
        d="M59 49L49 59l5 5 10-10-5-5zm0 1.414L62.586 54 54 62.586 50.414 59 59 50.414zM53 43L43 53l5 5 10-10-5-5zm0 1.414L56.586 48 48 56.586 44.414 53 53 44.414zM47 37L37 47l5 5 10-10-5-5zm0 1.414L50.586 42 42 50.586 38.414 47 47 38.414z"
        fill="#1d7ed0"
      />
      <path
        d="M51.147 54.853l1 1 .706-.706-1-1-.706.706zm-6-6l1 1 .706-.706-1-1-.706.706zM38 41.707l2.146 2.146.708-.707L38.707 41l-.707.707zm3-3l2.146 2.146.708-.707L41.707 38l-.707.707zm8.853 7.44l-1-1-.706.706 1 1 .706-.706zm11 11l-.706.706 1 1a.5.5 0 0 0 .706-.706l-1-1zm-5-5l-1-1-.706.706 1 1 .706-.706z"
        fill="#f2b430"
      />
      <path
        d="M28.293 49.879L14.12 35.707a1 1 0 0 1 0-1.414l.586-.586L14 33l-.586.586a2 2 0 0 0 0 2.829l14.172 14.17a2 2 0 0 0 2.828 0L31 50l-.707-.707-.586.586a.999.999 0 0 1-1.414 0"
        fill="#1d7ed0"
      />
      <path
        d="M47 12l-14 2-19 19 17 17 19-19 2-14-5-5zm-.354 1.061l4.293 4.293-1.882 13.175L31 48.586 15.414 33l18.057-18.057 13.175-1.882z"
        fill="#1d7ed0"
      />
      <path
        d="M32 25a7 7 0 1 0 0 14 7 7 0 0 0 0-14m0 1c3.308 0 6 2.692 6 6s-2.692 6-6 6-6-2.692-6-6 2.692-6 6-6"
        fill="#1d7ed0"
      />
      <path
        d="M36.5 32a.5.5 0 0 1-.5-.5c0-1.701-1.799-3.5-3.5-3.5a.5.5 0 0 1 0-1c2.271 0 4.5 2.229 4.5 4.5a.5.5 0 0 1-.5.5"
        fill="#f2b430"
      />
    </g>
  </svg>
);

export default Satellite2;
