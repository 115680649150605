import React from "react";

const Tennis = props => (
  <svg viewBox="0 0 128 128"   {...props}>
    <path
      fill="#B8CBCD"
      d="M109.551 111.647c0 5.626-21.343 10.187-47.668 10.187-26.326 0-47.667-4.561-47.667-10.187 0-5.626 21.009-9.813 47.335-9.813 26.325 0 48 4.187 48 9.813z"
    />
    <path
      fill="#3C663E"
      d="M77.103 78.321c-8.92 0-17.065-3.233-22.936-9.104-6.683-6.682-9.862-16.022-8.953-26.299.891-10.077 5.63-19.875 13.345-27.589C67.135 6.752 78.417 1.834 89.513 1.834c8.92 0 17.065 3.233 22.935 9.103 6.683 6.682 9.862 16.022 8.953 26.299-.891 10.077-5.631 19.875-13.345 27.59-8.575 8.576-19.858 13.495-30.953 13.495zm12.41-70.487c-9.516 0-19.252 4.278-26.712 11.738-6.72 6.72-10.843 15.199-11.61 23.875-.75 8.478 1.814 16.123 7.219 21.528 4.737 4.737 11.375 7.346 18.693 7.346 9.516 0 19.252-4.278 26.711-11.737 6.72-6.72 10.843-15.2 11.61-23.876.75-8.477-1.813-16.123-7.219-21.528-4.736-4.738-11.375-7.346-18.692-7.346z"
    />
    <path
      fill="#B2EDA6"
      d="M15.246 116.624c-2.522 2.521-6.271 2.862-8.372.761-2.102-2.102-2.307-5.638.215-8.16l19.092-19.092c2.522-2.522 6.752-3.01 8.854-.909 2.102 2.103 1.761 5.85-.761 8.373l-19.028 19.027z"
    />
    <path
      fill="#3C663E"
      d="M10.366 121.763c-2.165 0-4.158-.801-5.613-2.257-3.417-3.416-3.323-8.864.214-12.402L24.06 88.012c1.945-1.945 4.725-3.106 7.435-3.106 2.233 0 4.244.78 5.661 2.197 3.269 3.269 2.927 8.928-.761 12.615l-19.027 19.027c-1.918 1.918-4.47 3.018-7.002 3.018zm21.129-30.858c-1.137 0-2.361.517-3.193 1.349L9.21 111.346c-1.356 1.357-1.134 2.998-.215 3.918.413.412.973.499 1.371.499.938 0 1.97-.471 2.759-1.26l19.027-19.027c1.302-1.303 1.658-3.233.761-4.13-.279-.28-.796-.441-1.418-.441z"
    />
    <path
      fill="#3C663E"
      d="M27.751 91.79a2.998 2.998 0 0 1-2.121-5.121c18.205-18.205 21.937-28.385 21.237-30.033-.643-1.512.037-3.292 1.542-3.952 1.504-.66 3.247-.018 3.924 1.479 4.017 8.878-14.37 28.657-22.461 36.748a2.988 2.988 0 0 1-2.121.879zm7.665 7.665a3.001 3.001 0 0 1-2.121-5.122c10.13-10.13 28.344-26.574 36.537-22.871a3 3 0 0 1 1.497 3.97 2.997 2.997 0 0 1-3.97 1.497c-1.911-.759-11.723 3.548-29.822 21.647a2.991 2.991 0 0 1-2.121.879z"
    />
    <circle fill="#B2EDA6" cx={85.051} cy={100.334} r={15.5} />
    <path
      fill="#3C663E"
      d="M85.051 118.834a18.375 18.375 0 0 1-13.081-5.419c-3.495-3.494-5.419-8.139-5.419-13.081s1.924-9.587 5.419-13.081a18.38 18.38 0 0 1 13.081-5.419c4.941 0 9.587 1.924 13.081 5.419 3.495 3.494 5.419 8.139 5.419 13.081s-1.924 9.587-5.419 13.082a18.379 18.379 0 0 1-13.081 5.418zm0-31c-3.339 0-6.478 1.3-8.839 3.661-2.361 2.361-3.661 5.5-3.661 8.839s1.3 6.478 3.661 8.838a12.419 12.419 0 0 0 8.839 3.662c3.339 0 6.477-1.3 8.839-3.661 2.361-2.361 3.661-5.5 3.661-8.839s-1.3-6.478-3.661-8.838a12.417 12.417 0 0 0-8.839-3.662z"
    />
    <path
      fill="#3C663E"
      d="M73.721 102.296c-1.587 0-3.186-.268-4.724-.821a2 2 0 0 1-1.322-1.806l-.007-.192a2 2 0 0 1 1.962-2.038c.498-.028.966.167 1.322.466 3.477 1.006 7.285.049 9.856-2.521 2.706-2.706 3.62-6.648 2.386-10.287a2 2 0 1 1 3.787-1.286c1.728 5.093.446 10.611-3.345 14.401-2.655 2.657-6.256 4.084-9.915 4.084zm12.488 15.219a2 2 0 0 1-1.929-1.476c-1.317-4.841.062-10.046 3.599-13.583 3.7-3.7 9.106-5.021 14.112-3.446a2 2 0 1 1-1.201 3.816 9.978 9.978 0 0 0-10.083 2.46 10.028 10.028 0 0 0-2.567 9.705 2 2 0 0 1-1.931 2.524zM57.294 68.091a2 2 0 0 1-1.414-3.414l52.326-52.326a2 2 0 1 1 2.828 2.828L58.708 67.505c-.39.391-.903.586-1.414.586zm-6.364-9.192a2 2 0 0 1-1.414-3.414L97.599 7.401a2 2 0 1 1 2.828 2.828L52.344 58.313a1.99 1.99 0 0 1-1.414.586zm15.556 15.556a2 2 0 0 1-1.414-3.414l48.083-48.083a2 2 0 1 1 2.828 2.828L67.901 73.869a1.994 1.994 0 0 1-1.415.586zM47.394 46.878a2 2 0 0 1-1.414-3.414L85.578 3.866a2 2 0 1 1 2.828 2.828L48.808 46.292a1.99 1.99 0 0 1-1.414.586zm31.113 31.113a2 2 0 0 1-1.414-3.414l39.598-39.598a2 2 0 1 1 2.828 2.828L79.921 77.405c-.391.39-.902.586-1.414.586z"
    />
    <path
      fill="#3C663E"
      d="M105.377 63.849a1.992 1.992 0 0 1-1.414-.586L60.122 19.422a2 2 0 1 1 2.828-2.828l43.841 43.841a2 2 0 0 1-1.414 3.414zm7.778-7.779a1.992 1.992 0 0 1-1.414-.586L67.9 11.644a2 2 0 1 1 2.828-2.828l43.841 43.84a2 2 0 0 1-1.414 3.414zM97.599 71.627a1.992 1.992 0 0 1-1.414-.586L52.344 27.2a2 2 0 1 1 2.828-2.828l43.841 43.841a2 2 0 0 1-1.414 3.414zm19.799-26.87a1.992 1.992 0 0 1-1.414-.586l-36.77-36.77a2 2 0 1 1 2.828-2.828l36.77 36.77a2 2 0 0 1-1.414 3.414zM86.285 75.869a1.992 1.992 0 0 1-1.414-.586l-36.77-36.77a2 2 0 1 1 2.828-2.828l36.77 36.77a2 2 0 0 1-1.414 3.414z"
    />
  </svg>
);

export default Tennis;
