import React from "react";

const SailingShip = props => (
  <svg viewBox="0 0 128 128"   {...props}>
    <path
      fill="#B8CBCD"
      d="M112.179 115.012c0 5.625-21.01 9.813-47.335 9.813-26.326 0-48-4.188-48-9.813 0-5.626 21.341-10.188 47.667-10.188 26.325.001 47.668 4.562 47.668 10.188z"
    />
    <path
      fill="#B2EDA6"
      d="M120.844 97.576c0 10.218-28.886 21-56.5 21s-57.5-10.782-57.5-21"
    />
    <path
      fill="#3C663E"
      d="M64.344 120.576c-26.783 0-59.5-10.613-59.5-23a2 2 0 0 1 4 0c0 8.12 27.439 19 55.5 19 27.555 0 54.5-10.88 54.5-19a2 2 0 0 1 4 0c0 12.74-32.003 23-58.5 23z"
    />
    <path
      fill="#B2EDA6"
      d="M120.844 97.576s-29.662 11.25-57 11.25-57-11.25-57-11.25 29.662-11.25 57-11.25 57 11.25 57 11.25z"
    />
    <path
      fill="#3C663E"
      d="M63.844 110.826c-27.39 0-56.484-10.915-57.709-11.38a2 2 0 0 1 0-3.74c1.225-.465 30.319-11.38 57.709-11.38 27.39 0 56.484 10.915 57.709 11.38a2 2 0 0 1 0 3.74c-1.224.464-30.319 11.38-57.709 11.38zm-50.855-13.25c9.754 3.169 31.055 9.25 50.856 9.25 19.801 0 41.102-6.081 50.855-9.25-9.754-3.169-31.055-9.25-50.855-9.25-19.802 0-41.102 6.081-50.856 9.25z"
    />
    <path
      fill="#3C663E"
      d="M63.844 86.575c27.338 0 57 11.25 57 11.25 0 10.217-28.886 21-56.5 21s-57.5-10.783-57.5-21c0 0 29.662-11.25 57-11.25m0-4c-27.752 0-57.18 11.04-58.419 11.51a4 4 0 0 0-2.581 3.74c0 15.261 36.425 25 61.5 25 13.606 0 28.186-2.522 40.001-6.92 9.351-3.481 20.499-9.443 20.499-18.08a4 4 0 0 0-2.581-3.74c-1.239-.47-30.667-11.51-58.419-11.51z"
    />
    <ellipse fill="#FFF" cx={63.844} cy={97.576} rx={23.1} ry={5.25} />
    <path
      fill="#3C663E"
      d="M63.844 104.826c-2.569 0-25.1-.203-25.1-7.25s22.53-7.25 25.1-7.25c2.569 0 25.1.203 25.1 7.25 0 7.046-22.53 7.25-25.1 7.25zm-20.847-7.25c1.701 1.259 8.8 3.25 20.848 3.25 12.048 0 19.146-1.991 20.848-3.25-1.701-1.259-8.8-3.25-20.848-3.25-12.049 0-19.147 1.991-20.848 3.25z"
    />
    <path
      fill="#3C663E"
      d="M71.844 98.825a3 3 0 0 1-3-3v-89a3 3 0 1 1 6 0v89a3 3 0 0 1-3 3z"
    />
    <path
      fill="#B2EDA6"
      d="M71.844 13.825l-67 83s26-23 42-23c18 0 25 6 25 6v-66z"
    />
    <path
      fill="#3C663E"
      d="M4.844 99.825A3.001 3.001 0 0 1 2.51 94.94l67-83a3.002 3.002 0 0 1 5.334 1.885v66a3.001 3.001 0 0 1-4.952 2.278c-.039-.031-6.639-5.277-23.048-5.277-12.193 0-33.018 16.06-40.013 22.247a2.993 2.993 0 0 1-1.987.752zm64-77.508L22.01 80.335c8.088-5.055 17.342-9.511 24.834-9.511 10.662 0 17.771 2.054 22 3.892V22.317z"
    />
    <path
      fill="#B2EDA6"
      d="M71.844 30.825l33 59s-16-15-24-15c-3.5 0-9 2-9 2v-46z"
    />
    <path
      fill="#3C663E"
      d="M104.844 92.825a2.998 2.998 0 0 1-2.052-.81c-5.855-5.486-16.992-14.19-21.948-14.19-2.426 0-6.677 1.348-7.975 1.819a3 3 0 0 1-4.025-2.819v-46a3 3 0 0 1 5.618-1.464l33 59a2.999 2.999 0 0 1-2.618 4.464zm-24-21c3.959 0 9.097 2.7 13.795 5.901L74.844 42.333v30.432c1.89-.492 4.126-.94 6-.94z"
    />
    <path
      fill="#3C663E"
      d="M73.472 28.825a3.001 3.001 0 0 1-3-3V12.752a3 3 0 0 1 3.959-2.842l19.372 6.536a3 3 0 0 1 0 5.686l-19.372 6.536a3.01 3.01 0 0 1-.959.157zm3-11.894v4.715l6.988-2.357-6.988-2.358z"
    />
    <path
      fill="#FFF"
      d="M20.842 85.825a3 3 0 0 1-2.322-4.898l49-60a3 3 0 1 1 4.647 3.795l-49 60a2.99 2.99 0 0 1-2.325 1.103z"
    />
    <path
      fill="#3C663E"
      d="M4.844 99.825A3.001 3.001 0 0 1 2.51 94.94l67-83a3.002 3.002 0 0 1 5.334 1.885v66a3.001 3.001 0 0 1-4.952 2.278c-.039-.031-6.639-5.277-23.048-5.277-12.193 0-33.018 16.06-40.013 22.247a2.993 2.993 0 0 1-1.987.752zm64-77.508L22.01 80.335c8.088-5.055 17.342-9.511 24.834-9.511 10.662 0 17.771 2.054 22 3.892V22.317z"
    />
    <path
      fill="#FFF"
      d="M74.844 77.825a3 3 0 0 1-3-3v-37a3 3 0 1 1 6 0v37a3 3 0 0 1-3 3z"
    />
    <path
      fill="#3C663E"
      d="M104.844 92.825a2.998 2.998 0 0 1-2.052-.81c-5.855-5.486-16.992-14.19-21.948-14.19-2.426 0-6.677 1.348-7.975 1.819a3 3 0 0 1-4.025-2.819v-46a3 3 0 0 1 5.618-1.464l33 59a2.999 2.999 0 0 1-2.618 4.464zm-24-21c3.959 0 9.097 2.7 13.795 5.901L74.844 42.333v30.432c1.89-.492 4.126-.94 6-.94z"
    />
  </svg>
);

export default SailingShip;
