import React from "react";

const Boxing = props => (
  <svg viewBox="0 0 48 48"   {...props}>
    <path
      fill="#FFDA8E"
      d="M45.438 17.096l.003-.007-19-9-.003.007c-.13-.063-.272-.104-.427-.104s-.297.041-.427.104l-.003-.007-19 9 .003.007a.996.996 0 0 0-.573.896v17c0 .397.237.735.573.896l-.003.007 20 10 .003-.007c.13.063.272.104.427.104s.297-.041.427-.104l.003.007 18-9.001-.003-.006a.994.994 0 0 0 .573-.896v-18a.994.994 0 0 0-.573-.896z"
    />
    <path
      fill="#384D68"
      d="M3.713 10.673L6.57 7.521c4.736-4.735 9.707-4.721 14.428 0l2.611 2.474-4.598-.002c-.534-.001-1.001.475-1 1s.466.999 1 1l7.021.003c.535.002.98-.478.979-1.003v-7c-.002-.525-.466-.999-1-1s-1.002.475-1 1l-.016 4.713-2.57-2.589C16.92.612 10.593.664 5.141 6.117L2.285 9.269c-.395.388-.395 1.017 0 1.404s1.034.388 1.428 0zm42.228 4.973c-.012-.034-.028-.063-.045-.096a.962.962 0 0 0-.135-.202c-.024-.027-.045-.057-.072-.082a1.012 1.012 0 0 0-.249-.174l.001-.003-15.006-6.995a.978.978 0 0 0-.424-.102 1 1 0 0 0-1 1c0 .725.886 1.066.798 1.005l-.173-.08a.99.99 0 0 1 .173.08l12.876 5.995-12.517 5.961-5.157-2.579v-4.382a1 1 0 1 0-2 0v4.382l-5.157 2.579-12.517-5.96 8.988-4.063a.99.99 0 0 0 .686-.937 1 1 0 0 0-1-1c-.241 0-.341.046-.341.046l-11.088 5.05.001.003a1.01 1.01 0 0 0-.25.174c-.027.025-.047.055-.071.082a.993.993 0 0 0-.136.202c-.016.032-.032.062-.045.096a.98.98 0 0 0-.07.347v19c0 .398.234.739.571.9l-.001.003 21 10 .001-.003c.13.062.274.1.429.1s.299-.038.429-.1l.001.003 21-10-.001-.003a.997.997 0 0 0 .571-.9v-19a.98.98 0 0 0-.07-.347zm-22.93 27.763l-19-9.048V17.577l19 9.048v16.784zm1-18.524l-3.866-1.842 3.866-1.933 3.866 1.933-3.866 1.842zm20 9.476l-19 9.048V26.625l19-9.048v16.784z"
    />
  </svg>
);

export default Boxing;
