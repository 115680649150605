import React from "react";

const Disconnect = props => (
  <svg viewBox="0 0 32 32"   {...props}>
    <circle cx={16} cy={16} fill="#75ea9c" r={14} />
    <path
      d="M17.768 10.489l-7.778 7.78-2.829-2.829s-3.536-3.536.354-7.425 6.718-1.061 6.718-1.061zM22.01 14.731l-7.778 7.779 2.829 2.828s3.535 3.535 7.425-.354 1.061-6.718 1.061-6.718z"
      fill="#aaa"
    />
    <g fill="#4d5152">
      <path d="M24.84 24.63c3.36-3.71.71-6.36.71-6.36l-3.54-3.54-7.78 7.78 2.83 2.83s1.471 1.47 3.561 1.47c1.05 0 2.25-.369 3.5-1.479l4.84 4.84.71-.71zm-8.48-1.41h-.01l-.7-.71 6.36-6.359.71.71zm7.06 1.41c-.91.78-1.85 1.18-2.8 1.18-1.63 0-2.84-1.17-2.851-1.18l-.71-.71 6.36-6.36 1.41 1.41c.2.21 1.86 2.101-.7 4.95zM8.37 8.58l-.28.28c.04-.05.08-.09.13-.14s.1-.1.15-.14z" />
      <path d="M16.36 11.91l1.409-1.42-3.54-3.54s-.99-.99-2.61-.99c-1.02 0-2.3.4-3.74 1.71L3.04 2.83l-.71.71 4.84 4.84c-3.32 3.74-.01 7.06-.01 7.06l2.83 2.83 1.42-1.409 2.82 2.819.71-.71-2.83-2.819 3.54-3.541 2.82 2.83.71-.71zm-8.49 2.82c-.12-.11-2.71-2.78.22-5.87.04-.05.08-.09.13-.14s.1-.1.15-.14c1.12-1.08 2.21-1.62 3.25-1.62 1.16 0 1.88.68 1.91.7l1.41 1.42-6.36 6.36zm2.12 2.12l-.7-.699 6.36-6.371.7.71z" />
    </g>
  </svg>
);

export default Disconnect;
