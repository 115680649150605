import React from "react";

const MagnifierBacteria = props => (
  <svg viewBox="0 0 1010.002 1010"   {...props}>
    <path
      fill="#FFAB00"
      d="M317.39 598.771l93.761 93.79-296.984 297.035-93.761-93.85z"
    />
    <path
      fill="#78909C"
      d="M989.597 333c0 172.677-139.959 312.676-312.656 312.676-172.657 0-312.656-139.999-312.656-312.676 0-172.637 139.999-312.596 312.656-312.596 172.697 0 312.656 139.959 312.656 312.596z"
    />
    <path
      fill="#43525B"
      d="M676.941 666.08c-183.646 0-333.06-149.424-333.06-333.08 0-183.616 149.414-333 333.06-333s333.06 149.384 333.06 333c0 183.656-149.413 333.08-333.06 333.08zm0-625.272C515.791 40.808 384.69 171.88 384.69 333c0 161.16 131.102 292.271 292.252 292.271S969.193 494.161 969.193 333c0-161.12-131.101-292.192-292.252-292.192z"
    />
    <path
      fill="#43525B"
      d="M378.712 660.102l-28.853-28.852 93.82-93.81 28.853 28.851-93.82 93.811z"
    />
    <path
      fill="#43525B"
      d="M114.167 1010a20.38 20.38 0 0 1-14.437-5.988l-93.76-93.85c-7.961-7.96-7.961-20.883.01-28.844l296.984-296.973a20.405 20.405 0 0 1 14.426-5.978c5.41 0 10.6 2.152 14.426 5.978l93.761 93.79c7.97 7.97 7.97 20.883 0 28.853l-296.984 297.034a20.405 20.405 0 0 1-14.426 5.978zM49.258 895.756l64.918 64.977L382.3 692.562l-64.909-64.93L49.258 895.757z"
    />
    <path
      fill="#AEBCC4"
      d="M570.07 258.298c-11.268 0-20.405-9.136-20.405-20.404v-57.287c0-11.268 9.137-20.403 20.404-20.403 11.268 0 20.405 9.135 20.405 20.403v57.287c0 11.268-9.137 20.404-20.405 20.404zm11.935 83.57a20.336 20.336 0 0 1-14.426-5.978c-7.97-7.971-7.97-20.893 0-28.853l35.816-35.807c7.97-7.97 20.882-7.97 28.853 0 7.97 7.97 7.97 20.893 0 28.852L596.43 335.89a20.34 20.34 0 0 1-14.426 5.977zM746.77 501.492c-6.207 0-12.343-2.82-16.348-8.17-6.755-9.016-4.922-21.808 4.094-28.563l40.529-30.367c9.037-6.745 21.819-4.902 28.564 4.094 6.755 9.017 4.922 21.81-4.095 28.564l-40.529 30.367a20.294 20.294 0 0 1-12.215 4.075zm-.02-119.037a20.34 20.34 0 0 1-14.426-5.978l-28.653-28.653c-7.97-7.97-7.97-20.882 0-28.852 7.97-7.971 20.883-7.971 28.852 0l28.654 28.653c7.97 7.97 7.97 20.882 0 28.852a20.338 20.338 0 0 1-14.426 5.978zM514.298 437.67c-9.366 0-17.804-6.485-19.906-16.01l-11.109-50.432c-2.42-11.01 4.533-21.898 15.532-24.32 10.99-2.48 21.889 4.524 24.32 15.532l11.109 50.433c2.42 11.009-4.533 21.898-15.532 24.32a20.374 20.374 0 0 1-4.414.477zm106.553 75.45c-16.917 0-32.838-6.596-44.823-18.571-11.976-11.985-18.571-27.906-18.571-44.823s6.595-32.828 18.571-44.804c23.95-23.95 65.695-23.97 89.647.02 11.964 11.956 18.56 27.867 18.56 44.784 0 16.917-6.596 32.838-18.56 44.813-11.986 11.975-27.907 18.58-44.824 18.58zm0-85.97c-6.017 0-11.686 2.351-15.96 6.635-4.275 4.264-6.626 9.923-6.626 15.94 0 6.028 2.351 11.697 6.625 15.961 8.51 8.509 23.354 8.528 31.922 0 4.264-4.264 6.615-9.933 6.615-15.96 0-6.018-2.351-11.677-6.615-15.931-4.285-4.294-9.943-6.645-15.96-6.645z"
    />
    <path
      fill="#43525B"
      d="M872.504 350.734c-11.269 0-20.405-9.136-20.405-20.404 0-84.246-68.545-152.79-152.801-152.79-11.268 0-20.404-9.137-20.404-20.405s9.136-20.404 20.404-20.404c106.753 0 193.61 86.847 193.61 193.6-.001 11.267-9.137 20.403-20.404 20.403z"
    />
  </svg>
);

export default MagnifierBacteria;
