import React from "react";

const GlobalWarming = props => (
  <svg viewBox="0 0 32 32"   {...props}>
    <circle fill="#FFD878" cx={17} cy={15} r={15} />
    <circle fill="#67DDE0" cx={17} cy={15} r={14} />
    <path
      fill="#75EA9C"
      d="M30.5 14.5a5.424 5.424 0 0 0-.077-.072c-2.024-1.842-5.936-.915-6.923.072-1 1 1.235 3.479-2 5-3.021 1.42-.813 4.688-4 6-2.067.852-4-2-3-5s-5.888-1.634-4-7c1.05-2.983 3.885-2.252 8-2 3.897.239 3-4 1-5s-7 3-8 1 4-6 4-6 6.812-.475 10.753 3.434 4.222 9.542 4.222 9.542"
    />
    <path
      fill="#4D5152"
      d="M17 2c7.168 0 13 5.832 13 13s-5.832 13-13 13S4 22.168 4 15 9.832 2 17 2m0-1C9.268 1 3 7.268 3 15s6.268 14 14 14 14-6.268 14-14S24.732 1 17 1z"
    />
    <path
      fill="none"
      stroke="#4D5152"
      strokeMiterlimit={10}
      d="M15.5 1.5s-5 4-4 6 6-2 8-1 2.897 5.239-1 5c-4.115-.252-6.95-.983-8 2-1.888 5.366 5 4 4 7s.932 5.852 3 5c3.188-1.313.979-4.58 4-6 3.235-1.521 1-4 2-5s5-1.938 7 0"
    />
    <path
      fill="#FFD878"
      d="M9 31c-6 0-8-4-8-8S9 9 9 9l1 8 2-5s2 5 3 10-1 9-6 9z"
    />
    <path
      fill="#E88C77"
      d="M9 31s-3 0-2-4c.767-3.067 3-5 3-5s2 3 2 6-1 3-3 3z"
    />
    <path
      fill="#4D5152"
      d="M9 9l1 8 2-5s2 5 3 10-1 9-6 9c-6 0-8-4-8-8S9 9 9 9m.699-2.474L8.22 8.376C7.378 9.427 0 18.771 0 23c0 5.636 3.364 9 9 9 2.379 0 4.314-.827 5.597-2.392 1.526-1.862 2.017-4.634 1.383-7.805-1.006-5.032-2.969-9.967-3.052-10.175L12 9.308l-.929 2.321-.56 1.4-.519-4.153-.293-2.35z"
    />
    <path
      fill="#4D5152"
      d="M10 22s2 3 2 6-1 3-3 3c0 0-3 0-2-4 .767-3.067 3-5 3-5m.203-1.498l-.857.742c-.101.087-2.481 2.178-3.316 5.514-.429 1.715-.268 3.069.478 4.024C7.432 31.966 8.842 32 9 32c2.342 0 4-.287 4-4 0-3.266-2.08-6.422-2.168-6.555l-.629-.943z"
    />
  </svg>
);

export default GlobalWarming;
