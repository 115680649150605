import React from "react"

const BatteryCharging = props => (
  <svg
    viewBox="0 0 32 32"
    {...props}
    preserveAspectRatio="xMinYMin slice"
  >
    <path
      fill="#FFD878"
      d="M30 10v3c0 1.1-.9 2-2 2v2c0 1.1-.9 2-2 2h-3.51c-.101-.33-.21-.66-.33-1-1.75-4.88-5.64-10.3-5.85-10.59-.19-.26-.49-.41-.81-.41s-.62.15-.81.41c-.21.29-4.1 5.71-5.85 10.59-.12.34-.23.67-.33 1H5c-1.1 0-2-.9-2-2v-2c-1.1 0-2-.9-2-2v-3c0-1.1.9-2 2-2V6c0-1.1.9-2 2-2h21c1.1 0 2 .9 2 2v2c1.1 0 2 .9 2 2z"
    />
    <path
      fill="#4D5152"
      d="M28 8V6c0-1.1-.9-2-2-2H5c-1.1 0-2 .9-2 2v2c-1.1 0-2 .9-2 2v3c0 1.1.9 2 2 2v2c0 1.1.9 2 2 2h3.51c.1-.33.21-.66.33-1H5c-.55 0-1-.45-1-1v-3H3c-.55 0-1-.45-1-1v-3c0-.55.45-1 1-1h1V6c0-.55.45-1 1-1h21c.55 0 1 .45 1 1v3h1c.55 0 1 .45 1 1v3c0 .55-.45 1-1 1h-1v3c0 .55-.45 1-1 1h-3.84c.12.34.229.67.33 1H26c1.1 0 2-.9 2-2v-2c1.1 0 2-.9 2-2v-3c0-1.1-.9-2-2-2z"
    />
    <path
      fill="#75EA9C"
      d="M22 22c0 4.15-4.47 5.54-6 5.9v2.6c0 .28-.22.5-.5.5s-.5-.22-.5-.5v-2.6c-1.53-.36-6-1.75-6-5.9 0-5 6.5-14 6.5-14S22 17 22 22"
    />
    <path
      fill="#4D5152"
      d="M15.5 8S9 17 9 22c0 4.15 4.47 5.54 6 5.9v2.6c0 .28.22.5.5.5s.5-.22.5-.5v-2.6c1.53-.36 6-1.75 6-5.9 0-5-6.5-14-6.5-14m.5 18.91V23.7l2.85-2.851c.2-.189.2-.51 0-.699a.481.481 0 0 0-.698 0L16 22.3v-3.6l1.85-1.851c.2-.189.2-.51 0-.699a.481.481 0 0 0-.698 0L16 17.3v-2.8c0-.28-.22-.5-.5-.5s-.5.22-.5.5v4.8l-1.15-1.149a.482.482 0 0 0-.699 0c-.2.189-.2.51 0 .699L15 20.7v3.6l-2.15-2.148a.482.482 0 0 0-.699 0c-.2.188-.2.51 0 .698L15 25.7v1.21c-1.5-.37-5.04-1.6-5.04-4.91 0-3.63 3.85-9.77 5.54-12.3 1.69 2.53 5.54 8.67 5.54 12.3 0 3.31-3.54 4.54-5.04 4.91"
    />
  </svg>
)

export default BatteryCharging
