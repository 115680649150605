import React from "react";

const RacingFlag = props => (
  <svg viewBox="0 0 128 128"   {...props}>
    <path
      fill="#B8CBCD"
      d="M114.095 111.679c0 5.625-21.592 9.813-47.917 9.813-26.326 0-47.418-4.188-47.418-9.813 0-5.626 21.341-10.188 47.667-10.188 26.325 0 47.668 4.562 47.668 10.188z"
    />
    <path
      fill="#B2EDA6"
      d="M102.62 91.396c-15.744.918-20.083-22.081-35.827-21.165L87.35 27.127c15.745-.917 20.082 22.082 35.827 21.166L102.62 91.396z"
    />
    <path
      fill="#3C663E"
      d="M101.708 94.423c-8.681 0-14.282-6.157-19.223-11.591-4.684-5.15-9.059-9.986-15.518-9.605a2.998 2.998 0 0 1-2.882-4.286l20.557-43.104a3 3 0 0 1 2.534-1.704c9.281-.55 15.164 5.904 20.309 11.56 4.684 5.15 9.054 9.979 15.519 9.606a3.026 3.026 0 0 1 2.664 1.321c.592.88.675 2.008.218 2.965l-20.558 43.104a3 3 0 0 1-2.533 1.704c-.367.02-.73.03-1.087.03zM71.377 67.59c6.656 1.429 11.341 6.58 15.547 11.205 4.257 4.681 8.29 9.115 13.81 9.587l17.86-37.447c-6.656-1.43-11.341-6.58-15.548-11.206-4.256-4.68-8.29-9.115-13.81-9.587L71.377 67.59z"
    />
    <path
      fill="#3C663E"
      d="M93.661 41.107c-2.676-2.062-5.621-3.564-9.128-3.883l3.669-7.694c3.508.319 6.453 1.82 9.129 3.883l-3.67 7.694zm3.85 14.953c-2.435-2.569-4.843-5.195-7.519-7.259l3.668-7.693c2.677 2.063 5.085 4.688 7.521 7.258l-3.67 7.694zm11.35-.774c-2.784-1.838-5.246-4.352-7.68-6.921l3.669-7.693c2.435 2.569 4.896 5.082 7.68 6.921l-3.669 7.693zm5.943 10.565c-3.723.131-6.828-1.033-9.611-2.871l3.669-7.693c2.783 1.837 5.89 3.002 9.612 2.871l-3.67 7.693z"
    />
    <path
      fill="#3C663E"
      d="M86.322 56.494c-2.676-2.062-5.621-3.563-9.129-3.883l3.67-7.692c3.508.318 6.453 1.82 9.129 3.882l-3.67 7.693zm3.85 14.953c-2.435-2.571-4.843-5.196-7.52-7.258l3.669-7.695c2.677 2.063 5.085 4.688 7.52 7.259-1.222 2.565-2.446 5.13-3.669 7.694zm11.351-.773c-2.784-1.838-5.246-4.351-7.681-6.921l3.669-7.693c2.435 2.569 4.897 5.083 7.681 6.92a13416.29 13416.29 0 0 0-3.669 7.694zm5.942 10.563c-3.724.133-6.829-1.033-9.613-2.869l3.67-7.694c2.783 1.838 5.889 3.002 9.611 2.87l-3.668 7.693zm-28.482-9.355c-2.677-2.063-5.621-3.563-9.129-3.883l3.669-7.693c3.508.318 6.453 1.82 9.129 3.884l-3.669 7.692z"
    />
    <path
      fill="#3C663E"
      d="M94.184 86.061c-2.783-1.839-5.245-4.352-7.68-6.921l3.669-7.693c2.435 2.569 4.897 5.081 7.68 6.921l-3.669 7.693zm-45.64 25.431a3.003 3.003 0 0 1-2.707-4.292L69.11 58.402a3 3 0 1 1 5.416 2.582l-23.273 48.798a3 3 0 0 1-2.709 1.71z"
    />
    <path
      fill="#B2EDA6"
      d="M26.471 91.396c15.744.918 20.083-22.081 35.827-21.165L41.741 27.127C25.996 26.21 21.658 49.209 5.913 48.293l20.558 43.103z"
    />
    <path
      fill="#3C663E"
      d="M27.385 94.423h-.002c-.356 0-.719-.01-1.086-.031a3 3 0 0 1-2.533-1.704L3.205 49.584a3.003 3.003 0 0 1 .218-2.965 3.015 3.015 0 0 1 2.664-1.321c.249.015.491.021.732.021h.002c6.027-.001 10.28-4.677 14.783-9.628 5.144-5.657 11.015-12.106 20.309-11.56a3 3 0 0 1 2.534 1.704l20.417 42.812a3 3 0 0 1-2.548 4.584h-.02a3.44 3.44 0 0 1-.175-.005c-6.461-.379-10.833 4.455-15.518 9.605-4.938 5.435-10.539 11.592-19.218 11.592zM10.496 50.934l17.861 37.448c5.52-.471 9.553-4.905 13.81-9.586 4.206-4.626 8.891-9.776 15.547-11.206L39.855 30.142c-5.52.472-9.554 4.907-13.81 9.587-4.207 4.624-8.892 9.776-15.549 11.205z"
    />
    <path
      fill="#3C663E"
      d="M35.431 41.107c2.676-2.062 5.621-3.564 9.128-3.883L40.89 29.53c-3.508.319-6.453 1.82-9.129 3.883l3.67 7.694zM31.58 56.06c2.435-2.569 4.843-5.195 7.519-7.259l-3.668-7.693c-2.677 2.063-5.085 4.688-7.521 7.258l3.67 7.694zm-11.35-.774c2.784-1.838 5.246-4.352 7.68-6.921l-3.669-7.693c-2.435 2.569-4.896 5.082-7.68 6.921l3.669 7.693zm-5.942 10.565c3.723.131 6.828-1.033 9.611-2.871l-3.669-7.693c-2.783 1.837-5.89 3.002-9.612 2.871l3.67 7.693zm28.481-9.357c2.676-2.062 5.621-3.563 9.129-3.883l-3.669-7.692c-3.508.318-6.453 1.82-9.129 3.882 1.222 2.563 2.445 5.129 3.669 7.693zm-3.85 14.953c2.435-2.571 4.843-5.196 7.519-7.258l-3.669-7.695c-2.677 2.063-5.085 4.688-7.52 7.259l3.67 7.694z"
    />
    <path
      fill="#3C663E"
      d="M27.568 70.674c2.784-1.838 5.246-4.351 7.681-6.921L31.58 56.06c-2.434 2.569-4.896 5.083-7.681 6.92 1.224 2.565 2.447 5.129 3.669 7.694zm-5.942 10.563c3.724.133 6.829-1.033 9.613-2.869a18169.76 18169.76 0 0 1-3.67-7.694c-2.783 1.838-5.889 3.002-9.611 2.87l3.668 7.693zm28.482-9.355c2.677-2.063 5.621-3.563 9.129-3.883l-3.669-7.693c-3.508.318-6.453 1.82-9.129 3.884l3.669 7.692zm-15.2 14.179c2.783-1.839 5.245-4.352 7.68-6.921l-3.669-7.693c-2.435 2.569-4.897 5.081-7.68 6.921 1.222 2.565 2.445 5.129 3.669 7.693z"
    />
    <path
      fill="#3C663E"
      d="M80.547 111.492a3.002 3.002 0 0 1-2.71-1.71L54.564 60.984a3 3 0 1 1 5.417-2.582L83.253 107.2a3 3 0 0 1-2.706 4.292z"
    />
  </svg>
);

export default RacingFlag;
