import React from "react";

const Cards = props => (
  <svg viewBox="0 0 128 128"   {...props}>
    <path
      fill="#B8CBCD"
      d="M112.775 110.805c0 5.625-21.342 10.187-47.668 10.187s-47.666-4.562-47.666-10.187c0-5.626 21.41-9.813 47.736-9.813 26.327-.001 47.598 4.187 47.598 9.813z"
    />
    <path
      fill="#FFF"
      d="M7.183 52.902l44.432-11.91 16.83 62.782-44.433 11.911z"
    />
    <path
      fill="#783741"
      d="M24.01 118.686c-.522 0-1.04-.137-1.5-.401a3.006 3.006 0 0 1-1.397-1.821L4.28 53.681a3 3 0 0 1 2.12-3.675l44.431-11.912a2.997 2.997 0 0 1 3.675 2.12l16.833 62.783a3 3 0 0 1-2.12 3.674l-44.432 11.912a2.99 2.99 0 0 1-.777.103zM10.852 55.024l15.278 56.987 38.636-10.358-15.279-56.987-38.635 10.358z"
    />
    <path
      opacity={0.2}
      fill="#783741"
      d="M11.441 59.687a3 3 0 0 1-1.009-5.826c8.359-2.985 16.87-5.216 25.881-7.578 6.091-1.596 12.389-3.246 18.685-5.162a3 3 0 0 1 1.746 5.74c-6.407 1.95-12.763 3.615-18.909 5.227-8.882 2.327-17.27 4.526-25.385 7.424a2.994 2.994 0 0 1-1.009.175z"
    />
    <path
      fill="#FFBAB0"
      d="M41.823 90.686L29.281 80.108l5.16-15.422 11.903 10.846z"
    />
    <path
      fill="#783741"
      d="M41.823 92.686c-.466 0-.924-.163-1.289-.471L27.992 81.638a2 2 0 0 1-.607-2.164l5.16-15.422a1.998 1.998 0 0 1 3.244-.844L47.69 74.054c.568.518.789 1.314.57 2.05l-4.52 15.154a2 2 0 0 1-1.917 1.428zM31.608 79.455l9.171 7.734 3.289-11.025-8.704-7.932-3.756 11.223z"
    />
    <path fill="#FFF" d="M42.178 30.991h46v65h-46z" />
    <path
      fill="#324654"
      d="M88.178 98.991h-46a3 3 0 0 1-3-3v-65a3 3 0 0 1 3-3h46a3 3 0 0 1 3 3v65a3 3 0 0 1-3 3zm-43-6h40v-59h-40v59z"
    />
    <path
      opacity={0.2}
      fill="#324654"
      d="M86.441 36.991h-42.68a3 3 0 1 1 0-6h42.681a3 3 0 1 1-.001 6z"
    />
    <path
      fill="#B9E3ED"
      d="M64.566 67.315l1.768-1.768 1.768 1.768a5.501 5.501 0 0 0 7.778 0 5.501 5.501 0 0 0 0-7.778l-1.768-1.768-7.778-7.778-7.777 7.778-1.768 1.768a5.5 5.5 0 0 0 7.777 7.778z"
    />
    <path
      fill="#324654"
      d="M71.991 70.926a7.451 7.451 0 0 1-5.304-2.196l-.354-.354-.354.354a7.451 7.451 0 0 1-5.304 2.196 7.447 7.447 0 0 1-5.303-2.196c-2.925-2.924-2.925-7.683 0-10.606l9.546-9.546c.75-.75 2.078-.75 2.828 0l9.546 9.546c2.924 2.925 2.924 7.683 0 10.606a7.443 7.443 0 0 1-5.301 2.196zm-5.657-7.378c.512 0 1.023.195 1.414.586l1.768 1.768c1.321 1.32 3.627 1.323 4.95 0a3.505 3.505 0 0 0 0-4.95l-8.132-8.132-8.132 8.133a3.504 3.504 0 0 0 0 4.949c1.323 1.323 3.629 1.32 4.95 0l1.768-1.768a1.99 1.99 0 0 1 1.414-.586z"
    />
    <path fill="#B9E3ED" d="M77.178 73.933l-7.072-7.072-7.07 7.072z" />
    <path
      fill="#324654"
      d="M77.178 75.933H63.036a2 2 0 0 1-1.414-3.414l7.07-7.071c.75-.75 2.078-.75 2.828 0l7.071 7.071a2 2 0 0 1-1.413 3.414zm-9.314-4h4.485l-2.243-2.243-2.242 2.243z"
    />
    <path
      fill="#FFBAB0"
      d="M77.257 18.303l44.434 11.9-16.814 62.787-44.435-11.9z"
    />
    <path
      fill="#783741"
      d="M104.877 95.992c-.257 0-.518-.033-.778-.104l-44.434-11.9a2.998 2.998 0 0 1-2.121-3.673L74.36 17.527a2.997 2.997 0 0 1 3.674-2.121l44.434 11.9a2.998 2.998 0 0 1 2.121 3.674l-16.816 62.787a3.003 3.003 0 0 1-2.896 2.225zM64.115 78.969l38.638 10.349 15.265-56.991-38.638-10.35-15.265 56.992z"
    />
    <path
      fill="#FFF"
      d="M77.257 18.303l44.434 11.9-16.814 62.787-44.435-11.9z"
    />
    <path
      fill="#783741"
      d="M104.877 95.992c-.257 0-.518-.033-.778-.104l-44.434-11.9a2.998 2.998 0 0 1-2.121-3.673L74.36 17.527a2.997 2.997 0 0 1 3.674-2.121l44.434 11.9a2.998 2.998 0 0 1 2.121 3.674l-16.816 62.787a3.003 3.003 0 0 1-2.896 2.225zM64.115 78.969l38.638 10.349 15.265-56.991-38.638-10.35-15.265 56.992z"
    />
    <path
      opacity={0.2}
      fill="#783741"
      d="M101.9 92.899a2.998 2.998 0 0 1-2.899-3.779l14.449-53.949a3360.055 3360.055 0 0 0-17.81-4.674c-6.545-1.705-12.291-3.468-19.703-5.453a2.998 2.998 0 0 1-2.121-3.674 2.994 2.994 0 0 1 3.674-2.121c7.392 1.979 13.127 3.739 19.663 5.441a3054.677 3054.677 0 0 1 20.748 5.459 2.999 2.999 0 0 1 2.121 3.674l-15.227 56.852a2.999 2.999 0 0 1-2.895 2.224z"
    />
    <path
      fill="#FFBAB0"
      d="M94.432 50.519l-2.164 1.25-1.25-2.165a5.501 5.501 0 0 0-9.527 5.501l1.251 2.165 5.5 9.526 9.525-5.501 2.166-1.251a5.5 5.5 0 0 0-5.501-9.525z"
    />
    <path
      fill="#783741"
      d="M88.242 68.797a2.002 2.002 0 0 1-1.733-1l-6.75-11.69a7.448 7.448 0 0 1-.75-5.691 7.452 7.452 0 0 1 3.494-4.555 7.5 7.5 0 0 1 3.745-1.006 7.528 7.528 0 0 1 6.5 3.749l.25.434.433-.249a7.507 7.507 0 0 1 3.745-1.007 7.527 7.527 0 0 1 6.5 3.751 7.444 7.444 0 0 1 .751 5.69 7.456 7.456 0 0 1-3.494 4.555l-11.691 6.751a2.002 2.002 0 0 1-1 .268zm-1.993-19.943c-.609 0-1.212.162-1.744.47a3.477 3.477 0 0 0-1.632 2.126 3.477 3.477 0 0 0 .351 2.655l5.751 9.959 9.959-5.751a3.481 3.481 0 0 0 1.63-2.126 3.474 3.474 0 0 0-.35-2.654 3.516 3.516 0 0 0-3.037-1.752 3.49 3.49 0 0 0-1.745.471l-2.164 1.249a2.002 2.002 0 0 1-2.733-.732l-1.25-2.165a3.515 3.515 0 0 0-3.036-1.75z"
    />
  </svg>
);

export default Cards;
