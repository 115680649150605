import React from "react";

const UsbStick = props => (
  <svg viewBox="0 0 31.998 31.997"   {...props}>
    <path
      d="M31.704 8.792l-8.5-8.499a1.003 1.003 0 0 0-1.417 0l-8.499 8.499a1.003 1.003 0 0 0 0 1.417l8.499 8.499a1 1 0 0 0 1.417 0l8.5-8.499a1.003 1.003 0 0 0 0-1.417z"
      fill="#ccc"
    />
    <path
      fill="#b3b3b3"
      d="M16.827 13.747l9.916-9.916 1.454 1.454L18.28 15.2z"
    />
    <path
      d="M21.247 3.313l-.938.938a1.04 1.04 0 0 0 1.469 1.47l.938-.938a1.04 1.04 0 0 0-1.469-1.47zm7.469 6a1.04 1.04 0 0 0-1.469 0l-.938.938a1.04 1.04 0 0 0 1.469 1.47l.938-.939a1.038 1.038 0 0 0 0-1.469z"
      fillRule="evenodd"
      clipRule="evenodd"
      fill="gray"
    />
    <path
      d="M13.772 5.454L2.645 16.582a9.032 9.032 0 0 0 12.771 12.771l11.127-11.128L13.772 5.454z"
      fill="#f06eaa"
    />
  </svg>
);

export default UsbStick;
