import React from "react";

const Shaker = props => (
  <svg viewBox="0 0 128 128"   {...props}>
    <path
      fill="#B8CBCD"
      d="M109.178 113.679c0 5.625-21.675 9.813-48 9.813-26.326 0-47.335-4.188-47.335-9.813 0-5.626 21.341-10.188 47.667-10.188 26.325 0 47.668 4.562 47.668 10.188z"
    />
    <path
      fill="#FFF"
      d="M37.693 109.767c0 3.891 5.116 7.045 23.015 7.045s23.016-3.154 23.016-7.045l7.515-54.955c0 3.892-12.631 7.046-30.53 7.046-17.898 0-30.53-3.154-30.53-7.046l7.514 54.955z"
    />
    <path
      fill="#B2EDA6"
      d="M37.693 109.767c0 3.891 5.116 7.045 23.015 7.045s23.016-3.154 23.016-7.045l2.817-24.895c0 3.892-8.873 7.046-26.773 7.046-17.898 0-24.893-3.154-24.893-7.046l2.818 24.895z"
    />
    <path
      fill="#3C663E"
      d="M60.708 118.811c-10.673 0-24.84-.926-25.013-8.897l-7.417-54.236a3.843 3.843 0 0 1-.1-.866 2 2 0 0 1 3.981-.271l.042.303c.767 1.738 9.959 5.015 28.507 5.015 18.548 0 27.739-3.275 28.507-5.015l.042-.302a2 2 0 1 1 3.981.27c0 .29-.034.581-.1.866l-7.416 54.236c-.173 7.972-14.341 8.897-25.014 8.897zM32.911 60.033l6.764 49.462c.012.09.019.181.019.271 0 1.184 1.516 5.045 21.015 5.045 19.5 0 21.016-3.861 21.016-5.045 0-.091.006-.182.019-.271l6.763-49.463c-8.05 3.595-23.916 3.824-27.797 3.824-3.883.001-19.749-.228-27.799-3.823z"
    />
    <ellipse fill="#B2EDA6" cx={60.708} cy={84.872} rx={26.303} ry={7.046} />
    <path
      fill="#3C663E"
      d="M60.708 93.918c-9.789 0-28.303-1.891-28.303-9.046 0-7.154 18.514-9.045 28.303-9.045 9.789 0 28.303 1.891 28.303 9.045 0 7.155-18.514 9.046-28.303 9.046zm0-14.091c-16.187 0-24.048 3.662-24.303 5.054.255 1.374 8.116 5.037 24.303 5.037s24.048-3.663 24.303-5.055c-.255-1.374-8.116-5.036-24.303-5.036z"
    />
    <circle fill="#3C663E" cx={52.253} cy={102.251} r={3.288} />
    <circle fill="#3C663E" cx={66.189} cy={109.296} r={2.349} />
    <circle fill="#3C663E" cx={68.223} cy={99.433} r={2.348} />
    <path
      opacity={0.2}
      fill="#3C663E"
      d="M79.03 117.463a3 3 0 0 1-2.976-3.406l7.515-54.954a3 3 0 1 1 5.944.813l-7.515 54.954a3 3 0 0 1-2.968 2.593z"
    />
    <path
      fill="#3C663E"
      d="M49.834 25.402c-.321 0-.643-.052-.953-.155l-17.656-5.911a3 3 0 0 1 1.905-5.69l15.164 5.076c3.119-5.267 12.748-16.032 38.978-15.23a3.002 3.002 0 0 1-.187 5.998c-29.387-.917-34.343 13.669-34.39 13.816a3.003 3.003 0 0 1-2.861 2.096z"
    />
    <path
      fill="#B2EDA6"
      d="M61.178 36.023v-8.454c0-5.967 4.836-10.803 10.803-10.803 5.967 0 10.804 4.836 10.804 10.803v10.803l-21.607-2.349z"
    />
    <path
      fill="#3C663E"
      d="M82.784 40.372c-.072 0-.144-.004-.216-.012l-21.606-2.349a2 2 0 0 1-1.784-1.988v-8.454c0-7.059 5.743-12.803 12.803-12.803 7.06 0 12.804 5.743 12.804 12.803v10.803a2 2 0 0 1-2.001 2zm-19.606-6.143l17.606 1.913v-8.573c0-4.854-3.949-8.803-8.804-8.803-4.854 0-8.803 3.949-8.803 8.803v6.66z"
    />
    <path
      fill="#B2EDA6"
      d="M30.178 41.491c0-3.892 12.632-6.877 30.53-6.877 17.899 0 30.53 3.154 30.53 7.046v13.151c0 3.892-12.631 7.046-30.53 7.046-17.898 0-30.53-3.475-30.53-7.366v-13z"
    />
    <path
      fill="#3C663E"
      d="M60.708 63.857c-12.082 0-32.53-1.973-32.53-9.366v-13c0-8.449 27.098-8.877 32.53-8.877 5.432 0 32.53.437 32.53 9.046v13.151c0 8.61-27.098 9.046-32.53 9.046zm0-27.243c-18.792 0-27.991 3.261-28.536 4.924l.006 12.953c.445 1.553 9.292 5.366 28.53 5.366 18.813 0 28-3.37 28.535-5.089l-.005-13.108c-.53-1.676-9.717-5.046-28.53-5.046z"
    />
    <path
      fill="#3C663E"
      d="M60.708 50.705c-5.432 0-32.53-.437-32.53-9.045a2 2 0 0 1 4 0c.53 1.676 9.718 5.045 28.53 5.045 18.813 0 28-3.369 28.535-5.088 0-1.104.893-1.979 1.998-1.979s1.997.917 1.997 2.021c0 8.609-27.098 9.046-32.53 9.046z"
    />
    <path
      fill="#B2EDA6"
      d="M53.662 30.857a5.638 5.638 0 0 1-11.273 0v-9.394a5.636 5.636 0 1 1 11.273 0v9.394z"
    />
    <path
      fill="#3C663E"
      d="M48.026 38.493c-4.211 0-7.637-3.426-7.637-7.636v-9.394c0-4.21 3.426-7.636 7.637-7.636 4.21 0 7.636 3.426 7.636 7.636v9.394c0 4.21-3.425 7.636-7.636 7.636zm0-20.666a3.64 3.64 0 0 0-3.637 3.636v9.394a3.64 3.64 0 0 0 3.637 3.636 3.64 3.64 0 0 0 3.636-3.636v-9.394a3.64 3.64 0 0 0-3.636-3.636z"
    />
    <path
      fill="#B2EDA6"
      d="M61.178 24.281c-10.342 0-19.114 6.687-22.249 15.97 0 1.297 10.026 2.349 22.396 2.349 12.37 0 22.102-1.052 22.102-2.349-3.135-9.283-11.909-15.97-22.249-15.97z"
    />
    <path
      fill="#FFF"
      d="M41.594 43.661a2 2 0 0 1-1.895-2.64c3.589-10.627 17.472-17.766 28.187-17.766a2 2 0 0 1 0 4c-9.118 0-21.489 6.434-24.397 15.045a2 2 0 0 1-1.895 1.361z"
    />
    <path
      fill="#3C663E"
      d="M61.325 44.6c-24.396 0-24.396-2.829-24.396-4.349 0-.218.036-.434.105-.64 3.5-10.366 13.203-17.33 24.144-17.33s20.644 6.964 24.144 17.33c.069.206.105.422.105.64 0 1.519 0 4.349-24.102 4.349zm-19.893-5.301c2.825.627 10.034 1.301 19.893 1.301 10.094 0 16.91-.678 19.604-1.288a21.468 21.468 0 0 0-19.751-13.03 21.465 21.465 0 0 0-19.746 13.017z"
    />
    <path
      fill="#3C663E"
      d="M48.966 15.827a5.636 5.636 0 0 1 5.636 5.636v4.052a23.448 23.448 0 0 1 7.516-1.233c.171 0 .337.017.506.021 1.385-4.369 5.47-7.535 10.297-7.535 5.967 0 10.804 4.836 10.804 10.803v9.133c5.333 1.273 8.454 3.024 8.454 4.958v13.151c0 .147-.023.294-.06.438l-7.456 54.517c0 3.891-5.115 7.045-23.015 7.045s-23.015-3.154-23.015-7.045l-7.455-54.275v-14c0-2.175 3.889-3.95 10.495-5.243a23.559 23.559 0 0 1 2.125-3.142 5.615 5.615 0 0 1-.469-2.248v-9.394a5.637 5.637 0 0 1 5.637-5.639m0-4c-5.313 0-9.637 4.322-9.637 9.636v9.394c0 .481.035.956.105 1.425-.091.133-.181.267-.269.401-5.911 1.309-11.987 3.578-11.987 8.808v14c0 .182.012.364.037.544l7.424 54.046c.098 2.724 1.514 6.284 7.426 8.428 4.274 1.55 10.68 2.303 19.583 2.303s15.309-.753 19.583-2.303c5.913-2.145 7.329-5.705 7.426-8.429l7.402-54.122a5.64 5.64 0 0 0 .119-1.146V41.66c0-4.544-4.819-6.853-8.454-8.015v-6.076c0-8.162-6.641-14.803-14.804-14.803a14.74 14.74 0 0 0-12.937 7.597c-.478.037-.954.087-1.428.148-.479-4.868-4.597-8.684-9.589-8.684z"
    />
  </svg>
);

export default Shaker;
