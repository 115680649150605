import React from "react";

const Astronaut = props => (
  <svg viewBox="0 0 64 56"   {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M24 43a4 4 0 1 1-8 0 4 4 0 0 1 8 0" fill="#f2b430" />
      <path d="M31 56h2V37h-2v19z" fill="#1d7ed0" />
      <path
        d="M44 39h-8c-.55 0-1 .45-1 1v2c0 .55.45 1 1 1h8c.55 0 1-.45 1-1v-2c0-.55-.45-1-1-1zm-8 3h8v-2h-8v2z"
        fill="#f2b430"
      />
      <path
        d="M47 1c1.654 0 3 1.346 3 3v6h-7.019l.764 1H51V4c0-2.2-1.8-4-4-4H17c-2.2 0-4 1.8-4 4v7h7.163l.797-1H14V4c0-1.654 1.346-3 3-3h30"
        fill="#1d7ed0"
      />
      <path
        d="M63 47h-3c-.55 0-1 .45-1 1v4c0 .55.45 1 1 1h3c.55 0 1-.45 1-1v-4c0-.55-.45-1-1-1zm-3 5h3v-4h-3v4z"
        fill="#f2b430"
      />
      <path
        d="M53 22v14H11V22h2.426v-1H10v16h44V21h-3.565l-.036 1H53z"
        fill="#1d7ed0"
      />
      <path
        d="M52 11c1.654 0 3 1.346 3 3v7h-4.631l-.072 1H56v-8a4 4 0 0 0-4-4h-9.019l.651 1H52zm-31.04-1H12a4 4 0 0 0-4 4v8h5.433l.072-1H9v-7c0-1.654 1.346-3 3-3h8.163l.797-1z"
        fill="#f2b430"
      />
      <path
        d="M32 5c-8.837 0-16 7.163-16 16s7.163 16 16 16 16-7.163 16-16S40.837 5 32 5m0 1c8.271 0 15 6.729 15 15s-6.729 15-15 15-15-6.729-15-15S23.729 6 32 6"
        fill="#1d7ed0"
      />
      <path
        d="M32 10c-8.837 0-16 2.163-16 11s7.163 13 16 13 16-4.163 16-13-7.163-11-16-11m0 1c10.374 0 15 3.084 15 10 0 7.514-5.607 12-15 12s-15-4.486-15-12c0-6.916 4.626-10 15-10m20 25H12C5.373 36 0 41.373 0 48v8h64v-8c0-6.627-5.373-12-12-12m0 1c6.065 0 11 4.935 11 11v7H1v-7c0-6.065 4.935-11 11-11h40"
        fill="#1d7ed0"
      />
      <path
        d="M17 17h-2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h2v-8zm-1 1v6h-1c-.551 0-1-.449-1-1v-4c0-.551.449-1 1-1h1zm33-1h-2v8h2a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2m0 1c.551 0 1 .449 1 1v4c0 .551-.449 1-1 1h-1v-6h1"
        fill="#1d7ed0"
      />
      <path
        d="M48.5 8c-.275 0-.5.225-.5.5v9c0 .275.225.5.5.5s.5-.225.5-.5v-9c0-.275-.225-.5-.5-.5"
        fill="#1d7ed0"
      />
      <path
        d="M19.5 21a.5.5 0 0 1-.5-.5c0-3.54 3.635-7.5 8.5-7.5a.5.5 0 0 1 0 1c-4.293 0-7.5 3.432-7.5 6.5a.5.5 0 0 1-.5.5"
        fill="#f2b430"
      />
      <path d="M50 56h1V37h-1v19zm-37 0h1V37h-1v19z" fill="#1d7ed0" />
    </g>
  </svg>
);

export default Astronaut;
