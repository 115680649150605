import React from "react";

const Hockey = props => (
  <svg viewBox="0 0 128 128"   {...props}>
    <path
      fill="#B8CBCD"
      d="M112.219 110.521c0 5.625-21.843 10.813-48.168 10.813-26.326 0-47.168-5.188-47.168-10.813 0-5.626 20.841-10.188 47.168-10.188 26.325.001 48.168 4.562 48.168 10.188z"
    />
    <path
      fill="#F7BB83"
      d="M73.454 13.515a4 4 0 0 1 7.125 3.639L46.622 95.139a6.495 6.495 0 0 1-6.071 4.195h-27a6.5 6.5 0 1 1 0-13h26l33.903-72.819z"
    />
    <path
      fill="#6E4123"
      d="M40.551 101.334h-27c-4.687 0-8.5-3.813-8.5-8.5s3.813-8.5 8.5-8.5h24.725l33.365-71.663c1.063-2.083 3.111-3.337 5.379-3.337a6.01 6.01 0 0 1 2.726.658 5.959 5.959 0 0 1 2.979 3.495 5.957 5.957 0 0 1-.335 4.518L48.456 95.937c-1.21 3.193-4.401 5.397-7.905 5.397zm-27-13c-2.481 0-4.5 2.019-4.5 4.5s2.019 4.5 4.5 4.5h27a4.523 4.523 0 0 0 4.202-2.905l33.993-78.073c.016-.038.034-.075.052-.111a1.99 1.99 0 0 0 .122-1.526 1.988 1.988 0 0 0-.993-1.165c-.971-.497-2.204-.082-2.691.871L41.364 87.178a2 2 0 0 1-1.813 1.156h-26z"
    />
    <path fill="#FFF" d="M27.148 99.334h-12.41l7.091-13h12.409z" />
    <path
      fill="#6E4123"
      d="M27.148 101.334h-12.41a2 2 0 0 1-1.756-2.958l7.091-13a2 2 0 0 1 1.756-1.042h12.409a2 2 0 0 1 1.756 2.958l-7.091 13a1.997 1.997 0 0 1-1.755 1.042zm-9.04-4h7.853l4.909-9h-7.853l-4.909 9z"
    />
    <path
      fill="#F7BB83"
      d="M88.551 86.334h26a6.5 6.5 0 1 1 0 13h-27a6.494 6.494 0 0 1-6.071-4.195L47.523 17.154a4 4 0 1 1 7.125-3.639l33.903 72.819z"
    />
    <path
      fill="#6E4123"
      d="M114.551 101.334h-27c-3.504 0-6.695-2.204-7.941-5.485L45.711 18.004a5.96 5.96 0 0 1-.334-4.518 5.962 5.962 0 0 1 2.979-3.495 6.023 6.023 0 0 1 2.726-.657 5.977 5.977 0 0 1 5.347 3.272l33.397 71.729h24.725c4.687 0 8.5 3.813 8.5 8.5s-3.813 8.499-8.5 8.499zm-63.469-88c-.316 0-.621.074-.907.22a1.988 1.988 0 0 0-.993 1.165 1.99 1.99 0 0 0 .122 1.526c.019.037.036.074.052.111L83.313 94.34c.696 1.826 2.384 2.994 4.238 2.994h27c2.481 0 4.5-2.019 4.5-4.5s-2.019-4.5-4.5-4.5h-26a2 2 0 0 1-1.813-1.156L52.834 14.359c-.312-.607-.995-1.025-1.752-1.025z"
    />
    <path fill="#FFF" d="M101.829 99.334h12.409l-7.09-13h-12.41z" />
    <path
      fill="#6E4123"
      d="M114.238 101.334h-12.409a2 2 0 0 1-1.756-1.042l-7.091-13a2.002 2.002 0 0 1 1.756-2.958h12.409a2 2 0 0 1 1.756 1.042l7.091 13a2.002 2.002 0 0 1-1.756 2.958zm-11.221-4h7.853l-4.909-9h-7.853l4.909 9z"
    />
    <path
      fill="#B9E3ED"
      d="M51.051 112.834c0 2.485 7.148 4.5 13.5 4.5s13.5-2.015 13.5-4.5v-8c0-2.485-7.148-4.5-13.5-4.5s-13.5 2.015-13.5 4.5v8z"
    />
    <path
      fill="#324654"
      d="M64.551 120.334c-4.589 0-16.5-1.348-16.5-7.5v-8c0-6.152 11.911-7.5 16.5-7.5s16.5 1.348 16.5 7.5v8c0 6.152-11.911 7.5-16.5 7.5zm-10.5-8.148c1.228.837 5.401 2.148 10.5 2.148s9.271-1.312 10.5-2.148v-6.703c-1.229-.837-5.401-2.148-10.5-2.148s-9.272 1.312-10.5 2.148v6.703z"
    />
    <path
      fill="#324654"
      d="M64.551 111.334c-5.993 0-15.5-1.765-15.5-6.5a2 2 0 0 1 3.991-.192c.751.963 5.606 2.692 11.509 2.692 5.903 0 10.758-1.729 11.509-2.692a2 2 0 0 1 3.991.192c0 4.735-9.507 6.5-15.5 6.5z"
    />
    <path
      fill="#6E4123"
      d="M77.02 11.334a4 4 0 0 1 3.559 5.821l-11.713 26.9 19.685 42.279h26a6.5 6.5 0 1 1 0 13h-27a6.494 6.494 0 0 1-6.071-4.195L64.051 55.112 46.622 95.139a6.495 6.495 0 0 1-6.071 4.195h-27a6.5 6.5 0 1 1 0-13h26l19.685-42.28-11.713-26.9a4 4 0 0 1 7.124-3.64l9.404 20.197 9.403-20.197a4.002 4.002 0 0 1 3.566-2.18m0-4a7.97 7.97 0 0 0-7.128 4.361l-.064.131-5.777 12.409-5.777-12.409-.064-.131a7.968 7.968 0 0 0-7.127-4.361 8.025 8.025 0 0 0-3.636.877 7.95 7.95 0 0 0-3.972 4.66 7.944 7.944 0 0 0 .422 5.975L54.85 44 37.001 82.334h-23.45c-5.79 0-10.5 4.71-10.5 10.5s4.71 10.5 10.5 10.5h27c4.294 0 8.207-2.678 9.772-6.676l13.728-31.526 13.727 31.526c1.566 3.998 5.479 6.676 9.773 6.676h27c5.79 0 10.5-4.71 10.5-10.5s-4.71-10.5-10.5-10.5h-23.45L73.253 43.999l10.953-25.154a7.946 7.946 0 0 0 .422-5.976 7.947 7.947 0 0 0-3.972-4.66 8.043 8.043 0 0 0-3.636-.875z"
    />
  </svg>
);

export default Hockey;
