import React from "react";

const Cancel = props => (
  <svg viewBox="0 0 48 48"   {...props}>
    <path
      fill="#F16362"
      d="M25.5 7C14.178 7 5 15.73 5 26.5S14.178 46 25.5 46C33.557 46 46 34.005 46 26.5 46 15.73 36.822 7 25.5 7z"
    />
    <path
      fill="#384D68"
      d="M34.72 13.28a1.009 1.009 0 0 0-1.428 0l-9.282 9.281-9.282-9.281a1.009 1.009 0 1 0-1.428 1.428l9.281 9.282-9.281 9.281a1.009 1.009 0 1 0 1.428 1.428l9.282-9.281 9.282 9.281a1.009 1.009 0 1 0 1.428-1.428l-9.282-9.281 9.282-9.282a1.01 1.01 0 0 0 0-1.428zM24 2C11.85 2 2 11.85 2 24s9.85 22 22 22 22-9.85 22-22S36.15 2 24 2zm0 42C12.954 44 4 35.046 4 24S12.954 4 24 4s20 8.954 20 20-8.954 20-20 20z"
    />
  </svg>
);

export default Cancel;
