import React from "react";

const Rugby = props => (
  <svg viewBox="0 0 128 128"   {...props}>
    <ellipse fill="#B8CBCD" cx={66.357} cy={110.821} rx={47.668} ry={10.186} />
    <path
      fill="#FFBAB0"
      d="M111.645 13.566a2.685 2.685 0 0 0-.025-.084l-.058.057c-2.701-.835-38.544-11.125-70.138 20.47-31.595 31.594-21.743 67.879-20.942 70.611l-.061.06s.033.012.086.028l.025.083.059-.058c2.7.836 38.543 11.126 70.138-20.469 31.596-31.597 21.744-67.877 20.942-70.611l.061-.061c-.001.001-.033-.01-.087-.026z"
    />
    <path
      fill="#783741"
      d="M37.925 109.991h-.004c-9.138-.001-15.725-1.668-17.768-2.258a3.135 3.135 0 0 1-1.534-.663 3.011 3.011 0 0 1-1.182-2.198c-1.695-6.254-9.454-41.666 21.866-72.985 19.51-19.51 40.64-23.605 54.93-23.605 9.135 0 15.73 1.67 17.77 2.258a3.137 3.137 0 0 1 1.537.668 3.01 3.01 0 0 1 1.175 2.187c1.691 6.238 9.454 41.671-21.864 72.99-19.51 19.509-40.637 23.606-54.926 23.606zm-14.947-7.714c2.517.626 7.905 1.714 14.943 1.714h.003c13.155 0 32.625-3.79 50.683-21.848 27.444-27.446 22.244-58.892 20.567-66.148-2.519-.625-7.91-1.713-14.942-1.713-13.156 0-32.63 3.79-50.687 21.848C16.091 63.584 21.3 95.022 22.978 102.277z"
    />
    <path
      fill="#783741"
      d="M22.409 107.451c-.767 0-1.341-.024-1.711-.048a1.968 1.968 0 0 1-1.091-.215 2.002 2.002 0 0 1-1.049-1.172 2.034 2.034 0 0 1-.074-1.012c-.194-4.699.174-31.627 30.903-62.355 28.277-28.277 53.346-30.475 60.356-30.475.766 0 1.34.024 1.71.048a1.92 1.92 0 0 1 1.07.203 2.012 2.012 0 0 1 1.146 2.207c.195 4.729-.186 31.629-30.903 62.346-28.275 28.275-53.345 30.473-60.357 30.473zm87.268-91.277c-6.712.014-30.362 2.202-57.462 29.303-27.133 27.132-29.605 51.364-29.739 57.974 6.713-.015 30.362-2.203 57.461-29.303 27.144-27.142 29.608-51.367 29.74-57.974z"
    />
    <path
      fill="#783741"
      d="M46.743 58.502a2.003 2.003 0 0 1-1.554-3.261c-.011-.373-.356-1.476-1.566-2.685-1.209-1.209-2.312-1.555-2.685-1.566a2 2 0 0 1-2.675-2.967c1.877-1.877 5.322-1.162 8.189 1.703 1.166 1.167 2.037 2.531 2.454 3.843.552 1.735.278 3.319-.75 4.348-.39.39-.902.585-1.413.585zm7.02-6.903a2 2 0 0 1-1.553-3.26c-.012-.373-.357-1.477-1.566-2.686-1.209-1.209-2.312-1.555-2.685-1.566a2.001 2.001 0 0 1-2.675-2.968c1.028-1.026 2.612-1.302 4.348-.749 1.312.418 2.676 1.29 3.841 2.455s2.037 2.528 2.454 3.84c.552 1.735.279 3.32-.749 4.348a2 2 0 0 1-1.415.586zm6.98-7.097a2 2 0 0 1-1.553-3.261c-.012-.373-.357-1.476-1.567-2.685-1.21-1.21-2.313-1.555-2.686-1.567a2 2 0 0 1-2.673-2.97c1.88-1.873 5.321-1.156 8.188 1.707 2.866 2.868 3.583 6.312 1.705 8.189-.39.392-.902.587-1.414.587z"
    />
    <path
      fill="#FFF"
      d="M53.885 105.281c-2.117-6.526-6.458-13.552-12.689-19.78-6.482-6.482-13.824-10.921-20.571-12.935l4.313-12.735c6.746 2.014 19.057 9.904 25.54 16.387 6.23 6.229 15.478 17.771 17.594 24.298l-14.187 4.765z"
    />
    <path
      fill="#783741"
      d="M53.885 107.281a2 2 0 0 1-1.902-1.383c-2.101-6.477-6.434-13.218-12.201-18.983-6.016-6.016-13.022-10.431-19.728-12.433a2.002 2.002 0 0 1-1.323-2.558l4.313-12.735a1.996 1.996 0 0 1 2.467-1.274c7.081 2.113 19.656 10.163 26.382 16.889 6.111 6.11 15.761 17.936 18.083 25.095a2 2 0 0 1-1.266 2.513l-14.188 4.766a2.022 2.022 0 0 1-.637.103zM23.166 71.297c6.711 2.407 13.545 6.892 19.443 12.79 5.649 5.646 10.034 12.209 12.504 18.672l10.323-3.468c-2.947-6.404-11.032-16.319-16.373-21.659-5.691-5.69-16.181-12.614-22.879-15.248l-3.018 8.913z"
    />
    <path
      fill="#FFF"
      d="M53.885 105.281c-2.117-6.526-6.458-13.552-12.689-19.78-6.482-6.482-13.824-10.921-20.571-12.935l4.313-12.735c6.746 2.014 19.057 9.904 25.54 16.387 6.23 6.229 15.478 17.771 17.594 24.298l-14.187 4.765z"
    />
    <path
      fill="#783741"
      d="M53.885 107.281a2 2 0 0 1-1.902-1.383c-2.101-6.477-6.434-13.218-12.201-18.983-6.016-6.016-13.022-10.431-19.728-12.433a2.002 2.002 0 0 1-1.323-2.558l4.313-12.735a1.996 1.996 0 0 1 2.467-1.274c7.081 2.113 19.656 10.163 26.382 16.889 6.111 6.11 15.761 17.936 18.083 25.095a2 2 0 0 1-1.266 2.513l-14.188 4.766a2.022 2.022 0 0 1-.637.103zM23.166 71.297c6.711 2.407 13.545 6.892 19.443 12.79 5.649 5.646 10.034 12.209 12.504 18.672l10.323-3.468c-2.947-6.404-11.032-16.319-16.373-21.659-5.691-5.69-16.181-12.614-22.879-15.248l-3.018 8.913z"
    />
    <path
      fill="#FFF"
      d="M111.09 48.075c-6.527-2.115-13.551-6.458-19.781-12.688-6.482-6.482-10.919-13.824-12.933-20.57L65.64 19.129c2.014 6.747 9.905 19.059 16.387 25.541 6.23 6.229 17.771 15.476 24.299 17.593l4.764-14.188z"
    />
    <path
      fill="#783741"
      d="M106.326 64.264c-.205 0-.413-.032-.618-.099-7.159-2.322-18.985-11.971-25.096-18.081-6.726-6.726-14.775-19.301-16.889-26.383a2.001 2.001 0 0 1 1.275-2.467l12.736-4.312a2.003 2.003 0 0 1 2.558 1.322c2.002 6.708 6.416 13.714 12.43 19.729 5.771 5.77 12.512 10.102 18.984 12.199a2 2 0 0 1 1.279 2.539l-4.765 14.188a2 2 0 0 1-1.894 1.365zM68.192 20.376c2.634 6.699 9.558 17.188 15.249 22.88 5.34 5.339 15.256 13.423 21.66 16.371l3.467-10.322c-6.458-2.468-13.021-6.852-18.673-12.503-5.898-5.897-10.381-12.731-12.788-19.443l-8.915 3.017z"
    />
  </svg>
);

export default Rugby;
