import React from "react";

const BycicleUse = props => (
  <svg viewBox="0 0 32 32" {...props}>
    <path
      fill="#FFD878"
      d="M29 16c0-7.18-5.82-13-13-13S3 8.82 3 16s5.82 13 13 13 13-5.82 13-13"
    />
    <path
      fill="#75EA9C"
      d="M13 23.5a5.5 5.5 0 1 0-11 0 5.5 5.5 0 1 0 11 0m17 0a5.5 5.5 0 1 0-11 0 5.5 5.5 0 1 0 11 0"
    />
    <path
      fill="#4D5152"
      d="M24.503 17.999c-.646 0-1.264.117-1.84.322l-1.27-2.752L22.796 13H25.5a.5.5 0 0 0 0-1H19a.5.5 0 0 0 0 1h2.658l-1.091 2H10.448l1.429-5H14a.5.5 0 0 0 0-1h-2.5a.5.5 0 0 0-.48.362l-2.496 8.735a5.501 5.501 0 0 0-6.526 5.399 5.5 5.5 0 0 0 5.505 5.497 5.5 5.5 0 0 0 5.504-5.497 5.496 5.496 0 0 0-3.522-5.124l.473-1.656 6.164 7.112.06.044.079.066.017.004.218.057.003.001h2.532a5.497 5.497 0 1 0 5.472-6.001m-12.502 5.506a4.498 4.498 0 1 1-4.499-4.498c.253 0 .498.034.74.074L7.02 23.363a.499.499 0 0 0 .343.617L7.5 24a.5.5 0 0 0 .48-.363l1.226-4.291a4.494 4.494 0 0 1 2.795 4.159M23.719 23h-3.672a4.473 4.473 0 0 1 2.135-3.335L23.719 23zm-7.313-.371L10.662 16h9.36l-3.616 6.629zm.936.371l3.456-6.336.957 2.077a5.49 5.49 0 0 0-2.724 4.26L17.342 23zm7.152 5.003c-2.313 0-4.198-1.755-4.448-4.003H24.5a.501.501 0 0 0 .454-.709l-1.861-4.039a4.456 4.456 0 0 1 1.401-.245 4.498 4.498 0 0 1 0 8.996"
    />
  </svg>
);

export default BycicleUse;
