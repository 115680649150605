import React from "react";

const Svg24HDelivery = props => (
  <svg viewBox="0 0 48 48"   {...props}>
    <circle fill="#F16362" cx={26} cy={24.004} r={19} />
    <path
      fill="#384D68"
      d="M13 7.004a1 1 0 1 0 0-2 1 1 0 1 0 0 2zm-9 14a1 1 0 1 0 0 2 1 1 0 1 0 0-2zm19-17a1 1 0 1 0 0-2 1 1 0 1 0 0 2zm3 20h4v3a1 1 0 1 0 2 0v-3h1a1 1 0 1 0 0-2h-1v-1a1 1 0 1 0-2 0v1h-2.483l2.402-5.606h-.001a1 1 0 0 0-1.837-.788l-.002.003-.001.003-2.997 6.994A1 1 0 0 0 26 24.004zm17-2c0-7.051-3.753-13.108-9.267-16.668A.986.986 0 0 0 33 5.004a1 1 0 0 0-.369 1.926C37.587 10.135 41 15.663 41 22.004c0 9.941-8.059 18-18 18-.224 0-.443-.015-.665-.025v-.008l2.383-2.359a.95.95 0 0 0 0-1.351.972.972 0 0 0-1.365 0l-4.093 4.053a.948.948 0 0 0 0 1.351l4.093 4.053a.972.972 0 0 0 1.365 0 .95.95 0 0 0 0-1.351l-2.413-2.389c.231.01.461.026.695.026 11.046 0 20-8.954 20-20zm-36 9a1 1 0 1 0 0 2 1 1 0 1 0 0-2zm15-4a1 1 0 0 0-1-1h-3.865l3.634-4.359S22 20.356 22 19.004a4 4 0 0 0-8 0h2c0-1.104.896-2 2-2s1.948.896 2 2c.031.672-.769 1.359-.769 1.359l-5 6s-.231.16-.231.641a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1zm-9 10a1 1 0 1 0 0 2 1 1 0 1 0 0-2zm-6-26a1 1 0 1 0 0 2 1 1 0 1 0 0-2z"
    />
  </svg>
);

export default Svg24HDelivery;
