import React from "react";

const Ufo2 = props => (
  <svg viewBox="0 0 64 38"   {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M12.5 38a.502.502 0 0 1-.44-.737l3.5-6.5a.5.5 0 0 1 .88.474l-3.5 6.5a.5.5 0 0 1-.44.263m39 0a.498.498 0 0 1-.44-.263l-3.5-6.5a.5.5 0 1 1 .88-.474l3.5 6.5a.5.5 0 0 1-.44.737"
        fill="#f2b430"
      />
      <path
        d="M42.704 7.681C55.178 9.345 63 13.79 63 18c0 5.312-12.457 11-31 11S1 23.312 1 18c0-4.211 7.829-8.659 20.312-10.321l.235-1.024C9.007 8.279 0 12.745 0 18c0 6.627 14.327 12 32 12 17.673 0 32-5.373 32-12 0-5.259-9.021-9.728-21.576-11.349l.28 1.03z"
        fill="#1d7ed0"
      />
      <path
        d="M63 18v3c0 5.312-12.457 11-31 11S1 26.312 1 21v-3H0v3c0 6.627 14.327 12 32 12 17.673 0 32-5.373 32-12v-3h-1z"
        fill="#1d7ed0"
      />
      <path
        d="M40.5 12a.5.5 0 0 1-.5-.5C40 7.294 36.706 4 32.5 4a.5.5 0 0 1 0-1c4.767 0 8.5 3.733 8.5 8.5a.5.5 0 0 1-.5.5"
        fill="#f2b430"
      />
      <path
        d="M32 0c-6.627 0-12 5.404-12 11.5 0 3 6 4.5 12 4.5s12-1.5 12-4.5C44 5.494 38.627 0 32 0m0 1c5.86 0 11 4.907 11 10.5 0 1.93-4.935 3.5-11 3.5s-11-1.57-11-3.5C21 5.808 26.037 1 32 1"
        fill="#f2b430"
      />
    </g>
  </svg>
);

export default Ufo2;
