import React from "react";

const GloveFan = props => (
  <svg viewBox="0 0 128 128"   {...props}>
    <ellipse fill="#B8CBCD" cx={64.884} cy={115.147} rx={47.667} ry={10.186} />
    <path
      fill="#F6E89A"
      d="M80.551 55.667l-26-2.333v-32.5c0-8.008-6.492-14.5-14.5-14.5s-14.5 6.492-14.5 14.5v92.5c0 2.209 15.446 4 34.5 4 19.054 0 34.5-1.791 34.5-4V69.667c0-7.731-6.268-14-14-14z"
    />
    <path
      fill="#FFF"
      d="M33.657 100.938c-4.7-1.069-6.106-1.748-6.106-1.748v12.644s2.083.881 6.104 1.76c.032-4.342-.064-8.315.002-12.656z"
    />
    <path
      fill="#8D4520"
      d="M60.051 118.334c-9.29 0-18.035-.421-24.626-1.185-9.931-1.152-11.874-2.638-11.874-4.815v-91.5c0-9.098 7.402-16.5 16.5-16.5h10.5a2 2 0 0 1 0 4h-10.5c-6.893 0-12.5 5.607-12.5 12.5v90.738c2.702 1.225 14.686 2.762 32.5 2.762a2 2 0 0 1 0 4z"
    />
    <path
      fill="#F6E89A"
      d="M84.551 44.334s-7-1-7 7c-2.736-2.249 3-13-7-13-8 0-6 15-6 15v-32.5c0-8.008-6.492-14.5-14.5-14.5s-14.5 6.492-14.5 14.5v92.5c0 2.209 15.446 4 34.5 4 19.054 0 34.5-1.791 34.5-4V69.667c0-2.116 1-17.333-6-17.333-4.858 0-5.877 2.754-7 5 0-12-4.095-13-7-13z"
    />
    <path
      fill="#FFF"
      d="M35.726 99.738v13.533s8.223 3.5 34.95 3.5 33.875-3.625 33.875-3.625v-13s-3.438 3.5-34 3.5-34.825-3.908-34.825-3.908z"
    />
    <path
      fill="#8D4520"
      d="M70.051 119.334c-34.736 0-36.5-1.805-36.5-4.75v-93.75c0-9.098 7.402-16.5 16.5-16.5 9.098 0 16.5 7.402 16.5 16.5v16.392a8.614 8.614 0 0 1 4.667-1.367c4.454 0 7.882 3.203 8.292 7.585a9.467 9.467 0 0 1 5.041-1.431c4.963 0 9 3.732 9 8.32v1.019a8.649 8.649 0 0 1 4.333-1.159c4.779 0 8.667 3.888 8.667 8.666v54.475c0 2.178-1.943 3.663-11.874 4.815-6.591.764-15.336 1.185-24.626 1.185zm-32.5-5.606c1.933.682 8.907 1.605 32.5 1.605 17.813 0 29.798-1.537 32.5-2.762V58.859a4.672 4.672 0 0 0-4.667-4.666 4.66 4.66 0 0 0-4.124 2.485 2 2 0 0 1-1.973 1.052 2.18 2.18 0 0 1-1.554-.892 1.998 1.998 0 0 1-.683-1.505v-5c0-2.668-2.596-4.32-5-4.32s-5 1.652-5 4.32a2 2 0 0 1-4 0v-6c0-2.635-1.782-4.475-4.333-4.475a4.673 4.673 0 0 0-4.667 4.667 2 2 0 0 1-4 0V20.834c0-6.893-5.607-12.5-12.5-12.5s-12.5 5.607-12.5 12.5v92.894z"
    />
    <path
      fill="#8D4520"
      d="M35.554 102.938c-.089 0-.179-.006-.27-.018-11.733-1.578-11.733-3.993-11.733-5.024a2 2 0 0 1 3.747-.974c.876.47 3.871 1.408 8.52 2.033a2.001 2.001 0 0 1-.264 3.983z"
    />
    <path
      fill="#8D4520"
      d="M70.051 105.334c-17.53 0-33.94-1.268-36.579-2.825a2 2 0 1 1 2.033-3.445c1.635.78 14.983 2.271 34.546 2.271 18.277 0 30.418-1.618 32.692-2.856a2 2 0 0 1 3.808.856c0 2.178-1.943 3.663-11.875 4.815-6.59.763-15.335 1.184-24.625 1.184zm20.265-10H75.339a2 2 0 0 1-2-2c0-3.94 3.609-7.245 7.43-10.745 3.168-2.901 6.759-6.189 6.759-8.961 0-3.514-2.979-5.094-5.094-5.094-1.768 0-5.883.573-5.883 5.883a2 2 0 0 1-4 0c0-7.295 5.324-9.883 9.883-9.883 4.47 0 9.094 3.401 9.094 9.094 0 4.532-4.096 8.283-8.057 11.911-2.067 1.894-4.333 3.968-5.442 5.795h12.287a2 2 0 0 1 0 4zm-26.765 0a2 2 0 0 1-2-2v-6h-10a2.002 2.002 0 0 1-1.691-3.068l12-19a2 2 0 0 1 3.691 1.068v17h4a2 2 0 0 1 0 4h-4v6a2 2 0 0 1-2 2zm-8.371-12h6.371V73.246L55.18 83.334z"
    />
    <path
      fill="#8D4520"
      d="M50.551 6.334l.002.025a14.473 14.473 0 0 1 13.998 14.476v23.692a6.666 6.666 0 0 1 6.667-6.667c3.682 0 6.333 2.793 6.333 6.475v6c0-1.193.353-2.275.95-3.195a5.268 5.268 0 0 1 2.75-2.377 7.65 7.65 0 0 1 3.3-.748c3.682 0 7 2.639 7 6.32v5c.259.012.187.381.442.407a6.666 6.666 0 0 1 12.558 3.118v5.402c.109 2.554 0 4.703 0 5.405v43.667c0 2.209-15.446 4-34.5 4-2.159 0-4.269-.008-6.317-.023-1.21.016-2.438.023-3.683.023-19.054 0-34.5-1.791-34.5-4v-92.5c0-8.008 6.492-14.5 14.5-14.5h10.5m0-4h-10.5c-10.201 0-18.5 8.299-18.5 18.5v92.5c0 4.827 6.18 5.937 13.644 6.802 6.664.772 15.491 1.198 24.856 1.198 1.247 0 2.479-.008 3.693-.023 2.046.016 4.151.023 6.307.023 9.364 0 18.191-.426 24.855-1.198 7.465-.865 13.645-1.975 13.645-6.802V69.667c0-.147.006-.367.014-.647.026-1.054.07-2.807-.014-4.842V58.86c0-5.881-4.785-10.666-10.667-10.666-.857 0-1.698.102-2.509.298-.929-4.814-5.43-8.477-10.824-8.477-1.23 0-2.462.198-3.629.58-1.424-4.015-5.126-6.734-9.704-6.734-.921 0-1.815.117-2.667.337V20.834c0-2.495-.489-4.918-1.455-7.202a18.53 18.53 0 0 0-1.707-3.146 18.555 18.555 0 0 0-14.199-8.1 3.964 3.964 0 0 0-.639-.052z"
    />
    <path
      opacity={0.2}
      fill="#8D4520"
      d="M39.551 118.552a3 3 0 0 1-3-3V20.834c0-9.486 8.3-17.5 18.125-17.5a3 3 0 1 1 0 6c-6.572 0-12.125 5.266-12.125 11.5v94.718a3 3 0 0 1-3 3z"
    />
  </svg>
);

export default GloveFan;
