import React from "react";

const Comet = props => (
  <svg viewBox="0 0 64 64"   {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M48.5 64c-4.14 0-8.032-1.612-10.96-4.54L.146 22.854a.502.502 0 0 1 .708-.708l37.393 36.607A14.404 14.404 0 0 0 48.5 63c3.873 0 7.514-1.508 10.253-4.247A14.403 14.403 0 0 0 63 48.5c0-3.873-1.508-7.515-4.247-10.253L32.146 11.854a.502.502 0 0 1 .708-.708L59.46 37.54A15.396 15.396 0 0 1 64 48.5c0 4.141-1.612 8.032-4.54 10.96A15.398 15.398 0 0 1 48.5 64"
        fill="#f2b430"
      />
      <path
        d="M36.5 32a.502.502 0 0 1-.354-.146l-31-31a.502.502 0 0 1 .708-.708l31 31A.502.502 0 0 1 36.5 32m2 13a.502.502 0 0 1-.354-.146l-23-23a.502.502 0 0 1 .708-.708l23 23A.502.502 0 0 1 38.5 45m9 8c-1.654 0-3-1.346-3-3s1.346-3 3-3a.5.5 0 0 1 0 1c-1.103 0-2 .897-2 2s.897 2 2 2a.5.5 0 0 1 0 1m7.929-5.363a2.985 2.985 0 0 1-1.43.363c-1.653 0-3-1.346-3-3 0-.724.259-1.389.687-1.908l-.981-.36a4 4 0 0 0 4.825 5.966l-.101-1.06zM52 51a1 1 0 1 1-2 0 1 1 0 0 1 2 0m-5-6.5a1.5 1.5 0 0 1-3 0c0-.462.579-.325.908-.6.261-.218.226-.9.592-.9a1.5 1.5 0 0 1 1.5 1.5"
        fill="#f2b430"
      />
      <path
        d="M49 42a7 7 0 1 0 0 14 7 7 0 0 0 0-14m0 1c3.308 0 6 2.692 6 6s-2.692 6-6 6-6-2.692-6-6 2.692-6 6-6"
        fill="#1d7ed0"
      />
    </g>
  </svg>
);

export default Comet;
