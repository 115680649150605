import React from "react";

const MoonWalker2 = props => (
  <svg viewBox="0 0 64 52"   {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M63 24H1l-.5-1h63l-.5 1z" fill="#f2b430" />
      <path
        d="M12 36a8 8 0 1 0 0 16 8 8 0 0 0 0-16m0 1c3.86 0 7 3.14 7 7s-3.14 7-7 7-7-3.14-7-7 3.14-7 7-7"
        fill="#1d7ed0"
      />
      <path
        d="M12 40a4 4 0 1 0 0 8 4 4 0 0 0 0-8m0 1c1.654 0 3 1.346 3 3s-1.346 3-3 3-3-1.346-3-3 1.346-3 3-3m20-1a4 4 0 1 0 0 8 4 4 0 0 0 0-8m0 1c1.654 0 3 1.346 3 3s-1.346 3-3 3-3-1.346-3-3 1.346-3 3-3m20-1a4 4 0 1 0 0 8 4 4 0 0 0 0-8m0 1c1.654 0 3 1.346 3 3s-1.346 3-3 3-3-1.346-3-3 1.346-3 3-3"
        fill="#f2b430"
      />
      <path
        d="M52 36a8 8 0 1 0 0 16 8 8 0 0 0 0-16m0 1c3.86 0 7 3.14 7 7s-3.14 7-7 7-7-3.14-7-7 3.14-7 7-7m-20-1a8 8 0 1 0 0 16 8 8 0 0 0 0-16m0 1c3.86 0 7 3.14 7 7s-3.14 7-7 7-7-3.14-7-7 3.14-7 7-7m10-18h16v-4H42v4zm1-1h14v-2H43v2zM7 19h5v-6H7v6zm1-1h3v-4H8v4z"
        fill="#1d7ed0"
      />
      <path d="M28 15.5h1V9h-1v6.5zm3 0h1V9h-1v6.5z" fill="#f2b430" />
      <path
        d="M33.5 19c0-1.93-1.57-3.5-3.5-3.5s-3.5 1.57-3.5 3.5h-1c0-2.481 2.019-4.5 4.5-4.5s4.5 2.019 4.5 4.5h-1z"
        fill="#1d7ed0"
      />
      <path
        d="M61.323 19l1.64 4.1-2.684 14.056a.996.996 0 0 1-.988.844h-2.73l.993 1h1.737c.984 0 1.822-.716 1.976-1.688L64 23l-2-5H3l-3 5 2.733 14.312A2.001 2.001 0 0 0 4.709 39h1.663l.994-1H4.709c-.495 0-.911-.355-.993-.876L1.054 23.187 3.566 19h57.757zM17.525 39h8.91l.929-1H16.596l.929 1zm20.064 0h8.876l.866-1H36.69l.899 1zM9 13h1V8H9v5z"
        fill="#1d7ed0"
      />
      <path
        d="M47.5 27h12c.275 0 .5-.225.5-.5s-.225-.5-.5-.5h-12c-.275 0-.5.225-.5.5s.225.5.5.5"
        fill="#f2b430"
      />
      <path
        d="M11 7.5a1.5 1.5 0 1 0-3.001.001A1.5 1.5 0 0 0 11 7.5"
        fill="#1d7ed0"
      />
      <path
        d="M14 8a.5.5 0 0 1-.5-.5c0-2.206-1.794-4-4-4s-4 1.794-4 4a.5.5 0 0 1-1 0c0-2.757 2.243-5 5-5s5 2.243 5 5a.5.5 0 0 1-.5.5"
        fill="#f2b430"
      />
      <path
        d="M16 8a.5.5 0 0 1-.5-.5c0-3.309-2.691-6-6-6s-6 2.691-6 6a.5.5 0 0 1-1 0c0-3.859 3.14-7 7-7s7 3.141 7 7a.5.5 0 0 1-.5.5m4 20H9a2 2 0 1 0 0 4h11a2 2 0 1 0 0-4m0 1c.551 0 1 .449 1 1 0 .551-.449 1-1 1H9c-.551 0-1-.449-1-1 0-.551.449-1 1-1h11m6-1a2 2 0 1 0-.001 3.999A2 2 0 0 0 26 28m0 1c.551 0 1 .449 1 1 0 .551-.449 1-1 1-.551 0-1-.449-1-1 0-.551.449-1 1-1m23-17l-1-1v4h1v-3zm2 3h1v-3h-1v3z"
        fill="#f2b430"
      />
      <path
        d="M47.757 2.757a6 6 0 0 0 8.485 8.485l-8.485-8.485zm.071 1.485l6.93 6.93A4.963 4.963 0 0 1 52 12a4.965 4.965 0 0 1-3.536-1.465 5.01 5.01 0 0 1-.636-6.293zM55 5a1 1 0 1 1-2 0 1 1 0 0 1 2 0"
        fill="#1d7ed0"
      />
      <path
        d="M52.353 7.354l-.706-.708 2-2 .706.708-2 2zM37 0H26c-1.1 0-2 .9-2 2v6c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V2c0-1.1-.9-2-2-2m0 1c.551 0 1 .449 1 1v6c0 .551-.449 1-1 1H26c-.551 0-1-.449-1-1V2c0-.551.449-1 1-1h11"
        fill="#1d7ed0"
      />
      <path
        d="M33 2a3 3 0 1 0 0 6 3 3 0 0 0 0-6m0 1c1.103 0 2 .897 2 2s-.897 2-2 2-2-.897-2-2 .897-2 2-2m-6 6h1V1h-1v8z"
        fill="#1d7ed0"
      />
    </g>
  </svg>
);

export default MoonWalker2;
