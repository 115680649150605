import React from "react";

const LabFlaskLeaf = props => (
  <svg viewBox="0 0 874.633 1010"   {...props}>
    <path
      fill="#F3F5F6"
      d="M285.102 34.477c-29.345 0-53.112 23.805-53.112 53.112 0 29.306 23.767 53.111 53.112 53.111v177.026C125.787 353.146 19.563 505.45 19.563 654.115c0 185.736 150.566 336.321 336.341 336.321 185.776 0 336.322-150.585 336.322-336.321 0-148.665-106.184-300.969-265.538-336.39V140.7c29.402 0 53.15-23.805 53.15-53.111 0-29.307-23.748-53.112-53.15-53.112H285.102"
    />
    <path
      fill="#78909C"
      d="M19.563 588.164C5.655 833.872 170.13 990.436 355.904 990.436c185.776 0 342.034-160.69 336.322-402.272"
    />
    <path
      fill="#AEBCC4"
      d="M454.81 726.732c18.245 0 32.976 14.73 32.976 32.955 0 18.189-14.73 32.976-32.976 32.976-18.226 0-32.956-14.787-32.956-32.976 0-18.225 14.73-32.955 32.956-32.955zm-131.842 65.93c18.207 0 32.936 14.73 32.936 32.937 0 18.207-14.73 32.995-32.936 32.995-18.264 0-32.975-14.788-32.975-32.995 0-18.207 14.71-32.936 32.975-32.936zm-65.95-131.861c18.226 0 32.975 14.73 32.975 32.975 0 18.189-14.75 32.956-32.976 32.956-18.245 0-32.975-14.767-32.975-32.956 0-18.245 14.73-32.975 32.975-32.975z"
    />
    <g fill="#43525B">
      <path d="M446.251 302.423V157.589c30.625-8.559 53.15-36.7 53.15-70 0-40.082-32.612-72.675-72.713-72.675H285.102c-40.082 0-72.675 32.593-72.675 72.675 0 33.3 22.506 61.441 53.111 70v144.834C113.1 344.072 0 492.44 0 654.115 0 850.359 159.66 1010 355.904 1010c196.245 0 355.885-159.64 355.885-355.885 0-161.694-113.1-310.043-265.538-351.692zM251.554 87.589c0-18.494 15.054-33.549 33.548-33.549h141.586c18.513 0 33.586 15.055 33.586 33.549 0 18.493-15.073 33.548-33.586 33.548H285.102c-18.494 0-33.548-15.055-33.548-33.548zm104.35 883.284c-174.676 0-316.777-142.102-316.777-316.758 0-149.162 107.579-285.57 250.216-317.284 8.96-2.007 15.322-9.935 15.322-19.105V160.263h102.46v157.463c0 9.17 6.361 17.099 15.322 19.105 142.636 31.695 250.216 168.103 250.216 317.284 0 174.656-142.102 316.758-316.759 316.758z" />
      <path d="M331.144 411.493c-120.13 0-217.871 97.72-217.871 217.843 0 10.812 8.75 19.562 19.563 19.562 10.813 0 19.563-8.75 19.563-19.562 0-98.543 80.183-178.717 178.745-178.717 10.814 0 19.564-8.75 19.564-19.563 0-10.813-8.75-19.563-19.564-19.563z" />
    </g>
    <path
      fill="#FFAB00"
      d="M742.912 23.282c56.186-5.273 100.625-3.344 102.497-3.248 3.228.153 6.228 1.853 7.986 4.547a10.137 10.137 0 0 1 1.031 9.17c-.65 1.758-16.41 43.368-43.444 92.888-36.644 67.096-74.661 113.98-113.024 139.236-49.482 32.612-96.67 26.633-127.449 15.82-32.975-11.502-54.239-30.53-55.118-31.352-.648-.592-1.222-1.204-1.68-1.93-.478-.688-.841-1.49-1.109-2.292-.42-1.147-9.514-28.199-7.088-63.027 2.255-32.574 15.323-78.234 64.824-110.847 38.381-25.275 96.44-41.763 172.574-48.965z"
    />
    <path
      fill="#43525B"
      d="M869.787 13.882C864.59 5.953 855.84.948 846.403.49c-1.91-.095-45.947-2.254-105.325 3.325-79.324 7.489-140.402 25.027-181.497 52.099-56.187 37.006-71.032 88.856-73.592 125.825-2.235 32.363 4.242 58.002 6.916 66.924L423.21 297.57c-8.846 6.21-10.986 18.398-4.777 27.244 3.802 5.425 9.858 8.33 16.03 8.33a19.41 19.41 0 0 0 11.214-3.554l73.744-51.755c10.72 7.145 25.946 15.8 44.61 22.314 18.302 6.439 36.988 9.706 55.52 9.706 31.35 0 61.345-9.304 89.161-27.645 41.019-26.995 81.215-76.19 119.444-146.19 28.103-51.488 44.457-95.066 44.591-95.39 3.325-8.904 2.198-18.934-2.961-26.748zm-75.98 103.377c-35.115 64.326-70.994 108.82-106.625 132.281-34.082 22.487-72.369 26.996-110.234 13.68-8.54-2.98-15.8-6.515-22.354-10.05l21.399-15.035 74.279 11.176c.974.134 1.968.21 2.923.21 9.534 0 17.863-6.954 19.335-16.659 1.603-10.68-5.751-20.652-16.45-22.257l-33.644-5.063 125.309-87.92c8.845-6.21 10.986-18.399 4.776-27.244-6.19-8.845-18.379-11.004-27.243-4.776l-132.78 93.174 12.132-46.272c2.732-10.45-3.514-21.149-13.966-23.9-10.45-2.617-21.148 3.515-23.9 13.966l-22.314 85.15c-.44 1.681-.287 3.324-.287 4.967l-16.792 11.788c-1.834-9.935-3.458-24.053-2.332-40.025 1.95-28.16 13.26-67.669 56.054-95.868C616.8 65.064 671.86 49.646 744.746 42.77c37.79-3.573 69.598-3.783 86.43-3.535-6.553 15.8-19.43 45.164-37.369 78.025z"
    />
  </svg>
);

export default LabFlaskLeaf;
