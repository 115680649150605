import React from "react";

const Magnet = props => (
  <svg viewBox="0 0 1009.801 1010"   {...props}>
    <path
      fill="#FFAB00"
      d="M879.904 414.556L518.63 775.832c-78.607 78.587-206.033 78.587-284.64 0L124.506 885.304c139.073 139.042 364.534 139.042 503.615 0L989.397 524.03 879.904 414.556z"
    />
    <path
      fill="#78909C"
      d="M233.989 491.172l361.275-361.296L485.79 20.404 124.506 381.68c-139.062 139.102-139.062 364.543 0 503.625L233.99 775.832c-78.588-78.627-78.607-206.053 0-284.66z"
    />
    <path
      fill="#FFF"
      d="M901.803 611.603L792.33 502.131l87.574-87.575L989.396 524.03zM507.67 217.47L398.207 107.998l87.584-87.594 109.473 109.472z"
    />
    <g fill="#43525B">
      <path d="M1003.822 509.602L894.33 400.13c-7.97-7.97-20.883-7.97-28.853 0L504.204 761.405c-34.164 34.153-79.585 52.963-127.895 52.963s-93.73-18.81-127.884-52.963c-70.517-70.537-70.517-185.29-.01-255.808l361.274-361.295c7.971-7.97 7.971-20.882 0-28.852L500.218 5.977c-7.97-7.97-20.883-7.97-28.853 0L110.08 367.253c-146.773 146.813-146.773 385.684 0 532.477C181.195 970.826 275.744 1010 376.31 1010c100.565 0 195.122-39.174 266.237-110.27l361.275-361.275a20.386 20.386 0 0 0 5.979-14.426c0-5.42-2.153-10.6-5.979-14.427zM613.695 870.878c-63.404 63.383-147.72 98.313-237.386 98.313s-173.972-34.93-237.376-98.313c-130.863-130.893-130.863-343.86 0-474.773L485.792 49.257l80.62 80.62-346.85 346.868c-86.418 86.419-86.418 227.055 0 313.513 41.875 41.864 97.538 64.919 156.747 64.919s114.882-23.055 156.746-64.92l346.85-346.848 80.639 80.62-346.849 346.849z" />
      <path d="M401.864 237.735l-28.853-28.853-21.15 21.141 28.852 28.853zM444.147 195.452l-28.853-28.853-21.151 21.142 28.853 28.852zM359.541 280.057l-28.812-28.892-21.171 21.12 28.813 28.893zM750.924 629.058l28.853 28.852 21.121-21.14-28.853-28.853zM793.187 586.756L822 615.648l21.18-21.142-28.813-28.892zM708.582 671.32l28.813 28.893 21.2-21.121-28.812-28.893z" />
    </g>
  </svg>
);

export default Magnet;
