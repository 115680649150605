import React from "react";

const KeyPass = props => (
  <svg viewBox="0 0 48 48"   {...props}>
    <path
      fill="#FFDA8E"
      d="M44.369 40.702l-2.062-3.394a1.052 1.052 0 0 0-1.394-.367l-2.633 1.443-1.031-1.697 2.633-1.443a.945.945 0 0 0 .362-1.329l-2.063-3.394a1.052 1.052 0 0 0-1.394-.368l-2.633 1.443-5.798-9.54a11.944 11.944 0 0 0 3.132-8.064c0-6.627-5.373-12-12-12s-12 5.373-12 12 5.373 12 12 12c1.348 0 2.641-.232 3.85-.643l12.254 20.163a1.05 1.05 0 0 0 1.393.367l7.021-3.849a.942.942 0 0 0 .363-1.328z"
    />
    <path
      fill="#384D68"
      d="M40.369 40.702l-2.062-3.394a1.052 1.052 0 0 0-1.394-.367l-2.633 1.443-1.031-1.697 2.633-1.443a.945.945 0 0 0 .362-1.329l-2.063-3.394a1.052 1.052 0 0 0-1.394-.368l-2.633 1.443-5.798-9.54a11.944 11.944 0 0 0 3.132-8.064c0-6.627-5.373-12-12-12s-12 5.373-12 12 5.373 12 12 12c1.348 0 2.641-.232 3.85-.643l12.254 20.163a1.05 1.05 0 0 0 1.393.367l7.021-3.849a.942.942 0 0 0 .363-1.328zm-7.536 3L20.131 22.846a9.946 9.946 0 0 1-4.641 1.146c-5.523 0-10-4.478-10-10s4.477-10 10-10 10 4.478 10 10a9.978 9.978 0 0 1-3.688 7.753l7.114 11.662a1.05 1.05 0 0 0 1.393.367l2.633-1.442 1.031 1.696-2.633 1.443a.944.944 0 0 0-.361 1.329l2.063 3.395c.284.468.908.633 1.393.367l2.633-1.443 1.031 1.697-5.266 2.886zM15.49 5.992a8 8 0 1 0 0 16 8 8 0 0 0 0-16zm0 14a6 6 0 1 1 0-12 6 6 0 0 1 0 12z"
    />
  </svg>
);

export default KeyPass;
