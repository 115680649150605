import React from "react";

const Constellation = props => (
  <svg viewBox="0 0 64 46"   {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M31 31a3 3 0 1 0 0 6 3 3 0 0 0 0-6m0 1c1.103 0 2 .897 2 2s-.897 2-2 2-2-.897-2-2 .897-2 2-2m25 8a3 3 0 1 0 0 6 3 3 0 0 0 0-6m0 1c1.103 0 2 .897 2 2s-.897 2-2 2-2-.897-2-2 .897-2 2-2M40 12a3 3 0 1 0 0 6 3 3 0 0 0 0-6m0 1c1.103 0 2 .897 2 2s-.897 2-2 2-2-.897-2-2 .897-2 2-2M2.5 1a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5m0 1C3.327 2 4 2.673 4 3.5S3.327 5 2.5 5 1 4.327 1 3.5 1.673 2 2.5 2M60 20a4 4 0 1 0 0 8 4 4 0 0 0 0-8m0 1c1.654 0 3 1.346 3 3s-1.346 3-3 3-3-1.346-3-3 1.346-3 3-3M28.5 0a3.5 3.5 0 1 0 0 7 3.5 3.5 0 0 0 0-7m0 1C29.878 1 31 2.121 31 3.5S29.878 6 28.5 6A2.503 2.503 0 0 1 26 3.5C26 2.121 27.122 1 28.5 1"
        fill="#f2b430"
      />
      <path
        d="M37 12.5a.502.502 0 0 1-.354-.146l-5-5a.502.502 0 0 1 .708-.708l5 5A.502.502 0 0 1 37 12.5m-4 18a.5.5 0 0 1-.455-.707l5-11a.5.5 0 0 1 .91.414l-5 11A.5.5 0 0 1 33 30.5m22-8a.511.511 0 0 1-.158-.025l-12-4a.502.502 0 0 1 .316-.95l12 4A.502.502 0 0 1 55 22.5m2.001 17a.498.498 0 0 1-.491-.598l2-10a.503.503 0 0 1 .588-.392.498.498 0 0 1 .392.588l-2 10a.5.5 0 0 1-.489.402M52 42.5a.503.503 0 0 1-.166-.028l-17-6a.501.501 0 0 1 .332-.944l17 6A.501.501 0 0 1 52 42.5M23.5 4h-17a.5.5 0 0 1 0-1h17a.5.5 0 0 1 0 1"
        fill="#1d7ed0"
      />
    </g>
  </svg>
);

export default Constellation;
