import React from "react";

const BeakerAtom = props => (
  <svg viewBox="0 0 638.601 1010"   {...props}>
    <path
      fill="#EBEEF0"
      d="M437.986 169.121c0 65.543-53.125 118.669-118.686 118.669s-118.687-53.126-118.687-118.67c0-65.56 53.126-118.686 118.687-118.686S437.986 103.56 437.986 169.12z"
    />
    <path
      fill="#FFAB00"
      d="M348.172 169.103c0 15.945-12.946 28.854-28.872 28.854-15.927 0-28.872-12.91-28.872-28.854 0-15.927 12.945-28.835 28.872-28.835 15.926 0 28.872 12.908 28.872 28.835z"
    />
    <path
      fill="#43525B"
      d="M485.676 13.627C479.876 7.409 469.04 0 450.349 0c-32.107 0-80.16 22.78-131.067 60.525C268.41 22.78 220.376 0 188.25 0c-18.69 0-29.527 7.409-35.326 13.618-29.144 31.198 1.273 94.36 51.725 155.503-16.436 19.945-30.344 39.662-40.907 58.19-31.854 55.888-23.472 83.75-10.836 97.268 5.8 6.218 16.653 13.627 35.344 13.627h.018c32.09 0 80.124-22.772 131.014-60.498 50.925 37.726 98.942 60.498 131.031 60.498 18.708 0 29.544-7.418 35.29-13.6 5.508-5.872 11.945-16.5 11.364-33.826-1.037-30.653-24.51-74.906-63.053-121.668 50.47-61.143 80.906-124.314 51.762-155.485zm-35.327 23.608c4.746 0 7.328.964 8.11 1.809 6.781 7.254-5.946 47.235-49.526 101.715a688.19 688.19 0 0 0-32.671-32.672 690.473 690.473 0 0 0-26.49-23.544c43.634-30.963 80.815-47.308 100.577-47.308zm-65.98 132.022a637.509 637.509 0 0 1-33.452 33.681c-10.71 10-21.236 18.99-31.635 27.499-10.4-8.509-20.927-17.499-31.636-27.499a634.208 634.208 0 0 1-33.544-33.799c10.472-11.363 21.654-22.717 33.58-33.853 10.691-9.99 21.2-18.972 31.582-27.472 10.38 8.51 20.89 17.49 31.58 27.48 12.308 11.492 23.236 22.81 33.526 33.963zM180.124 39.035c.782-.836 3.382-1.8 8.127-1.8 19.762 0 56.925 16.336 100.523 47.308a682.32 682.32 0 0 0-26.508 23.544c-11.472 10.718-22.344 21.663-32.635 32.662-43.562-54.47-56.289-94.46-49.507-101.714zm8.145 261.936h-.018c-4.745 0-7.345-.964-8.127-1.8-1.891-2.036-4.745-17.054 15.98-53.416 8.746-15.327 20.164-31.645 33.527-48.28 10.49 11.217 21.4 22.199 32.599 32.672a698.049 698.049 0 0 0 26.544 23.562c-43.616 30.935-80.76 47.262-100.505 47.262zm271.482-8.945c.127 4.073-.582 6.354-1.346 7.163-.764.828-3.346 1.782-8.09 1.782-19.727 0-56.89-16.327-100.507-47.271a697.274 697.274 0 0 0 26.527-23.563c11.164-10.436 22.053-21.38 32.508-32.562 32.617 40.635 50.271 75.46 50.908 94.45z"
    />
    <path
      fill="#D6DDE1"
      d="M199.431 522.018v78.942L18.62 991.383H619.98L437.84 600.96v-78.942"
    />
    <path
      fill="#FFAB00"
      d="M149.215 710.81L18.619 991.384H619.98L490.166 710.81"
    />
    <path
      fill="#FFD580"
      d="M419.696 804.59c18.509 0 33.417 14.944 33.417 33.453 0 18.472-14.908 33.453-33.417 33.453-18.526 0-33.49-14.981-33.49-33.453 0-18.509 14.964-33.453 33.49-33.453zm-133.85 66.906c18.472 0 33.454 14.98 33.454 33.453 0 18.473-14.982 33.49-33.454 33.49-18.508 0-33.453-15.017-33.453-33.49 0-18.472 14.945-33.453 33.453-33.453zM218.94 737.683c18.508 0 33.453 14.944 33.453 33.453 0 18.454-14.945 33.454-33.453 33.454-18.527 0-33.454-15-33.454-33.454 0-18.509 14.927-33.453 33.454-33.453z"
    />
    <path
      fill="#43525B"
      d="M636.815 983.419l-178.248-377.15V410.022h32.945c10.29 0 18.617-8.336 18.617-18.617s-8.326-18.617-18.617-18.617H439.95c-1.128 0-2.09.445-3.163.636-1.073-.191-2.037-.636-3.164-.636H147.887c-10.29 0-18.617 8.336-18.617 18.617s8.327 18.617 18.617 18.617h32.927V606.27L1.802 983.4a18.62 18.62 0 0 0 1.073 17.928A18.64 18.64 0 0 0 18.619 1010H619.98a18.635 18.635 0 0 0 15.727-8.654 18.615 18.615 0 0 0 1.108-17.927zM421.332 410.02v185.666H218.049V566.67h64.997v-37.234H218.05v-12.963h64.997v-37.236H218.05V466.31h64.997v-37.234H218.05V410.02h203.283zM48.073 972.766l161.303-339.843H429.97l160.61 339.843H48.073z"
    />
  </svg>
);

export default BeakerAtom;
