import React from "react";

const Telescope = props => (
  <svg viewBox="0 0 59 64"   {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M28.278 45.65l-5.929-2.372.372-.928 5.93 2.372-.373.928z"
        fill="#1d7ed0"
      />
      <path
        d="M28.722 45.65l-.372-.928 5.929-2.372.372.928-5.93 2.372z"
        fill="#1d7ed0"
      />
      <path
        d="M16.5 59a.5.5 0 0 1-.463-.688l12-29.5a.5.5 0 0 1 .926.376l-12 29.5A.499.499 0 0 1 16.5 59"
        fill="#f2b430"
      />
      <path
        d="M40.5 59a.499.499 0 0 1-.463-.312l-12-29.5a.5.5 0 1 1 .926-.376l12 29.5A.5.5 0 0 1 40.5 59"
        fill="#f2b430"
      />
      <path
        d="M28.5 64a.5.5 0 0 1-.5-.5V29a.5.5 0 0 1 1 0v34.5a.5.5 0 0 1-.5.5M8.967 31.196l-8.967 5 2 3.464 8.967-5-2-3.464zm-.373 1.353l.999 1.732-7.22 4.026-1-1.732 7.22-4.026z"
        fill="#f2b430"
      />
      <path
        d="M45.072 5.732L6.1 28.232l6 10.392 38.97-22.5-6-10.392zm-.366 1.366l5 8.66-37.24 21.5-5-8.66 37.24-21.5z"
        fill="#1d7ed0"
      />
      <path d="M46.242 17.758l-.866.5-5-8.66.866-.5 5 8.66z" fill="#f2b430" />
      <path
        d="M48.402 1.5l-5.196 3 8 13.856 5.196-3-8-13.856zm-.366 1.366l7 12.124-3.464 2-7-12.124 3.464-2z"
        fill="#1d7ed0"
      />
      <path
        d="M51 0l-3.464 2 8 13.856 3.464-2L51 0zm-.366 1.366l7 12.124-1.732 1-7-12.124 1.732-1z"
        fill="#1d7ed0"
      />
      <path
        d="M16.797 34.758l-.866.5-5-8.66.866-.5 5 8.66zm-5.39-25.652l2.309.522c.379.104.379.64 0 .745l-2.31.522a.543.543 0 0 0-.398.355l-.635 2.466c-.104.379-.641.379-.745 0l-.635-2.466a.54.54 0 0 0-.4-.355l-2.31-.522c-.377-.104-.377-.641 0-.745l2.31-.522a.543.543 0 0 0 .4-.355l.635-2.467c.104-.378.64-.378.745 0l.634 2.467a.544.544 0 0 0 .4.355m-7.704 6.447l1.155.26c.19.053.19.322 0 .374l-1.155.26a.271.271 0 0 0-.2.178l-.317 1.233c-.052.19-.32.19-.373 0l-.317-1.233a.27.27 0 0 0-.2-.177l-1.154-.261c-.19-.052-.19-.321 0-.373l1.154-.261a.273.273 0 0 0 .2-.177l.317-1.234c.052-.19.321-.19.373 0l.317 1.234a.273.273 0 0 0 .2.177m36.047-2.62l-11.258 6.5a.501.501 0 0 1-.5-.866l11.258-6.5a.501.501 0 0 1 .5.866"
        fill="#f2b430"
      />
    </g>
  </svg>
);

export default Telescope;
