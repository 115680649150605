import React from "react";

const PackageTracking1 = props => (
  <svg viewBox="0 0 48 48"   {...props}>
    <path
      fill="#FFDA8E"
      d="M43.137 15.728C41.021 18.578 38.506 21 38.5 21c-.02 0-7.5-7.203-7.5-11.4 0-.992.193-1.938.535-2.807A19.99 19.99 0 0 0 26 6C14.954 6 6 14.954 6 26s8.954 20 20 20 20-8.954 20-20c0-3.762-1.058-7.268-2.863-10.272z"
    />
    <path
      fill="#384D68"
      d="M35 14a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm0-6a2 2 0 1 1-.001 4.001A2 2 0 0 1 35 8zm9 8c-.188-.584-.447-1-1-1-1.238 0-1.078 1.035-.77 1.613.701 1.879 1.629 4.493 1.723 6.387h-7.064l-1.174 1.175a1.034 1.034 0 0 1-1.461 0L33.08 23H25v-9a1 1 0 1 0 0-2V5a1 1 0 0 0 1-1c.15.018.852-.021 1 0a1 1 0 1 0 0-2h-3C11.85 2 2 11.85 2 24s9.85 22 22 22 22-9.85 22-22c0-2.454-1.002-6.08-2-8zM21.604 4.157C18.366 5.989 15.794 8.726 13.99 12H8.009a19.99 19.99 0 0 1 13.595-7.843zM6.699 14h6.313c-1.186 2.761-1.865 5.819-1.983 9H4.047a19.824 19.824 0 0 1 2.652-9zM4.047 25h6.982c.119 3.181.797 6.239 1.983 9H6.699a19.824 19.824 0 0 1-2.652-9zm3.962 11h5.981c1.804 3.274 4.376 6.011 7.613 7.843C16.055 43.18 11.206 40.246 8.009 36zM23 42.509c-2.848-1.487-5.131-3.759-6.794-6.509H23v6.509zM23 34h-7.865c-1.266-2.736-1.979-5.818-2.105-9H23v9zm0-11h-9.97c.125-3.182.839-6.264 2.105-9H23v9zm0-11h-6.794C17.869 9.25 20.152 6.978 23 5.491V12zm2 13h9.97c-.125 3.182-.839 6.264-2.104 9H25v-9zm0 11h6.794c-1.663 2.75-3.946 5.021-6.794 6.509V36zm1.396 7.843c3.237-1.832 5.809-4.568 7.613-7.843h5.98a19.981 19.981 0 0 1-13.593 7.843zM41.301 34h-6.313c1.186-2.761 1.864-5.819 1.982-9h6.982a19.81 19.81 0 0 1-2.651 9zM35 22c.012 0 8-7.582 8-12a8 8 0 0 0-16 0c0 4.418 7.98 12 8 12zm0-18c3.313 0 6 2.687 6 6s-5.968 9-6 9c-.001 0-6-5.687-6-9s2.686-6 6-6z"
    />
  </svg>
);

export default PackageTracking1;
