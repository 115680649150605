import React from "react";

const Glasses = props => (
  <svg viewBox="0 0 1010 935.477"   {...props}>
    <path
      fill="#AEBCC4"
      d="M579.543 710.037c0 113.218 91.798 205.036 205.017 205.036 113.258 0 205.036-91.818 205.036-205.036C989.596 596.777 897.818 505 784.56 505c-113.22 0-205.017 91.778-205.017 205.037zm-559.139 0c0 113.218 91.788 205.036 205.027 205.036 113.228 0 205.037-91.818 205.037-205.036C430.468 596.777 338.659 505 225.43 505c-113.239 0-205.027 91.778-205.027 205.037z"
    />
    <g fill="#43525B">
      <path d="M933.664 0v40.808c19.588 0 35.527 15.94 35.527 35.528v504.721c-40.807-58.224-108.276-96.46-184.631-96.46-90.344 0-168.254 53.56-204.16 130.493H429.62c-35.926-76.933-113.826-130.494-204.19-130.494-76.345 0-143.824 38.237-184.622 96.461V76.336c0-19.587 15.93-35.528 35.508-35.528V0C34.232 0 0 34.252 0 76.336v633.7c0 124.318 101.124 225.441 225.43 225.441 124.308 0 225.442-101.123 225.442-225.44 0-18.69-2.54-36.763-6.845-54.138h121.946c-4.304 17.375-6.834 35.447-6.834 54.138 0 124.317 101.123 225.44 225.42 225.44 124.318 0 225.441-101.123 225.441-225.44V76.336C1010 34.252 975.768 0 933.664 0zM225.431 894.67c-101.801 0-184.623-82.832-184.623-184.633 0-101.801 82.822-184.633 184.623-184.633 101.81 0 184.632 82.832 184.632 184.633 0 101.801-82.821 184.632-184.632 184.632zm559.129 0c-101.782 0-184.613-82.832-184.613-184.633 0-101.801 82.831-184.633 184.613-184.633 101.82 0 184.631 82.832 184.631 184.633 0 101.801-82.81 184.632-184.631 184.632z" />
      <path d="M784.56 577.81v40.808c50.432 0 91.46 41.006 91.46 91.419h40.807c0-72.91-59.339-132.228-132.267-132.228zm-559.13 0v40.808c50.403 0 91.4 41.006 91.4 91.419h40.809c0-72.91-59.31-132.228-132.208-132.228z" />
    </g>
  </svg>
);

export default Glasses;
