import React from "react";

const Satellite1 = props => (
  <svg viewBox="0 0 64 64"   {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M3.353 13.354l-.707-.707 10-10 .707.707-10 10zm48 48l-.706-.707 10-10 .706.706-10 10z"
        fill="#1d7ed0"
      />
      <path d="M17.354 47.353l-.707-.706 6-6 .707.706-6 6z" fill="#f2b430" />
      <path
        d="M18 47.5a1.5 1.5 0 1 1-3.001-.001A1.5 1.5 0 0 1 18 47.5"
        fill="#f2b430"
      />
      <path
        d="M45 35.828a1.99 1.99 0 0 0-1.414.586l-7.172 7.171a2 2 0 0 0 0 2.83L54 64l10-10-17.586-17.586A1.99 1.99 0 0 0 45 35.828m0 1c.267 0 .518.104.707.293l16.879 16.88L54 62.584 37.121 45.707a.993.993 0 0 1-.293-.707c0-.268.104-.519.293-.708l7.172-7.17a.993.993 0 0 1 .707-.294M10 0L0 10l17.586 17.586c.39.39.902.586 1.414.586.512 0 1.024-.196 1.414-.586l7.172-7.172a2 2 0 0 0 0-2.828L10 0zm0 1.414l16.879 16.879c.189.189.293.44.293.707a.993.993 0 0 1-.293.707l-7.172 7.172a.993.993 0 0 1-.707.293.993.993 0 0 1-.707-.293L1.414 10 10 1.414zm25 15.414a1.99 1.99 0 0 0-1.414.586L17.414 33.585a2 2 0 0 0 0 2.83l10.172 10.17c.39.391.902.586 1.414.586a1.99 1.99 0 0 0 1.414-.586l16.172-16.17a2 2 0 0 0 0-2.83l-10.172-10.17A1.99 1.99 0 0 0 35 16.827m0 1c.267 0 .518.104.707.293l10.172 10.171c.189.19.293.44.293.708a.993.993 0 0 1-.293.707L29.707 45.878a.993.993 0 0 1-.707.293.993.993 0 0 1-.707-.293l-10.172-10.17a.993.993 0 0 1-.293-.708c0-.268.104-.519.293-.708l16.172-16.17a.993.993 0 0 1 .707-.294"
        fill="#1d7ed0"
      />
      <path
        d="M41.353 23.354l-.706-.707 1.5-1.5.706.707-1.5 1.5z"
        fill="#1d7ed0"
      />
      <path
        d="M42.465 14.464A5 5 0 0 0 46 23a4.986 4.986 0 0 0 3.535-1.465l-7.07-7.07zm.088 1.503l5.48 5.48A3.972 3.972 0 0 1 46 22a3.978 3.978 0 0 1-2.83-1.172A3.976 3.976 0 0 1 42.002 18c0-.727.192-1.424.553-2.033z"
        fill="#1d7ed0"
      />
      <path
        d="M50.121 18.621a.502.502 0 0 1-.354-.854A2.48 2.48 0 0 0 50.5 16a2.48 2.48 0 0 0-.733-1.768c-.944-.944-2.59-.944-3.535 0a.5.5 0 0 1-.707-.707c1.322-1.322 3.628-1.322 4.95 0A3.477 3.477 0 0 1 51.5 16c0 .935-.364 1.814-1.025 2.475a.502.502 0 0 1-.354.146"
        fill="#f2b430"
      />
      <path
        d="M52.243 20.743a.502.502 0 0 1-.354-.854 5.458 5.458 0 0 0 1.61-3.89 5.46 5.46 0 0 0-1.61-3.888 5.462 5.462 0 0 0-3.89-1.611c-1.468 0-2.85.572-3.888 1.61a.5.5 0 0 1-.707-.706 6.509 6.509 0 0 1 9.193 0 6.509 6.509 0 0 1 0 9.193.502.502 0 0 1-.354.146"
        fill="#f2b430"
      />
      <path
        d="M54.364 22.864a.502.502 0 0 1-.354-.854A8.44 8.44 0 0 0 56.5 16c0-2.27-.884-4.405-2.49-6.01A8.444 8.444 0 0 0 48 7.5c-2.27 0-4.405.885-6.01 2.49a.5.5 0 0 1-.707-.707A9.439 9.439 0 0 1 48 6.5c2.538 0 4.923.989 6.718 2.783A9.438 9.438 0 0 1 57.5 16a9.44 9.44 0 0 1-2.782 6.718.502.502 0 0 1-.354.146m-31.217.99l-18-18 .707-.708 18 18-.707.707zm35 35l-18-18 .706-.707 18 18-.706.706z"
        fill="#f2b430"
      />
    </g>
  </svg>
);

export default Satellite1;
