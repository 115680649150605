import React from "react";

const SaveTheWorld = props => (
  <svg viewBox="0 0 32 32"   {...props}>
    <circle fill="#67DDE0" cx={18} cy={14} r={14} />
    <path
      fill="#75EA9C"
      d="M31.5 13.5a5.424 5.424 0 0 0-.077-.072c-2.024-1.842-5.936-.915-6.923.072-1 1 1.235 3.479-2 5-3.021 1.42-.813 2.688-4 4-2.067.852-4 0-3-3s-5.888-1.634-4-7c1.05-2.983 3.885-2.252 8-2 3.897.239 3-4 1-5s-7 3-8 1 4-6 4-6 6.812-.475 10.753 3.434 4.222 9.542 4.222 9.542"
    />
    <path
      fill="#4D5152"
      d="M18 1c7.168 0 13 5.832 13 13s-5.832 13-13 13S5 21.168 5 14 10.832 1 18 1m0-1C10.268 0 4 6.268 4 14s6.268 14 14 14 14-6.268 14-14S25.732 0 18 0z"
    />
    <path
      fill="none"
      stroke="#4D5152"
      strokeMiterlimit={10}
      d="M16.5.5s-5 4-4 6 6-2 8-1 2.897 5.239-1 5c-4.115-.252-6.95-.983-8 2-1.888 5.366 5 4 4 7s.932 3.852 3 3c3.188-1.313.979-2.58 4-4 3.235-1.521 1-4 2-5s5-1.938 7 0"
    />
    <path
      fill="#FFE4A9"
      d="M26.3 28.89l-.51.261C22.6 30.74 20.08 32 17 32c-2.94 0-10.91-2.59-12.16-3H0v-9h4c.38 0 .59 0 1.29-.71 1.08-1.07 2.28-1.6 3.68-1.6 1.16 0 2.26.359 3.32.71.96.31 1.87.6 2.71.6 2.39 0 6 0 6 2.5S19.01 24 16 24h-.1c-.5 0-1.09 0-1.47.04.43 1.19 1.01 1.38 2.89 2.01.3.101.64.15 1.01.15 1.32 0 2.86-.641 4.21-1.21 1.26-.53 2.35-.99 3.31-.99s1.841.78 2.091 1.84c.219.97-.091 2.28-1.641 3.05z"
    />
    <path fill="#67DDE0" d="M0 20h4v9H0z" />
    <path
      fill="#4D5152"
      d="M27.94 25.84c-.25-1.06-1.13-1.84-2.09-1.84s-2.05.46-3.31.99c-1.35.569-2.89 1.21-4.21 1.21-.37 0-.71-.05-1.01-.15-1.88-.63-2.46-.819-2.891-2.01C14.81 24 15.4 24 15.9 24h.1c3.01 0 5 0 5-2.5S17.39 19 15 19c-.84 0-1.75-.29-2.71-.6-1.06-.351-2.16-.71-3.32-.71-1.4 0-2.6.529-3.68 1.6-.7.71-.91.71-1.29.71H0v9h4.84c1.25.41 9.22 3 12.16 3 3.08 0 5.6-1.26 8.79-2.85l.51-.261c1.55-.769 1.86-2.079 1.64-3.049zM3 28H1v-7h2v7zm22.85 0C22.46 29.69 20 31 17 31S5 28 5 28H4v-7c.52 0 1 0 2-1 .98-.98 1.97-1.31 2.97-1.31 2 0 4.03 1.31 6.03 1.31s5 0 5 1.5-1 1.5-4 1.5h-.1c-1.93 0-2.86.01-2.5 1.12C14 26 14.97 26.32 17 27c.43.14.88.2 1.33.2 2.729 0 5.8-2.2 7.52-2.2 1 0 2 2 0 3z"
    />
  </svg>
);

export default SaveTheWorld;
