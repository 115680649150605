import React from "react";

const Torch = props => (
  <svg viewBox="0 0 128 128"   {...props}>
    <ellipse fill="#B8CBCD" cx={65.511} cy={115.429} rx={47.667} ry={10.186} />
    <path
      fill="#F6E89A"
      d="M37.011 46.241c0-3.313 12.089-6 27-6s27 2.687 27 6l-9 21c0 2.209-8.06 4-18 4s-18-1.791-18-4l-9-21z"
    />
    <path
      fill="#8D4520"
      d="M64.011 73.241c-4.542 0-19.034-.405-19.954-5.482l-8.884-20.73a2.017 2.017 0 0 1-.162-.788c0-7.775 26.032-8 29-8s29 .225 29 8c0 .271-.055.539-.162.788l-8.884 20.73c-.921 5.077-15.412 5.482-19.954 5.482zm-16.05-6.443c1.108.886 6.732 2.443 16.05 2.443 9.317 0 14.941-1.558 16.05-2.443.026-.118.065-.233.112-.345l8.673-20.237c-1.659-1.547-10.589-3.975-24.835-3.975-14.247 0-23.176 2.428-24.835 3.975l8.673 20.237c.048.111.085.227.112.345z"
    />
    <path
      fill="#F7BB83"
      d="M83.158 42.713c.276-7.95-1.147-20.667-1.147-20.667s-3.927 1.023-6.601 2.612C70.097 16.111 64.011 8.491 64.011 8.491S44.844 32.489 44.844 43.074c0 10.586 8.582 19.167 19.167 19.167s19.167-8.581 19.167-19.167c0-.116-.016-.242-.02-.361z"
    />
    <path
      fill="#8D4520"
      d="M64.011 64.241c-11.671 0-21.167-9.495-21.167-21.167 0-11.157 18.804-34.829 19.604-35.831.76-.951 2.366-.951 3.126 0 .058.072 5.486 6.889 10.546 14.793 2.553-1.18 5.247-1.89 5.386-1.926a1.997 1.997 0 0 1 2.492 1.713c.059.523 1.427 12.859 1.162 20.867.012.169.018.279.018.384 0 11.672-9.495 21.167-21.167 21.167zm-.006-52.493c-5.935 7.753-17.162 23.941-17.162 31.326 0 9.466 7.701 17.167 17.167 17.167 9.466 0 17.167-7.701 17.167-17.167l-.019-.296a3.038 3.038 0 0 1 0-.135c.197-5.659-.5-13.919-.89-17.963-1.202.418-2.67 1.003-3.838 1.697a2.003 2.003 0 0 1-2.72-.664c-3.588-5.773-7.569-11.162-9.705-13.965z"
    />
    <path
      fill="#F6E89A"
      d="M72.031 38.441c.115-3.328-1.437-6.075-1.437-6.075s-4.57 4.085-5.689 4.75c0-4.375-.889-13-.889-13s-8.023 10.046-8.023 14.477a8.023 8.023 0 1 0 16.046 0c0-.049-.006-.102-.008-.152z"
    />
    <path
      fill="#8D4520"
      d="M64.016 48.616c-5.527 0-10.023-4.496-10.023-10.023 0-4.884 7.048-13.956 8.461-15.725a2 2 0 0 1 3.552 1.043c.027.264.507 4.945.754 9.155.816-.7 1.697-1.472 2.502-2.191a2.01 2.01 0 0 1 1.659-.482 2.005 2.005 0 0 1 1.416.989c.074.131 1.799 3.229 1.698 7.031.004.062.006.121.006.18-.002 5.527-4.499 10.023-10.025 10.023zM62.5 29.551c-2.365 3.47-4.508 7.257-4.508 9.042a6.03 6.03 0 0 0 6.023 6.023 6.03 6.03 0 0 0 6.023-6.023l-.006-.072a2.44 2.44 0 0 1-.001-.148 9.648 9.648 0 0 0-.286-2.597c-1.435 1.241-3.093 2.628-3.82 3.06a2 2 0 0 1-3.022-1.719c.001-2.012-.196-4.989-.403-7.566z"
    />
    <path
      fill="#F6E89A"
      d="M46.011 67.241c0 2.209 8.06 4 18 4s18-1.791 18-4l9-21c0 3.314-12.089 6-27 6s-27-2.686-27-6l9 21z"
    />
    <path
      fill="#8D4520"
      d="M64.011 73.241c-4.542 0-19.034-.405-19.954-5.482L35.768 48.42c-.62-.835-.758-1.634-.758-2.179a2.002 2.002 0 0 1 3.839-.788l.343.8c1.913 1.673 10.745 3.988 24.819 3.988 14.073 0 22.905-2.315 24.819-3.988l.343-.8a1.994 1.994 0 0 1 2.24-1.171 2 2 0 0 1 1.598 1.959c0 .545-.138 1.344-.758 2.179l-8.288 19.339c-.921 5.077-15.412 5.482-19.954 5.482zm-16.05-6.443c1.108.886 6.732 2.443 16.05 2.443 9.317 0 14.941-1.558 16.05-2.443.026-.118.065-.233.112-.345l6.35-14.815c-5.737 1.78-14.507 2.603-22.512 2.603-8.004 0-16.774-.823-22.512-2.603l6.35 14.815c.048.111.085.227.112.345z"
    />
    <path
      fill="#F6E89A"
      d="M46.011 67.241c0 2.209 8.06 4 18 4s18-1.791 18-4l-10 49c0 1.657-.544 3-8 3-7.456 0-8-1.343-8-3l-10-49z"
    />
    <path
      fill="#8D4520"
      d="M64.011 121.241c-6.221 0-9.869-.789-9.997-4.781l-9.86-48.313a2.948 2.948 0 0 1-.144-.905 2 2 0 0 1 3.95-.443c1.108.886 6.732 2.443 16.05 2.443 9.317 0 14.941-1.558 16.05-2.443a2.01 2.01 0 0 1 2.151-1.547 2.002 2.002 0 0 1 1.799 1.99c0 .307-.048.61-.144.905l-9.86 48.313c-.126 3.992-3.774 4.781-9.995 4.781zm-5.988-4.714c.238.163 1.384.714 5.987.714s5.749-.551 5.987-.714c.006-.051.013-.139.013-.286 0-.135.014-.269.04-.4l9.047-44.331c-5.223 1.618-13.028 1.731-15.088 1.731-2.06 0-9.866-.113-15.088-1.731l9.047 44.331c.027.132.041.266.041.4.002.148.008.236.014.286z"
    />
    <path
      opacity={0.2}
      fill="#8D4520"
      d="M63.844 77.241c-10.036 0-19-1.388-19-8h5c0-1.1-.584-.866-.866-1.065 1.022.72 6.431 1.065 14.866 1.065 8.435 0 13.844-.346 14.866-1.065-.283.199-.866-.034-.866 1.065l5.35.458c0 6.613-9.314 7.542-19.35 7.542z"
    />
    <path
      fill="#8D4520"
      d="M64.011 8.491s6.086 7.62 11.399 16.167c2.674-1.589 6.601-2.612 6.601-2.612s1.343 11.996 1.167 19.971c4.841 1.084 7.833 2.576 7.833 4.225 0 .386-.17.763-.483 1.128l-8.517 19.872-10 49c0 1.657-.544 3-8 3-7.456 0-8-1.343-8-3l-10-49-8.517-19.872c-.313-.365-.483-.742-.483-1.128 0-1.655 3.017-3.153 7.894-4.239C46.068 30.957 64.011 8.491 64.011 8.491m0-4a4.003 4.003 0 0 0-3.126 1.504c-1.766 2.211-16.244 20.593-19.418 32.734-3.576 1.01-8.457 3.071-8.457 7.512 0 .801.185 1.95.994 3.141l8.168 19.06 9.849 48.261c.337 6.539 7.889 6.539 11.988 6.539 4.1 0 11.651 0 11.988-6.539l9.85-48.261 8.168-19.06c.81-1.19.994-2.34.994-3.141 0-4.194-4.352-6.263-7.831-7.327-.132-7.736-1.145-16.875-1.193-17.313a4.007 4.007 0 0 0-1.71-2.852A4.005 4.005 0 0 0 81 18.175c-.292.076-2.07.552-4.117 1.361-4.801-7.328-9.524-13.26-9.749-13.541a3.994 3.994 0 0 0-3.123-1.504z"
    />
  </svg>
);

export default Torch;
