import React from "react";

const WiFiLow = props => (
  <svg viewBox="0 0 32.004 26.003"   {...props}>
    <path
      d="M31.457 6.664C27.328 2.367 21.84 0 16.002 0S4.675 2.367.547 6.666a1.999 1.999 0 0 0 0 2.747c.364.379.842.569 1.319.569s.955-.19 1.319-.569c3.424-3.564 7.975-5.319 12.816-5.319 4.841 0 9.393 1.754 12.815 5.317.729.759 1.911.759 2.64 0s.73-1.988.001-2.747zm-15.472 1.33c-4.314 0-8.37 1.667-11.421 4.696a1.927 1.927 0 0 0 0 2.738c.381.378.88.566 1.379.566s.997-.188 1.378-.566c2.314-2.299 5.391-3.689 8.664-3.689 3.272 0 6.349 1.391 8.663 3.689a1.959 1.959 0 0 0 2.756-.001 1.924 1.924 0 0 0 0-2.737c-3.05-3.029-7.105-4.696-11.419-4.696z"
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#ccc"
    />
    <path
      d="M16.008 22.008c-1.109 0-2.008.894-2.008 1.997s.898 1.998 2.008 1.998c1.108 0 2.008-.895 2.008-1.998s-.9-1.997-2.008-1.997zm6.453-4.261c-1.725-1.778-4.02-2.757-6.459-2.757s-4.733.979-6.459 2.758a1.926 1.926 0 0 0 0 2.667c.357.367.826.552 1.294.552s.937-.185 1.294-.552c1.034-1.066 2.409-1.716 3.871-1.716 1.463 0 2.838.649 3.872 1.715a1.793 1.793 0 0 0 2.588 0 1.927 1.927 0 0 0-.001-2.667z"
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#48a0dc"
    />
  </svg>
);

export default WiFiLow;
