import React from "react";

const Growing = props => (
  <svg viewBox="0 0 32 32"   {...props}>
    <path
      fill="#75EA9C"
      d="M12 11c0 3-3.5 3-3.5 3S5 14 5 11s3.5-7 3.5-7S12 8 12 11zm9-3c0 3-3.5 3-3.5 3S14 11 14 8s3.5-7 3.5-7S21 5 21 8zm9 3c0 3-3.5 3-3.5 3S23 14 23 11s3.5-7 3.5-7S30 8 30 11z"
    />
    <path
      fill="#FFE4A9"
      d="M27.3 26.89l-.51.261C23.6 28.74 21.08 30 18 30c-2.939 0-10.91-2.59-12.16-3H1v-9h4c.38 0 .59 0 1.29-.71 1.08-1.07 2.28-1.6 3.68-1.6 1.16 0 2.26.359 3.32.71.96.31 1.87.6 2.71.6 2.39 0 6 0 6 2.5S20.01 22 17 22h-.1c-.5 0-1.09 0-1.471.04.43 1.19 1.01 1.38 2.891 2.01.3.101.64.15 1.01.15 1.32 0 2.86-.641 4.21-1.21 1.26-.53 2.35-.99 3.31-.99s1.841.78 2.091 1.84c.219.97-.091 2.28-1.641 3.05z"
    />
    <path fill="#67DDE0" d="M1 18h4v9H1z" />
    <path
      fill="#4D5152"
      d="M26.85 22c-.27 0-.56.04-.85.1v.4c0 .14.06.18.14.18.101 0 .221-.04.36-.04.28 0 .5.141.5-.14v-.48c-.05-.02-.1-.02-.15-.02z"
    />
    <path
      fill="#4D5152"
      d="M28.94 23.84c-.24-1-1.04-1.76-1.94-1.82v.48c0 .28-.22.14-.5.14-.14 0-.26.04-.36.04-.08 0-.14-.04-.14-.18v-.4c-.74.17-1.56.511-2.46.891-1.35.569-2.89 1.21-4.21 1.21-.37 0-.71-.05-1.01-.15-.11-.04-.221-.069-.32-.1v-1.96c2.44-.021 4-.28 4-2.49 0-1.86-2-2.34-4-2.46v-6.08c.96-.13 3-.67 3-2.96 0-3-3.5-7-3.5-7S14 5 14 8c0 2.29 2.04 2.83 3 2.96v6.05c-.35-.01-.68-.01-1-.01-.84 0-1.75-.29-2.71-.6-1.06-.351-2.16-.71-3.32-.71-.34 0-.66.03-.97.09v-1.82c.96-.13 3-.67 3-2.96 0-3-3.5-7-3.5-7S5 8 5 11c0 2.29 2.04 2.83 3 2.96v2.13c-.61.271-1.17.67-1.71 1.2-.7.71-.91.71-1.29.71H1v9h4.84c1.25.41 9.22 3 12.16 3 3.08 0 5.6-1.26 8.79-2.85l.51-.261c1.55-.769 1.86-2.079 1.64-3.049zM15 8c0-1.68 1.44-3.98 2.5-5.4C18.56 4.02 20 6.32 20 8c0 1.45-1.24 1.85-2 1.96V7.5c0-.28-.22-.5-.5-.5s-.5.22-.5.5v2.46c-.76-.11-2-.51-2-1.96zm-9 3c0-1.68 1.44-3.98 2.5-5.4C9.56 7.02 11 9.32 11 11c0 1.45-1.24 1.85-2 1.96V10.5c0-.28-.22-.5-.5-.5s-.5.22-.5.5v2.46c-.76-.11-2-.51-2-1.96zM4 26H2v-7h2v7zm22.85 0C23.46 27.69 21 29 18 29S6 26 6 26H5v-7c.52 0 1 0 2-1 .98-.98 1.97-1.31 2.97-1.31 2 0 4.03 1.31 6.03 1.31s5 0 5 1.5-1 1.5-4 1.5h-.1c-1.93 0-2.86.01-2.5 1.12C15 24 15.97 24.32 18 25c.43.14.88.2 1.33.2 2.729 0 5.8-2.2 7.52-2.2 1 0 2 2 0 3zM17 22v1.59c-.88-.359-1.26-.7-1.57-1.55.38-.04.97-.04 1.47-.04h.1z"
    />
    <path
      fill="#4D5152"
      d="M26.5 4S23 8 23 11c0 2.29 2.04 2.83 3 2.96v8.54c0 .14.06.18.14.18.101 0 .221-.04.36-.04.28 0 .5.141.5-.14v-8.54c.96-.13 3-.67 3-2.96 0-3-3.5-7-3.5-7zm.5 8.96V10.5c0-.28-.22-.5-.5-.5s-.5.22-.5.5v2.46c-.76-.11-2-.51-2-1.96 0-1.68 1.44-3.98 2.5-5.4C27.56 7.02 29 9.32 29 11c0 1.45-1.24 1.85-2 1.96z"
    />
  </svg>
);

export default Growing;
