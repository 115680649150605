import React from "react";

const Golf = props => (
  <svg viewBox="0 0 128 128"   {...props}>
    <path
      fill="#B8CBCD"
      d="M111.013 109.179c0 5.625-21.01 9.813-47.335 9.813-26.326 0-48-4.188-48-9.813 0-5.626 21.341-10.188 47.667-10.188 26.325 0 47.668 4.562 47.668 10.188z"
    />
    <path
      fill="#F7BB83"
      d="M81.916 104.991c-21.095 0-37.619-8.649-37.619-19.69 0-20.743 16.876-37.619 37.619-37.619s37.619 16.876 37.619 37.619c0 11.041-16.524 19.69-37.619 19.69z"
    />
    <path
      fill="#6E4123"
      d="M81.916 49.682c19.672 0 35.619 15.947 35.619 35.619 0 9.771-15.632 17.69-35.619 17.69-19.987 0-35.619-7.92-35.619-17.69 0-19.672 15.947-35.619 35.619-35.619m0-4c-21.846 0-39.619 17.773-39.619 39.619 0 12.365 17.032 21.69 39.619 21.69s39.619-9.325 39.619-21.69c0-21.846-17.773-39.619-39.619-39.619z"
    />
    <path
      fill="#FFF"
      d="M48.058 90.301a3 3 0 0 1-3-3c0-21.295 17.324-38.619 38.619-38.619a3 3 0 1 1 0 6c-17.986 0-32.619 14.633-32.619 32.619a3 3 0 0 1-3 3z"
    />
    <path
      fill="#F7BB83"
      d="M39.74 115.991c-15.059 0-31.062-4.702-31.062-13.417 0-17.277 14.056-31.333 31.333-31.333s31.334 14.056 31.334 31.333c0 8.715-16.284 13.417-31.605 13.417z"
    />
    <path
      fill="#6E4123"
      d="M40.011 73.241c16.2 0 29.334 13.133 29.334 29.333 0 6.306-13.554 11.417-29.605 11.417s-29.062-5.111-29.062-11.417c0-16.2 13.132-29.333 29.333-29.333m0-4c-18.38 0-33.333 14.953-33.333 33.333 0 10.121 16.633 15.417 33.062 15.417 16.699 0 33.605-5.296 33.605-15.417 0-18.38-14.954-33.333-33.334-33.333z"
    />
    <path
      fill="#FFF"
      d="M10.218 110.506a3 3 0 0 1-3-3c0-20.767 18.531-39.682 38.88-39.682a3 3 0 1 1 0 6c-16.9 0-32.88 16.368-32.88 33.682a3 3 0 0 1-3 3z"
    />
    <path
      fill="#6E4123"
      d="M81.916 49.682c19.672 0 35.619 15.947 35.619 35.619 0 9.771-15.632 17.69-35.619 17.69-4.455 0-8.691-.396-12.589-1.115.005.232.018.464.018.698 0 6.306-13.554 11.417-29.605 11.417s-29.062-5.111-29.062-11.417c0-16.2 13.133-29.333 29.333-29.333 2.777 0 5.461.395 8.007 1.115 4.618-14.315 18.045-24.674 33.898-24.674m0-6c-16.31 0-30.83 9.379-37.63 23.82a35.129 35.129 0 0 0-4.275-.261c-19.482 0-35.333 15.851-35.333 35.333 0 11.967 18.176 17.417 35.062 17.417 13.977 0 28.822-3.621 33.836-11.439 2.723.292 5.512.439 8.34.439 10.545 0 20.484-2.064 27.986-5.814 8.791-4.393 13.633-10.741 13.633-17.876 0-22.949-18.67-41.619-41.619-41.619z"
    />
    <ellipse fill="#FFF" cx={38.678} cy={88.173} rx={7.571} ry={4.818} />
    <path
      fill="#6E4123"
      d="M38.678 94.991c-5.367 0-9.571-2.995-9.571-6.818 0-3.823 4.204-6.818 9.571-6.818s9.571 2.995 9.571 6.818c0 3.823-4.204 6.818-9.571 6.818zm0-9.637c-3.138 0-5.571 1.515-5.571 2.818 0 1.304 2.434 2.818 5.571 2.818 3.138 0 5.571-1.515 5.571-2.818 0-1.303-2.434-2.818-5.571-2.818z"
    />
    <path
      fill="#6E4123"
      d="M38.678 89.354a3 3 0 0 1-3-3V27.628a3 3 0 1 1 6 0v58.727a2.999 2.999 0 0 1-3 2.999z"
    />
    <path fill="#F6E89A" d="M61.678 37.628l-23 10.363V26.628l23 11z" />
    <path
      fill="#6E4123"
      d="M38.678 50.991a3 3 0 0 1-3-3V26.628a3 3 0 0 1 4.294-2.706l23 11a2.999 2.999 0 0 1-.062 5.441l-23 10.363a3.006 3.006 0 0 1-1.232.265zm3-19.603v11.961l12.877-5.802-12.877-6.159z"
    />
    <path fill="#FFF" d="M78.678 48.252h8v15.739h-8z" />
    <path
      fill="#6E4123"
      d="M86.678 65.991h-8a2 2 0 0 1-2-2V48.252a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v15.739a2 2 0 0 1-2 2zm-6-4h4V50.252h-4v11.739z"
    />
    <path
      fill="#B2EDA6"
      d="M101.789 44.921l-.005-.01.003-.033h-.021L84.734 18.991 67.702 44.878h-.024c0 .013.003.025.003.038l-.003.005h.004c.08 2.788 7.924 8.556 17.294 8.556 9.37 0 16.731-5.768 16.808-8.556h.005z"
    />
    <path
      fill="#3C663E"
      d="M84.975 55.477c-9.486 0-18.934-5.867-19.284-10.335a1.937 1.937 0 0 1-.013-.201l-.001-.063c0-.509.19-.974.503-1.326l16.883-25.66a2.003 2.003 0 0 1 3.342 0l16.921 25.72a1.975 1.975 0 0 1 .449 1.501c-.292 4.147-8.688 10.364-18.8 10.364zM69.846 45.259c1.115 1.91 7.387 6.218 15.13 6.218 8.048 0 13.62-4.4 14.646-6.219L84.734 22.63 69.846 45.259z"
    />
    <path
      fill="#B2EDA6"
      d="M98.221 28.496l-.004-.008.001-.026h-.017L84.734 7.991 71.265 28.462h-.02c0 .01.002.02.003.029l-.003.005h.004c.063 2.205 6.266 6.766 13.676 6.766 7.409 0 13.23-4.56 13.289-6.766h.007z"
    />
    <path
      fill="#3C663E"
      d="M84.926 37.262c-7.823 0-15.33-4.755-15.664-8.53a1.906 1.906 0 0 1-.015-.213l-.001-.057c0-.498.182-.953.483-1.304L83.063 6.892a2.003 2.003 0 0 1 3.342 0l13.368 20.322c.277.342.444.775.444 1.248v.023c0 .076-.004.153-.013.229-.298 3.813-7.424 8.548-15.278 8.548zm-11.449-8.523c1.122 1.479 5.662 4.522 11.448 4.522 5.686 0 10.037-3.031 11.08-4.498L84.734 11.63 73.477 28.739z"
    />
    <path fill="#FFF" d="M104.678 71.991h-7V59.815l7 .176z" />
    <path
      fill="#6E4123"
      d="M104.678 73.991h-7a2 2 0 0 1-2-2V59.815c0-.539.218-1.056.603-1.432a1.95 1.95 0 0 1 1.446-.567l7 .176a2 2 0 0 1 1.95 1.999v12c.001 1.105-.895 2-1.999 2zm-5-4h3v-8.05l-3-.075v8.125z"
    />
    <path
      fill="#B2EDA6"
      d="M113.678 56.56l-.004-.008.002-.025h-.017l-13.176-20.025-13.175 20.024h-.02c0 .01.003.02.003.029l-.003.004h.003c.063 2.156 6.13 6.618 13.379 6.618s12.943-4.462 13.002-6.618h.006z"
    />
    <path
      fill="#3C663E"
      d="M100.671 65.178c-7.686 0-15.064-4.691-15.369-8.406a1.813 1.813 0 0 1-.012-.18l-.001-.065c0-.498.182-.953.483-1.304l13.04-19.821a2 2 0 0 1 3.342 0l13.077 19.876c.277.343.444.776.444 1.249l-.002.079a2.14 2.14 0 0 1-.008.141c-.261 3.753-7.271 8.431-14.994 8.431zm-11.145-8.383c1.123 1.442 5.55 4.383 11.145 4.383 5.598 0 9.761-2.904 10.79-4.354L100.483 40.14 89.526 56.795z"
    />
    <path
      fill="#B2EDA6"
      d="M110.917 43.853l-.004-.006.002-.021h-.014L100.483 27.99 90.065 43.827h-.015l.002.022-.002.004h.003c.049 1.706 4.847 5.233 10.579 5.233s10.235-3.527 10.281-5.233h.004z"
    />
    <path
      fill="#3C663E"
      d="M100.632 51.087c-5.92 0-12.278-3.575-12.569-7.014a1.977 1.977 0 0 1-.012-.188l-.001-.058c0-.488.175-.936.466-1.283l10.297-15.652a2 2 0 0 1 3.342 0l10.328 15.699c.271.341.433.77.433 1.236l-.001.068a2.158 2.158 0 0 1-.008.139c-.234 3.144-5.964 7.053-12.275 7.053zm-8.29-7.081c1.054 1.12 4.269 3.081 8.29 3.081 3.939 0 7.024-1.947 8.012-3.054l-8.16-12.403-8.142 12.376z"
    />
    <path
      fill="#3C663E"
      d="M84.734 7.991l13.467 20.471h.017l-.001.026.004.008h-.006c-.031 1.164-1.678 2.98-4.306 4.439l1.66 2.525 4.914-7.469 10.418 15.836h.014l-.002.021.004.006h-.004c-.024.9-1.299 2.306-3.332 3.435l6.078 9.238h.017l-.002.025.004.008h-.005c-.059 2.156-5.753 6.618-13.002 6.618-7.249 0-13.316-4.462-13.379-6.618h-.003l.003-.004c0-.01-.003-.02-.003-.029h.02l2.324-3.533a23.273 23.273 0 0 1-4.657.483c-9.37 0-17.214-5.768-17.294-8.556h-.004l.003-.005c0-.013-.003-.025-.003-.038h.024l7.911-12.023c-2.642-1.445-4.331-3.216-4.363-4.358h-.004l.003-.005-.003-.029h.02L84.734 7.991m28.942 48.535h.005M84.734 3.991a4.001 4.001 0 0 0-3.342 1.802L68.079 26.028a3.968 3.968 0 0 0-.825 2.684l.009.123c.142 2.012 1.395 3.72 2.879 5.059l-5.609 8.525a3.958 3.958 0 0 0-.835 2.859c.429 5.656 10.126 11.539 19.678 12.146.912 4.949 9.246 9.753 17.297 9.753 8.251 0 16.487-4.98 16.979-10.161a4.832 4.832 0 0 0 .029-.49 3.98 3.98 0 0 0-.874-2.496l-3.852-5.854c.991-1.058 1.806-2.373 1.94-3.904.021-.189.026-.38.02-.57a3.982 3.982 0 0 0-.833-2.32l-10.255-15.588a4 4 0 0 0-3.763-1.78L88.076 5.793a4 4 0 0 0-3.342-1.802z"
    />
  </svg>
);

export default Golf;
