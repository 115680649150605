import React from "react";

const Syringe2 = props => (
  <svg viewBox="0 0 1000.276 1010"   {...props}>
    <path
      fill="#E5E5E5"
      d="M747.418 191.467l94.17-94.369 71.253 71.196-94.248 94.308z"
    />
    <path
      fill="#FFAB00"
      d="M299.734 891.322c-7.482 0-14.685-3.01-20.125-8.449L127.107 730.42c-5.46-5.459-8.459-12.712-8.439-20.443.01-7.711 3.019-14.945 8.479-20.365l60.096-60.096 82.214 82.174 20.304-20.284-82.243-82.174 75.35-75.34 82.183 82.214 20.284-20.284-82.193-82.214 75.35-75.34 193.26 193.24-251.444 251.445c-5.39 5.4-12.574 8.37-20.215 8.37h-.359z"
    />
    <path
      fill="#E5E5E5"
      d="M378.401 438.428l95.685-95.804 82.183 82.194 20.324-20.284-82.173-82.194L569.76 247l82.213 82.174 20.265-20.264-82.254-82.155 76.056-76.036 193.282 193.26-287.69 287.65z"
    />
    <path
      fill="#43525B"
      d="M994.298 200.593L799.703 5.978c-7.97-7.97-20.882-7.97-28.852 0-7.97 7.97-7.97 20.882 0 28.852l41.924 41.924-85.701 85.82-107.58-107.579c-7.971-7.97-20.882-7.97-28.853 0-7.97 7.97-7.97 20.882 0 28.853l46.547 46.547-534.66 534.67c-24.737 24.737-24.777 65.056-.04 89.914l61.86 61.8L5.978 975.17c-7.97 7.97-7.97 20.882 0 28.852A20.333 20.333 0 0 0 20.404 1010c5.22 0 10.441-1.992 14.427-5.978l158.38-158.4 61.85 61.8c12.015 12.016 27.985 18.64 44.963 18.64h.039c16.957-.01 32.898-6.635 44.873-18.64l534.669-534.63 46.588 46.587c3.984 3.985 9.205 5.978 14.426 5.978s10.441-1.993 14.426-5.978c7.97-7.97 7.97-20.883 0-28.853l-107.6-107.6 85.72-85.76 32.28 32.28c3.986 3.985 9.207 5.977 14.428 5.977s10.441-1.992 14.425-5.977c7.971-7.97 7.971-20.882 0-28.853zM316.063 878.589c-4.284 4.294-9.972 6.655-16.03 6.665h-.01c-6.077 0-11.796-2.38-16.11-6.695l-76.126-76.067c-.05-.05-.07-.128-.12-.18-.05-.048-.12-.069-.18-.118l-76.106-76.047c-8.867-8.907-8.867-23.364 0-32.23l55.842-55.843 92.386 92.317 28.853-28.873-92.376-92.306 66.771-66.772 92.327 92.357 28.852-28.854-92.326-92.355 65.376-65.387L561.77 632.894 316.063 878.589zm274.578-274.548L405.94 419.35l68.166-68.166 92.326 92.346 28.854-28.853-92.327-92.346 66.801-66.791 92.336 92.326 28.853-28.853-92.336-92.326 67.429-67.439 46.606 46.607c.02 0 .02.01.02.02l45.75 45.74 92.336 92.326L590.641 604.04zm227.952-349.967l-62.647-62.637 85.68-85.82 62.688 62.696-85.72 85.76z"
    />
  </svg>
);

export default Syringe2;
