import React from "react";

const Moon = props => (
  <svg viewBox="0 0 64 64"   {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M21 25.5a5.506 5.506 0 0 1-5.5-5.5c0-3.033 2.468-5.5 5.5-5.5a.5.5 0 0 1 0 1 4.505 4.505 0 0 0-4.5 4.5c0 2.481 2.019 4.5 4.5 4.5a.5.5 0 0 1 0 1M45 19c-5.523 0-10 4.477-10 10s4.477 10 10 10 10-4.477 10-10-4.477-10-10-10m0 1c4.963 0 9 4.037 9 9s-4.037 9-9 9-9-4.037-9-9 4.037-9 9-9M10 51c2.757 0 5 2.243 5 5 0 .794-.186 1.545-.516 2.212l.856.527a6 6 0 0 0-8.549-7.81l.693.751A4.97 4.97 0 0 1 10 51m27.5 1.5a.5.5 0 0 1 0-1c1.103 0 2-.897 2-2s-.897-2-2-2a.5.5 0 0 1 0-1c1.654 0 3 1.346 3 3s-1.346 3-3 3m-10.5-9a.5.5 0 0 1-.5-.5c0-3.86-3.141-7-7-7s-7 3.14-7 7a.5.5 0 0 1-1 0c0-4.411 3.589-8 8-8s8 3.589 8 8a.5.5 0 0 1-.5.5m9.828-30a.502.502 0 0 1-.354-.854 3.473 3.473 0 0 0 1.026-2.474c0-.935-.364-1.814-1.026-2.475-1.322-1.322-3.626-1.322-4.949 0a.5.5 0 0 1-.707-.707A4.47 4.47 0 0 1 34 5.672a4.47 4.47 0 0 1 3.182 1.318 4.469 4.469 0 0 1 1.318 3.182 4.47 4.47 0 0 1-1.318 3.182.502.502 0 0 1-.354.146M51 43.5a2.503 2.503 0 0 0-2.5 2.5c0 1.378 1.121 2.5 2.5 2.5s2.5-1.122 2.5-2.5-1.121-2.5-2.5-2.5m0 6c-1.93 0-3.5-1.57-3.5-3.5s1.57-3.5 3.5-3.5 3.5 1.57 3.5 3.5-1.57 3.5-3.5 3.5"
        fill="#f2b430"
      />
      <path
        d="M32 0C14.327 0 0 14.327 0 32c0 17.673 14.327 32 32 32 17.673 0 32-14.327 32-32C64 14.327 49.673 0 32 0m0 1c17.093 0 31 13.907 31 31S49.093 63 32 63 1 49.093 1 32 14.907 1 32 1"
        fill="#1d7ed0"
      />
    </g>
  </svg>
);

export default Moon;
