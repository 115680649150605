import React from "react";

const CheckboardGraph = props => (
  <svg viewBox="0 0 857.293 1010"   {...props}>
    <path
      fill="#F7F8F9"
      d="M603.622 99.794h232.24v888.773H21.432V99.794H259.7"
    />
    <path
      fill="#AEBCC4"
      d="M610.424 107.79l-.187-.293v-5.003h-3.12l-50.023-81.062H300.137l-49.981 81.062H247.1v4.92l-.25.376h.25v73.38h363.137v-73.38z"
    />
    <path
      fill="#43525B"
      d="M835.862 1010H21.432C9.587 1010 0 1000.414 0 988.567V99.794c0-11.846 9.586-21.432 21.433-21.432H259.7v42.865H42.865v845.908H814.43V121.227H603.622V78.362h232.24c11.845 0 21.431 9.586 21.431 21.432v888.773c0 11.847-9.586 21.433-21.431 21.433z"
    />
    <path
      fill="#43525B"
      d="M610.237 202.602H247.1c-11.846 0-21.432-9.585-21.432-21.432v-70.764c-.23-1.863-.252-3.726 0-5.568v-2.344c0-7.953 4.332-14.902 10.779-18.606l45.439-73.695A21.448 21.448 0 0 1 300.136 0h256.958a21.412 21.412 0 0 1 18.23 10.172l45.462 73.653c6.508 3.683 10.883 10.674 10.883 18.67v3.704a24.243 24.243 0 0 1 0 3.014v71.957c0 11.847-9.587 21.433-21.432 21.433zm-341.704-42.864h320.27v-46.11l-43.66-70.763H312.11l-43.576 70.66v46.213zM751.012 845.992H72.335v-42.865h678.677v42.865z"
    />
    <path fill="#43525B" d="M172.945 903.717H130.08V225.04h42.864v678.677z" />
    <path
      fill="#FFAB00"
      d="M235.484 758.337a21.38 21.38 0 0 1-11.616-3.433c-9.942-6.424-12.788-19.695-6.363-29.636 23.881-36.942 84.453-122.902 131.105-122.902 26.477 0 43.41 26.874 59.778 52.87 6.571 10.423 17.287 27.439 23.378 31.772 27.125-8.77 25.87-94.918 24.928-158.232-1.383-94.415-2.68-183.577 53.183-207.48 36.104-15.424 61.24 26.205 83.51 62.79 9.88 16.201 28.235 46.34 37.026 48.705 21.056-1.967 52.847-102.662 64.505-207.27 1.34-11.742 11.827-20.072 23.694-18.92 11.764 1.318 20.219 11.93 18.92 23.692-7.994 71.664-34.178 239.021-104.274 245.154-32.671 2.804-54.941-33.698-76.476-69.049-9.065-14.902-23.883-39.222-32.15-44.643-27.272 15.74-26.057 99.083-25.075 166.393 1.446 97.786 2.805 190.15-62.39 201.578-29.052 5.023-48.266-25.033-65.052-51.634-7.178-11.428-19.235-30.559-25.22-33.133-13.165.377-58.5 49.625-93.39 103.583-4.103 6.342-10.988 9.795-18.021 9.795z"
    />
  </svg>
);

export default CheckboardGraph;
