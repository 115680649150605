import React from "react";

const Observatory = props => (
  <svg viewBox="0 0 56 64"   {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M24 61h8V49h-8v12zm1-1h6V50h-6v10z" fill="#1d7ed0" />
      <path d="M0 64h56v-4H0v4zm1-1h54v-2H1v2z" fill="#f2b430" />
      <path
        d="M20 5h16V4H20v1zm8 4a6 6 0 1 0 0 12 6 6 0 0 0 0-12m0 1c2.757 0 5 2.243 5 5s-2.243 5-5 5-5-2.243-5-5 2.243-5 5-5M7.5 37a.501.501 0 0 1-.5-.5v-4c0-.275.225-.5.5-.5s.5.225.5.5v4c0 .275-.225.5-.5.5m5 0a.501.501 0 0 1-.5-.5v-4c0-.275.225-.5.5-.5s.5.225.5.5v4c0 .275-.225.5-.5.5m5 0a.501.501 0 0 1-.5-.5v-4c0-.275.225-.5.5-.5s.5.225.5.5v4c0 .275-.225.5-.5.5m5 0a.501.501 0 0 1-.5-.5v-4c0-.275.225-.5.5-.5s.5.225.5.5v4c0 .275-.225.5-.5.5m5 0a.501.501 0 0 1-.5-.5v-4c0-.275.225-.5.5-.5s.5.225.5.5v4c0 .275-.225.5-.5.5m5 0a.501.501 0 0 1-.5-.5v-4c0-.275.225-.5.5-.5s.5.225.5.5v4c0 .275-.225.5-.5.5m5 0a.501.501 0 0 1-.5-.5v-4c0-.275.225-.5.5-.5s.5.225.5.5v4c0 .275-.225.5-.5.5m5 0a.501.501 0 0 1-.5-.5v-4c0-.275.225-.5.5-.5s.5.225.5.5v4c0 .275-.225.5-.5.5m5 0a.501.501 0 0 1-.5-.5v-4c0-.275.225-.5.5-.5s.5.225.5.5v4c0 .275-.225.5-.5.5M2 60h1V33H2v27zm51 0h1V33h-1v27zM20 0C10.013.528 2 8.887 2 19v11h18V0zm-1 1.085V29H3V19C3 9.766 9.946 2.091 19 1.085zM36 0v30h18V19C54 8.887 45.987.528 36 0m1 1.085C46.054 2.091 53 9.766 53 19v10H37V1.085"
        fill="#1d7ed0"
      />
      <path
        d="M5.5 20a.5.5 0 0 1-.5-.5c0-6.158 2.928-11.282 8.245-14.431a.502.502 0 0 1 .51.862C8.682 8.934 6 13.626 6 19.5a.5.5 0 0 1-.5.5m26-5a.5.5 0 0 1-.5-.5c0-1.191-1.309-2.5-2.5-2.5a.5.5 0 0 1 0-1c1.733 0 3.5 1.767 3.5 3.5a.5.5 0 0 1-.5.5M0 33h56v-4H0v4zm1-1h54v-2H1v2z"
        fill="#f2b430"
      />
    </g>
  </svg>
);

export default Observatory;
